import React, { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { HiOutlineBars3 } from "react-icons/hi2";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../Images/Icons/MirrorNewLogo.svg";
import { refreshAccessToken } from "../../../slices/authSlice";
import { ACCOUNT_TYPE } from "../../../utils/constants";
import { AiOutlineDisconnect } from "react-icons/ai";

const navigation = [
  { name: "Dashboard", path: "/dashboard", current: true },
  // { name: "Temp01", path: "/Temp01", current: false },
  // { name: "Temp02", path: "/Temp02", current: false },
  // { name: "Temp03", path: "/Temp03", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const TopNavNew = ({ setIsFetchingSuggestions }) => {
  const [isOnline, setOnline] = useState(true);
  const [showOnlineMessage, setShowOnlineMessage] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.profile);
  const { newNotificationState } = useSelector((state) => state.notification);

  useEffect(() => {
    const handleOnline = () => {
      setOnline(true);
      setShowOnlineMessage(false);
    };

    const handleOffline = () => {
      setOnline(false);
      setShowOnlineMessage(true);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  const userAccountType = user ? user.accountType : null;

  const handleRefreshAccessToken = async () => {
    try {
      await dispatch(refreshAccessToken()).unwrap();
      console.log("Access token refreshed successfully");
    } catch (error) {
      console.error("Failed to refresh access token:", error);
    }
  };

  return (
    <Disclosure
      as="nav"
      className="bg-white text-black border fixed w-full z-10"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <HiOutlineBars3
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div
                  onClick={() => navigate("/dashboard")}
                  className="flex flex-shrink-0 text-xl font-medium items-center"
                >
                  <img src={logo} width="90px" />
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  onClick={() => navigate("/notifications")}
                  type="button"
                  className="relative rounded-full bg-gray-100 p-1 text-gray-800 hover:text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>

                  <div className="relative">
                    <BellIcon className="h-6 w-6" aria-hidden="true" />

                    {newNotificationState && (
                      <span class="absolute top-2  w-3 h-3 rounded-full border bg-blue-500 z-10"></span>
                    )}
                  </div>
                </button>

                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only ">Open user menu</span>
                      {user && user.userImage ? (
                        <div className="relative">
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.userImage}
                            alt={user.fullName || "User"}
                          />
                          {isOnline ? (
                            <span class="absolute top-6 right-0.5 w-3 h-3 rounded-full bg-green-400 z-10"></span>
                          ) : (
                            <span class="absolute top-6 right-0.5 w-3 h-3 rounded-full bg-gray-400 z-10"></span>
                          )}
                        </div>
                      ) : (
                        <img
                          className="h-8 w-8 rounded-full"
                          src={
                            "https://api.dicebear.com/5.x/initials/svg?seed=NA"
                          }
                          alt=""
                        />
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink
                            to={user?.username ? `/user-profile/${user.username}` : "#"}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Your Profile
                          </NavLink>
                        )}
                      </Menu.Item>
                      {userAccountType === ACCOUNT_TYPE.ADMIN && (
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink
                              to="/admin/dashboard"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Admin
                            </NavLink>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink
                            to="/settings"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Settings
                          </NavLink>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink
                            href="#"
                            // to="/login"
                            onClick={handleRefreshAccessToken}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            refresh Token
                          </NavLink>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          {showOnlineMessage && (
            <div
              className={`w-screen h-7 flex justify-center items-center gap-2 transition-all duration-300 ${
                isOnline ? "bg-green-400" : "bg-gray-400"
              }`}
            >
              <div
                className={`${
                  isOnline ? "bg-green-500" : ""
                } h-3 w-3 rounded-full`}
              ></div>
              {isOnline ? (
                <span>Back Online</span>
              ) : (
                <div className="flex justify-center gap-1 items-center">
                  <AiOutlineDisconnect />
                  <span className=" text-sm">No Internet Connection </span>{" "}
                </div>
              )}
            </div>
          )}

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.path}
                  className={classNames(
                    item.current
                      ? "bg-black text-white"
                      : "text-black hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
