import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaRegCircleUser } from "react-icons/fa6";

// Bottom Nav added in Issue #2 Loop
export const BottomNav = () => {
  const navigate = useNavigate();

  const profile = useSelector((state) => state.profile);
  const username = profile?.user?.username;
  console.log("profile in bottomNav", profile);
  console.log("Username:", username);

  return (
    <div class="fixed bottom-0 z-50 w-full -translate-x-1/2 bg-white border-t border-gray-200 left-1/2 ">
      <div class="w-full">
        <div
          class="grid max-w-xs grid-cols-3 gap-1 p-1 mx-auto my-2 bg-gray-100 rounded-lg "
          role="group"
        >
          <button
            type="button"
            class="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200  rounded-lg"
          >
            New
          </button>
          <button
            type="button"
            class="px-5 py-1.5 text-xs font-medium text-white bg-black  rounded-lg"
          >
            Popular
          </button>
          <button
            type="button"
            class="px-5 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200  rounded-lg"
          >
            Following
          </button>
        </div>
      </div>
      <div class="grid h-full max-w-lg grid-cols-5 mx-auto">
        <button
          onClick={() => navigate("/dashboard")}
          data-tooltip-target="tooltip-home"
          type="button"
          class="inline-flex flex-col items-center justify-center p-4 hover:bg-black hover:text-white  group"
        >
          <svg
            class="w-5 h-5 mb-1  hover:bg-black hover:text-white "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
          </svg>
          <span class="sr-only">Home</span>
        </button>
        <div
          id="tooltip-home"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
        >
          Home
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <button
          data-tooltip-target="tooltip-search"
          type="button"
          onClick={() => navigate("/search")}
          class="inline-flex flex-col items-center justify-center p-4 hover:bg-black hover:text-white group"
        >
          <svg
            class="w-5 h-5 mb-1 k hover:bg-black hover:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
          <span class="sr-only">Search</span>
        </button>
        <div
          id="tooltip-search"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
        >
          Search
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <button
          onClick={() => navigate("/post")}
          data-tooltip-target="tooltip-post"
          type="button"
          class="inline-flex flex-col items-center justify-center p-4 hover:bg-black hover:text-white group"
        >
          <svg
            class="w-5 h-5 mb-1 k hover:bg-black hover:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 1v16M1 9h16"
            />
          </svg>
          <span class="sr-only">New post</span>
        </button>
        <div
          id="tooltip-post"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
        >
          New post
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <button
          data-tooltip-target="tooltip-bookmark"
          type="button"
          class="inline-flex flex-col items-center justify-center p-4 hover:bg-black hover:text-white group"
        >
          <svg
            class="w-5 h-5 mb-1 k hover:bg-black hover:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 14 20"
          >
            <path d="M13 20a1 1 0 0 1-.64-.231L7 15.3l-5.36 4.469A1 1 0 0 1 0 19V2a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v17a1 1 0 0 1-1 1Z" />
          </svg>
          <span class="sr-only">Bookmark</span>
        </button>
        <div
          id="tooltip-bookmark"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
        >
          Bookmark
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <button
          // onClick={() => navigate("/profile")}
          onClick={() => navigate(`/user-profile/${username}`)}
          data-tooltip-target="tooltip-settings"
          type="button"
          class="inline-flex flex-col items-center justify-center p-4 hover:bg-black hover:text-white group"
        >
          {/* <svg
            class="w-5 h-5 mb-1 k hover:bg-black hover:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 12.25V1m0 11.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M4 19v-2.25m6-13.5V1m0 2.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M10 19V7.75m6 4.5V1m0 11.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM16 19v-2"
            />
          </svg> */}

          <FaRegCircleUser size="25" className="mb-1" />
          <span class="sr-only">Settings</span>
        </button>
        <div
          id="tooltip-settings"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
        >
          Settings
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>
    </div>
  );
};
