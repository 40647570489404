import React, { useState, useRef, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { apiConnector } from "../../../services/apiconnector";
import { Link, useNavigate } from "react-router-dom";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import Geolocation from "../../../Geolocation";
import Logo from "../../../Images/Icons/MirrorNewLogo.svg";
import { updateUserDetailsSignupData } from "../../../slices/authSlice";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { CiCircleCheck } from "react-icons/ci";
import { CiCircleAlert } from "react-icons/ci";

export const EnterDetails = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    fullName: "",
    dateOfBirth: "2001-01-01",
    password: "",
    confirmPassword: "", // For validation only
    currentRole: "",
    currentCompany: "",
    workLocation: "",
    userImage: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const passwordStrengthChecklist = {
    length: formData.password.length >= 8,
    uppercase: /[A-Z]/.test(formData.password),
    lowercase: /[a-z]/.test(formData.password),
    number: /\d/.test(formData.password),
    specialChar: /[!@#$%^&*()_\-+={}|\\:;"'<>,.?/~`]/.test(formData.password),
  };

  const { signupData } = useSelector((state) => state.auth);

  // if (signupData === null) {
  //   navigate("/register/enter-email");
  // }

  const handleLocationChange = (location) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      workLocation: location,
    }));
  };

  const changeHandler = (event) => {
    const { name, value, type } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? event.target.checked : value,
    }));
  };

  const inputRef = useRef();
  const libraries = ["places"];

  const handlePlaceChanges = () => {
    const places = inputRef.current?.getPlaces();

    if (places && places.length > 0) {
      const place = places[0];
      setFormData((prevFormData) => ({
        ...prevFormData,
        workLocation: place.formatted_address,
      }));
    }
  };

  const [googleScriptLoaded, setGoogleScriptLoaded] = useState(false);

  useEffect(() => {
    if (!googleScriptLoaded) {
      setGoogleScriptLoaded(true);
    }
  }, [googleScriptLoaded]);

  const handleNextStep = (event) => {
    event.preventDefault();
    if (step === 1) {
      // Validation for step 1 fields
      if (
        !formData.fullName ||
        !formData.dateOfBirth ||
        !formData.password ||
        !formData.confirmPassword
      ) {
        toast.error("Please complete all fields.");
        return;
      }
      if (formData.password !== formData.confirmPassword) {
        toast.error("Passwords do not match.");
        return;
      }
      setStep(2);
    } else if (step === 2) {
      submitHandler(event);
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Saving Profile Details...");

    formData.userImage = `https://api.dicebear.com/9.x/initials/svg?seed=${formData.fullName}`;

    toast.dismiss(toastId);
    toast.success("Profile Details Saved Successfully");

    const { confirmPassword, ...formDataToStore } = formData;

    try {
      dispatch(updateUserDetailsSignupData(formDataToStore));
      navigate("/register/upload-avatar");
      // navigate("/register/choose-username");
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen gap-4">
      <div className="flex items-center flex-col justify-center w-[325px] lg:mb-0 h-full p-10 ">
        <div className="flex flex-col gap-4">
          <h2 className="text-gray-900 text-center text-4xl font-semibold">
            <img src={Logo} alt="Logo" />
          </h2>
          <span className="font-normal text-sm text-gray-500">
            Create New Account
          </span>
        </div>

        {/* <div className="mt-12 w-full text-left">
          <span className="font-semibold text-3xl">Add to your profile</span>
        </div> */}

        <div className="mt-6 h-full">
          <form
            className="flex h-full flex-col items-center justify-between  gap-4"
            onSubmit={handleNextStep}
          >
            <div className="flex flex-col items-center justify-center gap-4">
              {step === 1 && (
                <>
                  <div className="relative">
                    <input
                      onChange={changeHandler}
                      value={formData.fullName}
                      name="fullName"
                      type="text"
                      className={`p-3 text-sm w-[325px] h-[59px] border rounded-md ${
                        formData.fullName && "not-empty"
                      }`}
                      //   placeholder="Name"
                    />
                    <label
                      className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                        formData.fullName &&
                        "text-xs text-gray-700 -translate-y-9"
                      }`}
                      style={{
                        backgroundColor: formData.fullName
                          ? "#fff"
                          : "transparent",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                      }}
                    >
                      Name
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      onChange={changeHandler}
                      value={formData.dateOfBirth}
                      name="dateOfBirth"
                      type="date"
                      className={`p-3 text-sm w-[325px] h-[59px] border rounded-md ${
                        formData.dateOfBirth && "not-empty"
                      }`}
                      min="1950-01-01"
                      max="2199-12-31"
                    />
                    <label
                      className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                        formData.dateOfBirth &&
                        "text-xs text-gray-700 -translate-y-9"
                      }`}
                      style={{
                        backgroundColor: formData.dateOfBirth
                          ? "#fff"
                          : "transparent",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                      }}
                    >
                      Date of Birth
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      onChange={changeHandler}
                      value={formData.password}
                      name="password"
                      maxLength={255}
                      type={showPassword ? "text" : "password"}
                      className={`p-3 text-sm w-[325px] h-[59px] border rounded-md ${
                        formData.password && "not-empty"
                      }`}
                      //   placeholder="Password"
                    />
                    <label
                      className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                        formData.password &&
                        "text-xs text-gray-700 -translate-y-9"
                      }`}
                      style={{
                        backgroundColor: formData.password
                          ? "#fff"
                          : "transparent",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                      }}
                    >
                      Password
                    </label>
                    <span
                      className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <VscEye size={20} />
                      ) : (
                        <VscEyeClosed size={20} />
                      )}
                    </span>
                  </div>
                  <div className="relative">
                    <input
                      onChange={changeHandler}
                      value={formData.confirmPassword}
                      name="confirmPassword"
                      maxLength={255}
                      type={showConfirmPassword ? "text" : "password"}
                      className={`p-3 text-sm w-[325px] h-[59px] border rounded-md ${
                        formData.confirmPassword && "not-empty"
                      }`}
                      //   placeholder="Confirm Password"
                    />
                    <label
                      className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                        formData.confirmPassword &&
                        "text-xs text-gray-700 -translate-y-9"
                      }`}
                      style={{
                        backgroundColor: formData.confirmPassword
                          ? "#fff"
                          : "transparent",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                      }}
                    >
                      Confirm Password
                    </label>
                    <span
                      className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? (
                        <VscEye size={20} />
                      ) : (
                        <VscEyeClosed size={20} />
                      )}
                    </span>
                  </div>

                  {formData.password && (
                    <div className=" w-full p-2 ">
                      <ul className="flex flex-col text-sm items-start">
                        <li
                          className={`flex items-center justify-center gap-2 ${
                            passwordStrengthChecklist.length
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {passwordStrengthChecklist.length ? (
                            <CiCircleCheck />
                          ) : (
                            <CiCircleAlert />
                          )}{" "}
                          At least 8 characters
                        </li>

                        <li
                          className={`flex items-center justify-center gap-2 ${
                            passwordStrengthChecklist.uppercase
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {passwordStrengthChecklist.uppercase ? (
                            <CiCircleCheck />
                          ) : (
                            <CiCircleAlert />
                          )}{" "}
                          Contains an uppercase letter
                        </li>

                        <li
                          className={`flex items-center justify-center gap-2 ${
                            passwordStrengthChecklist.lowercase
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {passwordStrengthChecklist.lowercase ? (
                            <CiCircleCheck />
                          ) : (
                            <CiCircleAlert />
                          )}{" "}
                          Contains a lowercase letter
                        </li>

                        <li
                          className={`flex items-center justify-center gap-2 ${
                            passwordStrengthChecklist.number
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {passwordStrengthChecklist.number ? (
                            <CiCircleCheck />
                          ) : (
                            <CiCircleAlert />
                          )}{" "}
                          Contains a number
                        </li>

                        <li
                          className={`flex items-center justify-center gap-2 ${
                            passwordStrengthChecklist.specialChar
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {passwordStrengthChecklist.specialChar ? (
                            <CiCircleCheck />
                          ) : (
                            <CiCircleAlert />
                          )}{" "}
                          Contains a special character
                        </li>

                        <li
                          className={`flex items-center justify-center gap-2 ${
                            formData.password === formData.confirmPassword
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {formData.password === formData.confirmPassword ? (
                            <CiCircleCheck />
                          ) : (
                            <CiCircleAlert />
                          )}{" "}
                          Confirm Password Matches
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              )}

              {step === 2 && (
                <>
                  <div className="relative">
                    <input
                      onChange={changeHandler}
                      value={formData.currentRole}
                      name="currentRole"
                      type="text"
                      className={`p-3 text-sm w-[325px] h-[59px] border rounded-md ${
                        formData.currentRole && "not-empty"
                      }`}
                      //   placeholder="Current Role"
                    />
                    <label
                      className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                        formData.currentRole &&
                        "text-xs text-gray-700 -translate-y-9"
                      }`}
                      style={{
                        backgroundColor: formData.currentRole
                          ? "#fff"
                          : "transparent",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                      }}
                    >
                      Current Role
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      onChange={changeHandler}
                      value={formData.currentCompany}
                      name="currentCompany"
                      type="text"
                      className={`p-3 text-sm w-[325px] h-[59px] border rounded-md ${
                        formData.currentCompany && "not-empty"
                      }`}
                      //   placeholder="Current Company"
                    />
                    <label
                      className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                        formData.currentCompany &&
                        "text-xs text-gray-700 -translate-y-9"
                      }`}
                      style={{
                        backgroundColor: formData.currentCompany
                          ? "#fff"
                          : "transparent",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                      }}
                    >
                      Current Company
                    </label>
                  </div>
                  <div>
                    {googleScriptLoaded && (
                      <LoadScript
                        googleMapsApiKey={
                          process.env.REACT_APP_GOOGLE_PLACES_API_KEY
                        }
                        libraries={libraries}
                        onError={() =>
                          toast.error("Failed to load Google Maps API")
                        }
                      >
                        <StandaloneSearchBox
                          onLoad={(ref) => {
                            inputRef.current = ref;
                          }}
                          onPlacesChanged={handlePlaceChanges}
                        >
                          <input
                            type="text"
                            placeholder="Location"
                            className="p-3 text-md w-[325px] h-[59px] border rounded-md"
                          />
                        </StandaloneSearchBox>
                      </LoadScript>
                    )}
                  </div>
                </>
              )}
            </div>

            <div>
              <button
                type="submit"
                className={`bg-black w-[325px] h-[50px] text-white rounded-full ${
                  step === 1 && "mb-8"
                }`}
              >
                {step === 1 ? "Next" : "Submit"}
              </button>

              {step === 2 && (
                <button
                  type="button"
                  onClick={() => navigate("/register/upload-avatar")}
                  className="underline mt-4"
                >
                  Skip
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
