import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { apiConnector } from "../../services/apiconnector";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Images/Icons/MirrorNewLogo.svg";

export const UserName = () => {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "@username1",
  });

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    console.log(formData);

    const toastId = toast.loading("Updating Your Username...");

    try {
      await apiConnector(
        "PUT",
        "https://mirror-express-server.onrender.com/api/v1/updateProfile",
        // "http://localhost:8000/api/v1/updateProfile",
        formData,
        { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
      )
        .then((res) => {
          toast.dismiss(toastId);
          toast.success("Username Updated successfully");
          navigate("/profile");
        })
        .catch((error) => {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUsernameClick = (username) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      username: username,
    }));
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen gap-4">
      <div className=" flex items-center flex-col justify-center w-[325px] lg:mb-0 mb-[14rem] ">
        <div className="flex flex-col gap-2">
          <h2 className="text-gray-900 text-center text-4xl font-semibold mt-10">
            <img src={Logo} alt="Logo" />
          </h2>
        </div>

        <div className="mt-12  w-full text-left">
          <span className="font-semibold text-3xl   ">Set your Username</span>
        </div>

        <div className="mt-4">
          <form className="flex flex-col gap-5" onSubmit={submitHandler}>
            <input
              required
              onChange={changeHandler}
              value={formData.username}
              name="username"
              type="text"
              className="bg-richblack-800  text-richblack-5  p-3 w-[325px]  h-[48px]  text-sm border rounded-md"
            />
            <div className="flex flex-col text-left mt-1 gap-3">
              <span
                className="text-sm font-medium text-blue-600 cursor-pointer ml-2"
                onClick={() => handleUsernameClick("@username2")}
              >
                @username2
              </span>
              <span
                className="text-sm font-medium text-blue-600 cursor-pointer ml-2"
                onClick={() => handleUsernameClick("@username3")}
              >
                @username3
              </span>
              <span
                className="text-sm font-medium text-blue-600 cursor-pointer ml-2"
                onClick={() => handleUsernameClick("@username4")}
              >
                @username4
              </span>

              <span className="text-sm font-medium text-gray-500 ml-2 mt-3">
                Confirm or edit your username
              </span>
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <button className="bg-black w-[325px] h-[50px] text-white rounded-full">
                <span>Submit</span>
              </button>

              <Link to="/profile">
                <span className="text-sm font-medium underline text-gray-600">
                  Skip for now
                </span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
