import React from "react";

export const ReportButton = ({
  option,
  label,
  selectedReportOption,
  selectReportOption,
}) => {
  const isSelected = selectedReportOption.includes(option);
  return (
    <button
      className={`border border-black rounded-full mb-3 py-1 flex justify-center items-center ${
        isSelected ? "bg-black text-white" : ""
      }`}
      onClick={() => selectReportOption(option)}
    >
      <span className="flex-grow text-left ml-2 mr-3">{label}</span>
      <span className={`mr-3 ${isSelected ? "text-white" : "text-black"}`}>
        {isSelected ? "-" : "+"}
      </span>
    </button>
  );
};
