import React, { useState } from "react";
import Logo from "../../Images/Icons/MirrorNewLogo.svg";
import { FaGoogle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import TermsModal from "./TermsModal";
import PrivacyModal from "./PrivacyModal";
import { privacySections } from "./PrivacyContent";
import { termsSections } from "./TermsContent";

export const WelcomeScreen = () => {
  const navigate = useNavigate();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const openTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const openPrivacyModal = () => {
    setIsPrivacyModalOpen(true);
  };

  const googleSignUpHandler = () => {
    window.location.href = "https://api.atmirrors.com/auth/google";
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen gap-7">
      <h2 className="text-gray-900 text-center text-4xl font-semibold ">
        <img src={Logo} alt="Logo" sizes="45" />
      </h2>

      <div className="mt-5 font-semibold text-2xl">
        <span className="lg:text-wrap">Reflect . Connect . Grow</span>
      </div>
      <div className="mt-1">
        <div className="flex flex-col gap-5 items-center justify-center">
          <Link to="/">
            <button
              className=" flex justify-center items-center gap-x-3  w-[325px] border h-[50px] rounded-full"
              onClick={googleSignUpHandler}
            >
              <FaGoogle size={18} />
              <span className="font-medium">Sign Up With Google</span>
            </button>
          </Link>

          <Link to="/register">
            <button className="bg-black w-[325px] h-[50px] text-white rounded-full">
              <span>Sign Up</span>
            </button>
          </Link>
        </div>

        <div className="mt-5 text-sm">
          <span className="text-gray-500">Already have an account? </span>
          <span
            className="font-semibold underline cursor-pointer"
            onClick={() => {
              navigate("/auth/login");
            }}
          >
            Sign In
          </span>
        </div>

        <div className="flex h-full items-end justify-center ">
          <span className="text-sm font-small text-gray-500 pb-3">
            By signing up, you agree to our <br />
            <span
              className="text-sky-500 cursor-pointer"
              onClick={openTermsModal}
            >
              Terms,
            </span>
            and{" "}
            <span
              className="text-sky-500 cursor-pointer"
              onClick={openPrivacyModal}
            >
              Privacy Policy
            </span>
            .
          </span>
        </div>
      </div>

      {/* Vercel Deployment Access Test 02*/}
      <TermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        title="Terms of Service"
        sections={termsSections}
      />

      <PrivacyModal
        isOpen={isPrivacyModalOpen}
        onClose={() => setIsPrivacyModalOpen(false)}
        title="Privacy Policy"
        sections={privacySections}
      />
    </div>
  );
};

export default WelcomeScreen;
