import { createSlice } from "@reduxjs/toolkit";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("notificationData");
    return serializedState ? JSON.parse(serializedState) : [];
  } catch (e) {
    console.error("Could not load state from localStorage", e);
    return [];
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("notificationData", serializedState);
  } catch (e) {
    console.error("Could not save state to localStorage", e);
  }
};

const initialState = {
  notificationData: loadState(),
  newNotificationState: false,
  loading: false,
  fcmToken: localStorage.getItem("fcmToken")
    ? JSON.parse(localStorage.getItem("fcmToken"))
    : null,
};

const notificationsSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    setNotificationData(state, value) {
      state.notificationData = value.payload;
      saveState(state.notificationData);
    },
    addNotification(state, value) {
      const notificationWithTimestamp = {
        ...value.payload,
        timestamp: new Date().toISOString(),
        isNotificationOpened: false,
      };
      state.notificationData.push(notificationWithTimestamp);
      saveState(state.notificationData);
    },
    setNewNotificationState(state, value) {
      state.newNotificationState = value.payload;
    },
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setFcmToken(state, value) {
      state.fcmToken = value.payload;
    },
    clearNotifications(state) {
      state.notificationData = [];
      saveState(state.notificationData);
    },
    setNotificationOpenedState(state, value) {
      const { messageId, isOpened } = value.payload;
      const notification = state.notificationData.find(
        (notif) => notif.messageId === messageId
      );
      if (notification) {
        notification.isNotificationOpened = isOpened;
        saveState(state.notificationData);
      }
    },
    removeNotification(state, value) {
      state.notificationData = state.notificationData.filter(
        (notif) => notif.messageId !== value.payload
      );
      saveState(state.notificationData);
    },
  },
});

export const {
  setNotificationData,
  addNotification,
  setLoading,
  setFcmToken,
  setNewNotificationState,
  clearNotifications,
  setNotificationOpenedState,
  removeNotification,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
