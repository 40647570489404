import React, { Suspense, useState } from "react";

function PasswordStrengthChecker({ password, setNewPasswordStrength }) {
  const [strength, setStrength] = useState(null);

  React.useEffect(() => {
    let isMounted = true;

    import("zxcvbn").then((module) => {
      if (isMounted) {
        const zxcvbn = module.default;
        const result = zxcvbn(password);
        const strengthLevel = getPasswordStrengthFromScore(result.score);
        setStrength(strengthLevel);
        setNewPasswordStrength(strengthLevel);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [password, setNewPasswordStrength]);

  const getPasswordStrengthFromScore = (score) => {
    if (score >= 0 && score < 2) {
      return "Weak Password";
    } else if (score >= 2 && score < 4) {
      return "Okay Password";
    } else if (score >= 4) {
      return "Strong Password";
    }
  };

  const getColorForStrength = (strength) => {
    if (strength === "Weak Password") {
      setNewPasswordStrength("Weak Password");
      return "red";
    } else if (strength === "Okay Password") {
      setNewPasswordStrength("Okay Password");
      return "orange";
    } else if (strength === "Strong Password") {
      setNewPasswordStrength("Strong Password");
      return "green";
    }
  };

  return (
    <Suspense fallback={null}>
      <div>
        {strength ? (
          <div
            className="text-right w-[325px] text-xs font-medium"
            style={{
              color: getColorForStrength(strength),
            }}
          >
            {strength}
          </div>
        ) : (
          null
        )}
      </div>
    </Suspense>
  );
}

export default PasswordStrengthChecker;