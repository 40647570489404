// This will prevent authenticated users from accessing this route
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ACCOUNT_TYPE } from "../../../utils/constants";

function OpenRoute({ children }) {
  const { accessToken } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);

  // console.log("User in openRoute.js", user);

  if (accessToken === null) {
    return children;
  } else {
    // return user.accountType === ACCOUNT_TYPE.ADMIN ? (
    //   <Navigate to="/admin/dashboard" />
    // ) : (
    //   <Navigate to="/dashboard" />
    // );
    return <Navigate to="/dashboard" />;
  }
}

export default OpenRoute;
