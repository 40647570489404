export const formattedDate = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    day: "2-digit",
    month: "short",
    year: "numeric",
    timeZone: "IST",
  });
};
