import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const { accessToken } = useSelector((state) => state.auth);

  if (accessToken !== null) return children;
  else return <Navigate to="/auth/login" />;
};

export default PrivateRoute;
