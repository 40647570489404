import { UsercardAtCompanyPage } from "../../Pages/NewUI/UserCardAtCompanyPage"

export const CompanyHomePage = () => {

    const exampleProfile = {
        fullName: "Rohith",
        role: "Software Developer",
        rating: 4.8,
        reviews: 12,
        img: "https://via.placeholder.com/64"
      };

    return(
        <div>
            <UsercardAtCompanyPage userCompanyPageProfile={exampleProfile}/>
        </div>
    )
}