import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../../slices/profileSlice";
import { setToken } from "../../slices/authSlice";
import Logo from "../../Images/Icons/MirrorNewLogo.svg";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { FaGoogle } from "react-icons/fa";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

export const Login = () => {
  const apiUrl = "https://mirror-express-server.onrender.com/api/v1/login";
  const siteKey = process.env.REACT_APP_SITE_KEY;
  const dispatch = useDispatch();

  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const captchaRef = useRef();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    password: "",
  });

  const [shakeEmail, setShakeEmail] = useState(false);
  const [shakePhone, setShakePhone] = useState(false);
  const [shakePassword, setShakePassword] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const [isUsingPhone, setIsUsingPhone] = useState(false);

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleUsingPhone = () => {
    setIsUsingPhone(!isUsingPhone);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const toastId = toast.loading("Logging you in...");

    // Reset shake and error states
    setShakeEmail(false);
    setShakePhone(false);
    setShakePassword(false);
    setErrorEmail(false);
    setErrorPhone(false);
    setErrorPassword(false);

    let hasError = false;

    if (!formData.password) {
      setShakePassword(true);
      setErrorPassword(true);
      hasError = true;
    }
    if (!formData.phone && !formData.email) {
      setShakeEmail(true);
      setShakePhone(true);
      setErrorEmail(true);
      setErrorPhone(true);
      hasError = true;
    }
    if (!recaptchaValue) {
      toast.dismiss(toastId);
      toast.error("Invalid reCaptcha Value");
      return;
    }

    if (hasError) {
      toast.dismiss(toastId);
      toast.error("Please fill in all the required fields");
      setTimeout(() => {
        setShakeEmail(false);
        setShakePhone(false);
        setShakePassword(false);
      }, 500);
      return;
    }

    captchaRef.current.reset();

    const loginData = {
      password: formData.password,
      contactInfo: formData.phone || formData.email,
    };

    try {
      const res = await axios.post(apiUrl, {
        ...formData,
        recaptchaValue,
      });
      toast.dismiss(toastId);
      toast.success("Logged in successfully");
      dispatch(setToken(res.data.token));
      dispatch(setUser(res.data.user));
      localStorage.setItem("token", JSON.stringify(res.data.token));
      localStorage.setItem("user", JSON.stringify(res.data.user));
    } catch (error) {
      toast.dismiss(toastId);
      toast.error(error.response.data.message);
      setShakeEmail(true);
      setShakePassword(true);
      setTimeout(() => {
        setShakeEmail(false);
        setShakePassword(false);
      }, 500);
    }
  };

  const googleSignInHandler = () => {
    window.location.href =
      "https://api.atmirrors.com/auth/google";
  };

  return (
    <div className="flex items-center flex-col justify-center w-screen h-screen">
      <div className="flex items-center flex-col justify-center w-[325px]">
        <div className="flex flex-col gap-4">
          <h2 className="text-gray-900 text-center text-4xl font-semibold">
            <img src={Logo} alt="Logo" />
          </h2>
          <span className="font-normal text-sm text-gray-500">
            Log in to your account
          </span>
        </div>

        <div className="mt-10">
          <form className="flex flex-col items-center" onSubmit={submitHandler}>
            <div className="flex flex-col items-right text-right">
              {isUsingPhone ? (
                <div className="relative">
                  <PhoneInput
                    flags={flags}
                    defaultCountry="IN"
                    placeholder=""
                    value={formData.phone}
                    onChange={(value) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        phone: value,
                      }))
                    }
                    className={`bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[69px] text-sm border rounded-md ${
                      shakePhone ? "animate-shake" : ""
                    } ${errorPhone ? "border-red-600" : ""}`}
                  />
                </div>
              ) : (
                <div className="relative">
                  <input
                    onChange={changeHandler}
                    value={formData.email}
                    placeholder=""
                    name="email"
                    type="email"
                    className={`bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[69px] text-sm border rounded-md ${
                      shakeEmail ? "animate-shake" : ""
                    } ${errorEmail ? "border-red-600" : ""}`}
                  />
                  <label
                    className={`absolute top-9 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                      formData.email && "text-xs text-gray-700 -translate-y-9 "
                    }`}
                    style={{
                      backgroundColor: formData.email ? "#fff" : "transparent",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    Email address
                  </label>
                </div>
              )}
              <span
                className="text-xs font-medium text-right ml-2 mt-1 text-gray-600 cursor-pointer underline hover:text-blue-600 "
                onClick={toggleUsingPhone}
              >
                {isUsingPhone ? "Use email instead" : "Use phone instead"}
              </span>
            </div>

            <div className="relative w-[325px] mt-2">
              <input
                onChange={changeHandler}
                value={formData.password}
                placeholder=""
                name="password"
                type={showPassword ? "text" : "password"}
                className={`bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[69px] text-sm border rounded-md ${
                  shakePassword ? "animate-shake" : ""
                } ${errorPassword ? "border-red-600" : ""}`}
              />
              <label
                className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                  formData.password && "text-xs text-gray-700 -translate-y-9 "
                }`}
                style={{
                  backgroundColor: formData.password ? "#fff" : "transparent",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                Password
              </label>
              <span
                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <VscEye size={25} />
                ) : (
                  <VscEyeClosed size={25} />
                )}
              </span>
            </div>

            <div className=" flex items-start justify-start w-full text-xs underline mt-2 ml-2">
              <Link to="/forgotpassword" className="cursor-pointer">
                Forgot your password ?
              </Link>
            </div>

            <ReCAPTCHA
              ref={captchaRef}
              onChange={(value) => setRecaptchaValue(value)}
              sitekey={siteKey}
              className="mt-3"
            />

            <div className="mt-3">
              <div className="flex flex-col gap-3 items-center justify-center">
                <button className="bg-black w-[325px] h-[50px] text-white rounded-full">
                  <span>Sign In</span>
                </button>

                <button
                  className="flex justify-center items-center gap-x-3 w-[325px] border h-[50px] rounded-full"
                  onClick={googleSignInHandler}
                >
                  <FaGoogle size={18} />
                  <span className="font-medium">Sign In With Google</span>
                </button>
              </div>

              <div className="mt-5 text-sm">
                <span className="text-gray-500">
                  Don’t have an account? Let’s{" "}
                </span>
                <span
                  className="font-semibold underline cursor-pointer"
                  onClick={() => navigate("/register")}
                >
                  Sign up
                </span>
              </div>
            </div>
          </form>
          <div className="flex h-[50%] items-end justify-center ">
            <span className="text-sm font-small text-gray-500 pb-3">
              By signing in, you agree to our <br />
              <Link to="/terms">
                <span className="text-sky-500"> Terms,</span> and{" "}
                <span className="text-sky-500">Privacy Policy</span>.
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
