const BASE_URL = process.env.REACT_APP_BASE_SERVER_URL;

// AUTH ENDPOINTS
export const endpoints = {
  SENDOTP_API: BASE_URL + "/api/auth/sendotp",
  SIGNUP_API: BASE_URL + "/api/auth/signup",
  LOGIN_API: BASE_URL + "/api/auth/signIn",
  CHANGE_PASSWORD_API: BASE_URL + "/api/auth/changepassword",
  RESET_PASSWORD_TOKEN_API: BASE_URL + "/api/auth/reset-password-token",
  RESET_PASSWORD_API: BASE_URL + "/api/auth/resetPassword",
  UPDATE_DISPLAY_PICTURE_API: BASE_URL + "/updateDisplayPicture",

  //Search
  USER_SEARCH_API:
    BASE_URL + `/api/v2/search?searchQuery=${1}&limit=${2}&page=${1}`,
  AUTO_SUGGEST_API:
    BASE_URL + `/api/v2/autoSuggest?searchQuery=${1}&limit=${10}&page=${1}`,

  //Misc
  RECOMMENDATIONS_API: BASE_URL + "/api/v2/getRecommendations",
  GET_TOP_REPORTED_USERS_API: BASE_URL + "/api/v2/users/reported",
  GET_PROFILE_RECOMMENDATIONS:
    BASE_URL + "/api/v2/getProfileRecommendations/:id",
  GET_BUGREPORTS_API: BASE_URL + "/api/v2/getBugReports/",

  //POST ENDPOINTS
  CREATE_NEW_POST_API: BASE_URL + "/api/v2/posts/create",
  GET_ALL_POSTS_API: BASE_URL + "/api/v2/posts",
  GET_POST_BYID_API: BASE_URL + "/api/v2/getPost/:postId",
  EDIT_POST_API: BASE_URL + "/api/v2/post/editPost/:postId",
  REFLECT_POST_API: BASE_URL + "/api/v2/post/reflectPost",

  //Post Votes

  // VOTE_POST_API: BASE_URL + "/posts/vote",
  // GET_ALL_VOTES_API: BASE_URL + "/posts/:postId/vote",

  UPVOTE_POST_API: BASE_URL + "/api/v2/likes/upvote",
  DOWNVOTE_POST_API: BASE_URL + "/api/v2/likes/downvote",
  REPORT_POST_API: BASE_URL + "api/v2/posts/:postId",

  //Comments
  CREATE_COMMENT_API: BASE_URL + "/api/v2/comments/create",

  // GET_ALL_COMMENTS_API: BASE_URL + "/posts/:postId/comments",
  // EDIT_COMMENT_API: BASE_URL + "/posts/comments/:commentId",

  UPVOTE_COMMENT_API: BASE_URL + "/api/v2/likes/upvote/comment",
  DOWNVOTE_COMMENT_API: BASE_URL + "/api/v2/likes/downvote/comment",

  //PROFILE ENDPOINTS
  UPDATE_PROFILE_API: BASE_URL + "/updateProfile",
  GET_ALL_USER_DETAILS_API: BASE_URL + "/getUserDetails",
  GET_ALL_USERS_API: BASE_URL + "/getAllUsers",
  GET_USER_PROFILE_BYID_API: BASE_URL + "/getUserProfile/:userId",
  VERIFY_PROFILE_API: BASE_URL + "verifyUser/103",

  //INVITE ENDPOINTS
  EMAIL_INVITE_API: BASE_URL + "/invite/email",
};
