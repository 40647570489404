import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
// import { Signup } from "./Pages/Signup";
// import { Login } from "./Pages/Login";
// import { Dashboard } from "./Pages/Dashboard";
// import { Navbar } from "./Components/Dashboard/Navbar";
import { BottomNav } from "./Components/Dashboard/BottomNav";
// import { TopNav } from "./Components/Dashboard/TopNav";
import image from "./Images/profile-picture-3.jpg";
import { UserProfile } from "./Pages/UserProfile";
// import { NewPost } from "./Pages/NewPost";
import { UserSearch } from "./Pages/UserSearch";
// import { UserSearchPostgres } from "./Pages/UserSearchPostgres";
import { ForgotPassword } from "./Pages/ForgotPassword";
import { UpdatePassword } from "./Pages/UpdatePassword";
import { NotFound } from "./Pages/NotFound";
// import { VerifyOTP } from "./Pages/VerifyOTP";
import { Settings } from "./Pages/Settings";
// import { v4 as uuidv4 } from "uuid";
import OpenRoute from "./Components/core/RouteAuth/OpenRoute";
import PrivateRoute from "./Components/core/RouteAuth/PrivateRoute";
import { CommentPage } from "./Pages/CommentPage";

import { Signup } from "./Pages/NewUI/Signup";
import { WelcomeScreen } from "./Pages/NewUI/WelcomeScreen";
// import { VerifyOTP } from "./Pages/NewUI/VerifyOTP";
import { EditProfile } from "./Pages/NewUI/EditProfile";
import { UserName } from "./Pages/NewUI/UserName";
// import { UpdateProfileImage } from "./Components/Settings/UpdateProfileImage";
// import { EditProfileImage } from "./Pages/NewUI/EditProfileImage";
// import { NewPost } from "./Pages/NewUI/NewPost";
import { UpdateProfileDetails } from "./Components/Settings/UpdateProfileDetails";
import { ReportBug } from "./Components/Settings//ReportBug";
import { ChangePassword } from "./Components/Settings/ChangePassword";
import { UserProfileByID } from "./Pages/NewUI/UserProfileByID";
import Geolocation from "./Geolocation";
import { SharedPostPage } from "./Pages/NewUI/SharedPostPage";
import { TopNavNew } from "./Components/Dashboard/NewUI/TopNavNew";
import { useDispatch, useSelector } from "react-redux";
import { AdminDashboard } from "./Pages/NewUI/AdminDashboard";
// import AdminRoute from "./Components/core/RouteAuth/AdminRoute";
// import { AdminTopNav } from "./Components/Dashboard/NewUI/Admin/AdminTopNav";
import { ACCOUNT_TYPE } from "./utils/constants";
import { AnonymousPhoneVerification } from "./Pages/NewUI/AnonymousPhoneVerification";
import { AnonymousEmailVerification } from "./Pages/NewUI/AnonymousEmailVerification";
import { VerifyUser } from "./Pages/NewUI/VerifyUser";
import "react-image-crop/dist/ReactCrop.css";
import { EditProfileImageNew } from "./Pages/NewUI/EditProfileImageNew";
import { UpdateProfileImageNew } from "./Components/Settings/UpdateProfileImageNew";
import { CompanyHomePage } from "./Components/Company/CompanyHomePage";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import { generateToken, messaging } from "./notifications/firebase";
import { onMessage } from "firebase/messaging";
import toast from "react-hot-toast";
import {
  addNotification,
  setNewNotificationState,
  setNotificationData,
} from "./slices/notificationSlice";
import { Notifications } from "./Pages/NewUI/Notifications";
import { SearchUserProfile } from "./Components/UserProfile/SearchUserProfile";
import { Register } from "./Pages/NewUI/Register";
import { VerifyOTP } from "./Pages/NewUI/NewRegistration/VerifyOTP";
import { EnterEmail } from "./Pages/NewUI/NewRegistration/EnterEmail";
import { EnterDetails } from "./Pages/NewUI/NewRegistration/EnterDetails";
import { ChooseUserName } from "./Pages/NewUI/NewRegistration/ChooseUserName";
import { UploadAvatar } from "./Pages/NewUI/NewRegistration/UploadAvatar";
import { Login } from "./Pages/NewUI/Login";
import { NewLogin } from "./Pages/NewUI/NewRegistration/NewLogin";
import { Dashboard } from "./Pages/NewUI/Dashboard/Dashboard";
import { NewPost } from "./Pages/NewUI/Post/NewPost";
import { WorkEmailVerification } from "./Pages/NewUI/NewRegistration/WorkEmailVerification";
import { VerifyToken } from "./Pages/NewUI/NewRegistration/VerifyToken";
import { PasswordResetReq } from "./Pages/NewUI/Password/PasswordResetReq";
import { PasswordReset } from "./Pages/NewUI/Password/PasswordReset";
import { OAuthStatus } from "./Pages/NewUI/NewRegistration/OAuthStatus";
import { termsSections } from "./Pages/NewUI/TermsContent";
import { TermsAndPrivacy } from "./Pages/NewUI/Terms&Policies/TermsAndPrivacy";
import { privacySections } from "./Pages/NewUI/PrivacyContent";
import { CommentPageNew } from "./Pages/NewUI/Post/CommentPageNew";

function App() {
  generateToken();

  const dispatch = useDispatch();

  const { fcmToken, notificationData } = useSelector(
    (state) => state.notification
  );
  console.log("App.js", localStorage.getItem("fcmToken"));
  console.log("App.js selector ", fcmToken);
  console.log("App.js selector notification ", notificationData);
  // inside the jsx being returned:
  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log(payload);
      // dispatch(setNotificationData(payload));
      dispatch(addNotification(payload));
      dispatch(setNewNotificationState(true));

      // toast((t) => {
      //   <span>
      //     <img src={payload.notification.image} className="w-5 h-5" />
      //     <span>{payload.notification.body}</span>

      //     <button onClick={() => toast.dismiss(t.id)}>Dismiss</button>
      //   </span>;
      // });

      toast(
        <div className="flex flex-col gap-1">
          <span>{payload.notification.title}</span>{" "}
          <span>{payload.notification.body}</span>{" "}
        </div>,
        {
          icon: "🔔",
        }
      );
    });
  }, [dispatch]);

  const location = useLocation();
  const { user } = useSelector((state) => state.profile);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);

  const handleSetIsFetchingSuggestions = (value) => {
    setIsFetchingSuggestions(value);
  };

  const showBottomNav = useMemo(() => {
    const pathsToExclude = [
      "/",
      "/login",
      "/auth/login",
      "/auth/OAuth/status",
      "/signup",
      "/verify-otp",
      "/edit-profile",
      // "/anonymous-verification",
      "/changePassword",
      "/forgotpassword",
      "/register/enter-email",
      "/register/verify-otp",
      "/register/enter-details",
      "/register/choose-username",
      "/register/upload-avatar",
      "/reset-password",
      "/about/terms",
      "/about/privacy-policy",
    ];
    const startsWithExcludedPath = pathsToExclude.includes(location.pathname);
    const specificPaths = [
      "/update-password/",
      "/reset-password/",
      "/edit-profile/",
      // "/anonymous-verification/",
      "/user-verification/",
      "/admin/",
    ];
    const startsWithSpecificPath = specificPaths.some((path) =>
      location.pathname.startsWith(path)
    );
    return !(startsWithExcludedPath || startsWithSpecificPath);
  }, [location.pathname]);

  const showTopNav = useMemo(() => {
    const pathsToExclude = [
      "/",
      "/login",
      "/auth/login",
      "/auth/OAuth/status",
      "/signup",
      "/verify-otp",
      "/edit-profile",
      // "/anonymous-verification",
      "/forgotpassword",
      "/company",
      "/register/enter-email",
      "/register/verify-otp",
      "/register/enter-details",
      "/register/choose-username",
      "/register/upload-avatar",
      "/reset-password",
      "/about/terms",
      "/about/privacy-policy",
    ];
    const startsWithExcludedPath = pathsToExclude.includes(location.pathname);
    const specificPaths = [
      "/update-password/",
      "/reset-password/",

      "/edit-profile/",
      // "/anonymous-verification/",
      "/user-verification/",
      "/admin/",
    ];
    const startsWithSpecificPath = specificPaths.some((path) =>
      location.pathname.startsWith(path)
    );
    return !(startsWithExcludedPath || startsWithSpecificPath);
  }, [location.pathname]);

  return (
    <div className="App">
      {showBottomNav && <BottomNav />}

      {showTopNav && (
        <TopNavNew
          setIsFetchingSuggestions={handleSetIsFetchingSuggestions}
          image={image}
        />
      )}

      <Routes>
        <Route
          path="*"
          element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <OpenRoute>
              {/* <Signup /> */}
              {/* <Signup /> */}
              <WelcomeScreen />
            </OpenRoute>
          }
        />
        <Route
          path="/about/terms"
          element={
            <TermsAndPrivacy
              title="Terms of Service"
              sections={termsSections}
            />
          }
        />
        <Route
          path="/about/privacy-policy"
          element={
            <TermsAndPrivacy
              title="Privacy Policy"
              sections={privacySections}
            />
          }
        />

        <Route path="/register">
          {/* Redirect to /register/enter-email when the path is /register */}
          <Route
            index
            element={<Navigate to="/register/enter-email" replace />}
          />
          <Route
            path="enter-email"
            element={
              <OpenRoute>
                <EnterEmail />
              </OpenRoute>
            }
          />
          <Route
            path="verify-otp"
            element={
              <OpenRoute>
                <VerifyOTP />
              </OpenRoute>
            }
          />
          <Route
            path="enter-details"
            element={
              <OpenRoute>
                <EnterDetails />
              </OpenRoute>
            }
          />
          <Route
            path="choose-username"
            element={
              <OpenRoute>
                <ChooseUserName />
              </OpenRoute>
            }
          />
          <Route
            path="upload-avatar"
            element={
              <OpenRoute>
                <UploadAvatar />
              </OpenRoute>
            }
          />
        </Route>

        <Route
          path="/signup"
          element={
            <OpenRoute>
              {/* <Signup /> */}
              <Signup />
            </OpenRoute>
          }
        />
        <Route
          path="/verify-otp"
          element={
            <OpenRoute>
              {/* <VerifyOTP /> */}
              <VerifyOTP />
            </OpenRoute>
          }
        />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route
          path="/login"
          element={
            <OpenRoute>
              {/* <Login /> */}
              <Login />
            </OpenRoute>
          }
        />
        <Route
          path="/auth/login"
          element={
            <OpenRoute>
              {/* <Login /> */}
              {/* <Login /> */}
              <NewLogin />
            </OpenRoute>
          }
        />
        <Route
          path="/auth/OAuth/status"
          element={
            <OpenRoute>
              <OAuthStatus />
            </OpenRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              {/* <Dashboard showAutoSuggestResults={isFetchingSuggestions} /> */}
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/company"
          element={
            <PrivateRoute>
              <CompanyHomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-profile"
          element={
            <>
              <Geolocation />
              <PrivateRoute>
                <EditProfile />
              </PrivateRoute>
            </>
          }
        />
        <Route
          path="/edit-profile/profileImage"
          element={
            <PrivateRoute>
              {/* <EditProfileImage /> */}
              <EditProfileImageNew />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <UserProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-profile/:username"
          element={
            <PrivateRoute>
              <UserProfileByID />
            </PrivateRoute>
          }
        />
        <Route
          path="/post"
          element={
            <PrivateRoute>
              {/* <NewPost /> */}
              <NewPost />
            </PrivateRoute>
          }
        >
          {/* <Route path="/post/newPost" element={<NewPost />} /> */}
        </Route>

        <Route
          path="/post/:id"
          element={
            <PrivateRoute>
              {/* <CommentPage /> */}
              <CommentPageNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/sharedPost/:id"
          element={
            <PrivateRoute>
              <SharedPostPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/search"
          element={
            <PrivateRoute>
              {/* <UserSearch /> */}

              <SearchUserProfile />

              {/* <UserSearchPostgres /> */}
            </PrivateRoute>
          }
        />
        <Route
          path="/forgotpassword"
          element={
            <OpenRoute>
              {/* <ForgotPassword /> */}
              <PasswordResetReq />
            </OpenRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <OpenRoute>
              <PasswordReset />
            </OpenRoute>
          }
        />
        <Route
          path="/update-password/:id"
          element={
            <OpenRoute>
              <UpdatePassword />
            </OpenRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/updateProfileDetails"
          element={
            <PrivateRoute>
              <UpdateProfileDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/reportBug"
          element={
            <PrivateRoute>
              <ReportBug />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/updateProfileImage"
          element={
            <PrivateRoute>
              <UpdateProfileImageNew />
              {/* <UpdateProfileImage /> */}
              {/* <ProfileImage /> */}
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-profile/username"
          element={
            <PrivateRoute>
              <UserName />
            </PrivateRoute>
          }
        />
        <Route
          path="/anonymous-verification/phone"
          element={
            <PrivateRoute>
              <AnonymousPhoneVerification />
            </PrivateRoute>
          }
        />
        <Route
          path="/anonymous-verification/:userId"
          element={
            <PrivateRoute>
              <AnonymousEmailVerification />
            </PrivateRoute>
          }
        />
        <Route
          path="/anonymous-verification/email"
          element={
            <PrivateRoute>
              <WorkEmailVerification />
            </PrivateRoute>
          }
        />
        <Route
          path="/verify"
          element={
            <PrivateRoute>
              <VerifyToken />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-verification/:userId"
          element={
            <PrivateRoute>
              <VerifyUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/changePassword"
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <PrivateRoute>
              <Notifications />
            </PrivateRoute>
          }
        />

        {/* Admin Routes */}
        {user?.accountType === ACCOUNT_TYPE.ADMIN && (
          <>
            <Route
              path="/admin/dashboard"
              element={
                <AdminDashboard />
                // <AdminRoute user={user}>
                //   <AdminDashboard />
                // </AdminRoute>
              }
            />
          </>
        )}
        {user?.accountType === ACCOUNT_TYPE.ADMIN && (
          <>
            <Route
              path="/admin/dashboard"
              element={
                <AdminDashboard />
                // <AdminRoute user={user}>
                //   <AdminDashboard />
                // </AdminRoute>
              }
            />
          </>
        )}
        {user?.accountType === ACCOUNT_TYPE.MODERATOR && (
          <>
            <Route
              path="/moderator/dashboard"
              element={<h1 className="pt-[7.5rem]">Moderator Route</h1>}
            />
          </>
        )}
      </Routes>
      <SpeedInsights />
      <Analytics />
    </div>
  );
}

export default App;
