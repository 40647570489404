import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  postData: null,
  loading: false,
};

const createPostSlice = createSlice({
  name: "createPost",
  initialState: initialState,
  reducers: {
    setSavedCreatePostData(state, value) {
      state.savedPostData = value.payload;
    },
    setLoading(state, value) {
      state.loading = value.payload;
    },
  },
});

export const { setSavedCreatePostData, setLoading } = createPostSlice.actions;
export default createPostSlice.reducer;
