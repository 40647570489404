import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
  XIcon,
  RedditShareButton,
  RedditIcon,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";

import { FaWhatsapp } from "react-icons/fa";
import { RiFileCopyLine } from "react-icons/ri";
import { FaL, FaLink, FaReddit, FaTelegram } from "react-icons/fa6";
import toast from "react-hot-toast";
import { ImEmbed2 } from "react-icons/im";
import { IoIosShareAlt } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";

export const SharePost = ({ postId }) => {
  const shareUrl = `https://mirror-web-app.vercel.app/sharedPost/${postId}`; // Replace with your profile URL
  const title = "Mirror Post";

  const emmbbedCode = `<iframe width="560" height="315" src="https://mirror-web-app.vercel.app/post/${postId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      toast.success("Link copied to clipboard");
    } catch (error) {
      console.error("Failed to copy:", error);
      toast.error("Failed to copy link to clipboard");
    }
  };
  const copyEmbbedCode = async () => {
    try {
      await navigator.clipboard.writeText(emmbbedCode);
      toast.success("Embedded code copied to clipboard");
    } catch (error) {
      console.error("Failed to copy:", error);
      toast.error("Failed to copy link to clipboard");
    }
  };
  return (
    <div>
      <div className="p-2 flex flex-col gap-5">
        <h2 className="font-bold flex items-center gap-2 text-xl">
          <span>
            <IoIosShareAlt />
          </span>
          <span>Share Review</span>
        </h2>

        <button
          onClick={copyToClipboard}
          className="pointer flex items-center gap-2 text-base "
        >
          <span>
            <FaLink />
          </span>
          <span>Copy Link</span>
        </button>
        <hr className="mt-[-10px]" />

        <div className="flex gap-3 ">
          {/* Share buttons */}
          <button
            onClick={copyEmbbedCode}
            className="bg-gray-200 p-2 rounded-full"
          >
            {/* <RxClipboardCopy /> */}
            <ImEmbed2 size="25" />
          </button>
          <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
            <div>
              <FaWhatsapp size={32} round />
              {/* <span>Whatsapp</span> */}
            </div>
          </WhatsappShareButton>
          <TwitterShareButton
            url={shareUrl}
            hashtags={["Mirror", "Workplace"]}
            title={title}
          >
            <FaXTwitter size={28} round />
          </TwitterShareButton>
          <LinkedinShareButton
            url={shareUrl}
            title={title}
            // summary={profile.bio}
          >
            <FaLinkedin size={32} round />
          </LinkedinShareButton>

          <TelegramShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <FaTelegram size={32} round />
          </TelegramShareButton>

          <RedditShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="Demo__some-network__share-button"
          >
            <FaReddit size={32} round />
          </RedditShareButton>
        </div>
      </div>
    </div>
  );
};
