import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../../../slices/profileSlice";
import { setRefreshToken, setToken } from "../../../slices/authSlice";
import Logo from "../../../Images/Icons/MirrorNewLogo.svg";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { FaGoogle } from "react-icons/fa";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import TermsModal from "../TermsModal";
import PrivacyModal from "../PrivacyModal";
import { privacySections } from "../PrivacyContent";
import { termsSections } from "../TermsContent";

export const NewLogin = () => {
  const apiUrl = "https://api.atmirrors.com/auth/login";
  const siteKey = process.env.REACT_APP_SITE_KEY;
  const dispatch = useDispatch();

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const captchaRef = useRef();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    phone: "",
    password: "",
  });

  const [shakeUsername, setShakeUsername] = useState(false);
  const [shakePhone, setShakePhone] = useState(false);
  const [shakePassword, setShakePassword] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const [isUsingPhone, setIsUsingPhone] = useState(false);

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleUsingPhone = () => {
    setIsUsingPhone(!isUsingPhone);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const toastId = toast.loading("Logging you in...");

    // Reset shake and error states
    setShakeUsername(false);
    setShakePhone(false);
    setShakePassword(false);
    setErrorUsername(false);
    setErrorPhone(false);
    setErrorPassword(false);

    let hasError = false;

    if (!formData.password) {
      setShakePassword(true);
      setErrorPassword(true);
      hasError = true;
    }
    if (!formData.phone && !formData.username) {
      setShakeUsername(true);
      setShakePhone(true);
      setErrorUsername(true);
      setErrorPhone(true);
      hasError = true;
    }
    if (!recaptchaToken) {
      toast.dismiss(toastId);
      toast.error("Invalid reCaptcha Value");
      return;
    }

    if (hasError) {
      toast.dismiss(toastId);
      toast.error("Please fill in all the required fields");
      setTimeout(() => {
        setShakeUsername(false);
        setShakePhone(false);
        setShakePassword(false);
      }, 500);
      return;
    }

    captchaRef.current.reset();

    const loginData = {
      password: formData.password,
      username: formData.phone || formData.username,
    };

    console.log(loginData);

    try {
      const res = await axios.post(apiUrl, {
        // ...formData,
        ...loginData,
        recaptchaToken,
      });
      toast.dismiss(toastId);
      toast.success("Logged in successfully");
      console.log("LoggedIn successfully", res);
      if (
        res.data &&
        res.data.signInResponse &&
        res.data.signInResponse.accessToken
      ) {
        //access Token
        dispatch(setToken(res.data.signInResponse.accessToken));
        localStorage.setItem(
          "accessToken",
          JSON.stringify(res.data.signInResponse.accessToken)
        );

        //refresh Token
        dispatch(setRefreshToken(res.data.signInResponse.refreshToken));
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(res.data.signInResponse.refreshToken)
        );

        //user
        dispatch(setUser(res.data.signInResponse.user));
        localStorage.setItem(
          "user",
          JSON.stringify(res.data.signInResponse.user)
        );
        console.log("accessToken", res.data.signInResponse.accessToken);
        console.log("user ", res.data.signInResponse.user);
      } else {
        console.error("Access token not found in the response", res);
      }
      //   dispatch(setUser(res.data.user));
      //   localStorage.setItem("token", JSON.stringify(res.data.accessToken));
      //   localStorage.setItem("user", JSON.stringify(res.data.user));
    } catch (error) {
      toast.dismiss(toastId);
      toast.error(error.response.data.message);
      setShakeUsername(true);
      setShakePassword(true);
      setTimeout(() => {
        setShakeUsername(false);
        setShakePassword(false);
      }, 500);
    }
  };

  const googleSignInHandler = () => {
    window.location.href = "https://api.atmirrors.com/auth/google";
  };

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const openTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const openPrivacyModal = () => {
    setIsPrivacyModalOpen(true);
  };

  return (
    <div className="flex items-center flex-col justify-center w-screen h-screen">
      <div className="flex items-center flex-col justify-center w-[325px]">
        <div className="flex flex-col gap-4">
          <h2 className="text-gray-900 text-center text-4xl font-semibold">
            <img src={Logo} alt="Logo" />
          </h2>
          <span className="font-normal text-sm text-gray-500">
            Log in to your account
          </span>
        </div>

        <div className="mt-10">
          <form className="flex flex-col items-center" onSubmit={submitHandler}>
            <div className="flex flex-col items-right text-right">
              {isUsingPhone ? (
                <div className="relative">
                  <PhoneInput
                    flags={flags}
                    defaultCountry="IN"
                    placeholder=""
                    value={formData.phone}
                    onChange={(value) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        phone: value,
                      }))
                    }
                    className={`bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[69px] text-sm border rounded-md ${
                      shakePhone ? "animate-shake" : ""
                    } ${errorPhone ? "border-red-600" : ""}`}
                  />
                </div>
              ) : (
                <div className="relative">
                  <input
                    onChange={changeHandler}
                    value={formData.username}
                    placeholder=""
                    name="username"
                    type="username"
                    className={`bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[69px] text-sm border rounded-md ${
                      shakeUsername ? "animate-shake" : ""
                    } ${errorUsername ? "border-red-600" : ""}`}
                  />
                  <label
                    className={`absolute top-9 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                      formData.username &&
                      "text-xs text-gray-700 -translate-y-9 "
                    }`}
                    style={{
                      backgroundColor: formData.username
                        ? "#fff"
                        : "transparent",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    Username or email
                  </label>
                </div>
              )}
              <span
                className="text-xs font-medium text-right ml-2 mt-1 text-gray-600 cursor-pointer underline hover:text-blue-600 "
                onClick={toggleUsingPhone}
              >
                {isUsingPhone ? "Use Username instead" : "Use phone instead"}
              </span>
            </div>

            <div className="relative w-[325px] mt-2">
              <input
                onChange={changeHandler}
                value={formData.password}
                placeholder=""
                name="password"
                type={showPassword ? "text" : "password"}
                className={`bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[69px] text-sm border rounded-md ${
                  shakePassword ? "animate-shake" : ""
                } ${errorPassword ? "border-red-600" : ""}`}
              />
              <label
                className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                  formData.password && "text-xs text-gray-700 -translate-y-9 "
                }`}
                style={{
                  backgroundColor: formData.password ? "#fff" : "transparent",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                Password
              </label>
              <span
                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <VscEye size={25} />
                ) : (
                  <VscEyeClosed size={25} />
                )}
              </span>
            </div>

            <div className=" flex items-start justify-start w-full text-xs underline mt-2 ml-2">
              <Link to="/forgotpassword" className="cursor-pointer">
                Forgot your password ?
              </Link>
            </div>

            <ReCAPTCHA
              ref={captchaRef}
              onChange={(value) => setRecaptchaToken(value)}
              sitekey={siteKey}
              className="mt-3"
            />

            <div className="mt-3">
              <div className="flex flex-col gap-3 items-center justify-center">
                <button className="bg-black w-[325px] h-[50px] text-white rounded-full">
                  <span>Sign In</span>
                </button>

                <div
                  className="flex justify-center cursor-pointer items-center gap-x-3 w-[325px] border h-[50px] rounded-full"
                  onClick={googleSignInHandler}
                >
                  <FaGoogle size={18} />
                  <span className="font-medium">Sign In With Google</span>
                </div>
              </div>

              <div className="mt-5 text-sm">
                <span className="text-gray-500">
                  Don’t have an account? Let’s{" "}
                </span>
                <span
                  className="font-semibold underline cursor-pointer"
                  onClick={() => navigate("/register")}
                >
                  Sign up
                </span>
              </div>
            </div>
          </form>
          <div className="flex lg:h-[20%] h-[50%] items-end justify-center ">
            <span className="text-sm font-small text-gray-500 pb-3">
              By signing up, you agree to our <br />
              <span
                className="text-sky-500 cursor-pointer"
                onClick={openTermsModal}
              >
                Terms,
              </span>
              and{" "}
              <span
                className="text-sky-500 cursor-pointer"
                onClick={openPrivacyModal}
              >
                Privacy Policy
              </span>
              .
            </span>
          </div>

          <TermsModal
            isOpen={isTermsModalOpen}
            onClose={() => setIsTermsModalOpen(false)}
            title="Terms of Service"
            sections={termsSections}
          />

          <PrivacyModal
            isOpen={isPrivacyModalOpen}
            onClose={() => setIsPrivacyModalOpen(false)}
            title="Privacy Policy"
            sections={privacySections}
          />
        </div>
      </div>
    </div>
  );
};
