import React from "react";
import { useNavigate } from "react-router-dom";
import { ActionModal } from "../core/Modals/ActionModal";

export const AnonymousStatus = ({ setIsPopupOpen }) => {
  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const verifyEmailHandler = () => {
    navigate("/anonymous-verification/email");
  };

  const navigate = useNavigate();
  return (
    <ActionModal
      close={handleClose}
      ModalTitle="Work Email Verification"
      ModalBodyContent="Your work email isn’t verified yet! Verify it now to unlock the option for anonymous posting."
      ActionButtonValue="Verify"
      ActionButtonLogic={verifyEmailHandler}
    />

    // <div className="flex flex-col lg:gap-7 gap-5 items-center justify-center p-5 lg:w-full ">
    //   <div className="flex flex-col items-center justify-center lg:gap-0.5 gap-1">
    //     <h2 className="text-lg font-bold">Work Email Verification Required</h2>
    //     <p className="text-center font-medium text-sm text-gray-500">
    //       Your work email isn’t verified yet! Verify it now to unlock the option
    //       for anonymous posting.
    //     </p>
    //   </div>

    //   <div className="flex flex-col w-full">
    //     <button
    //       className="p-2 border hover:border-black"
    //       onClick={verifyEmailHandler}
    //     >
    //       Verify Work Email
    //     </button>
    //     <button
    //       className="p-2 hover:border hover:border-black"
    //       onClick={handleClose}
    //     >
    //       Keep Posting Publicly
    //     </button>
    //   </div>
    // </div>
  );
};
