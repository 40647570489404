import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const ReportBug = () => {
  const [formData, setFormData] = useState({ feedback: "" });
  const [charCount, setCharCount] = useState(0);
  const navigate = useNavigate();
  const [isShaking, setIsShaking] = useState(false);
  const [showError, setShowError] = useState(false);

  const submitHandler = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Reporting your bug...");

    if (formData.feedback.trim() === "") {
      setIsShaking(true);
      setShowError(true);
      setTimeout(() => setIsShaking(false), 500);
      toast.dismiss(toastId);
      toast.error("Feedback cannot be empty.");
      return;
    }

    try {
      const userId = 103;
      const response = await axios.post(
        `http://localhost:4001/api/v2/getBugReports/${userId}`,
        {
          reason: formData.feedback,
        }
      );

      if (response.status === 201) {
        toast.dismiss(toastId);
        toast.success("Your bug has been reported successfully");
        setFormData({ feedback: "" });
        setCharCount(0);
        setShowError(false);
        navigate("/dashboard");
      } else {
        throw new Error("Failed to report bug");
      }
    } catch (error) {
      console.error("Error reporting bug:", error);
      toast.dismiss(toastId);
      toast.error("Report failed. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 500) {
      setFormData({ feedback: inputValue });
      setCharCount(inputValue.length);
      if (inputValue.trim() !== "") {
        setShowError(false);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen gap-4">
      <div className="flex items-center flex-col justify-center w-[325px] mb-[6rem]">
        <div className="mt-12 w-full text-center mb-5">
          <div className="text-3xl font-semibold text-gray-900 text-left">
            <span>Report Your Bug</span>
          </div>
          <div className="mt-3">
            <form
              className="flex flex-col items-center"
              onSubmit={submitHandler}
            >
              <div className="relative">
                <textarea
                  placeholder="We apologize for any inconvenience experiencing the app. Please tell us about it and we would try our best to fix it"
                  value={formData.feedback}
                  onChange={handleInputChange}
                  className={`p-3 text-sm w-[330px] h-[300px] border rounded-md mt-2 ${
                    isShaking ? "animate-shake" : ""
                  } ${showError ? "border-red-600" : ""}`}
                />
                <span className="absolute right-3 bottom-3 text-gray-500 text-sm">
                  {charCount}/500
                </span>
              </div>
              <button className="bg-white w-[305px] h-[50px] border border-black text-black rounded-full mt-8 hover:bg-black hover:text-white">
                <span>Submit</span>
              </button>
              <button
                type="button"
                className="bg-transparent text-base text-black underline rounded-full mt-3 hover:text-blue-600"
                onClick={() => navigate("/settings")}
              >
                <span>Back</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
