import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Images/Icons/MirrorNewLogo.svg";
import imageLogo from "../../Images/Icons/Image-Upload.svg";
import Modal from "../../Components/UserProfileByID/Modal";

export const EditProfileImageNew = () => {
  //   const user = localStorage.getItem("user", JSON.stringify);

  const [modalOpen, setModalOpen] = useState(false);
  const avatarUrl = useRef(imageLogo);
  const updateAvatar = (imgSrc) => {
    avatarUrl.current = imgSrc;
  };

  const submitHandler = (event) => {
    event.preventDefalut();
  };
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen gap-4">
      <div className=" flex items-center flex-col justify-center w-[325px]   ">
        <div className="flex flex-col gap-2">
          <h2 className="text-gray-900 text-center text-4xl font-semibold ">
            <img src={Logo} alt="Mirror Logo" />
          </h2>
        </div>
        <div className="mt-12  w-full text-left">
          <div className="font-semibold text-3xl   ">
            Pick a profile picture
          </div>

          <span className="text-sm fomt-normal mt-[450px] text-gray-500 ">
            We will not show your name or profile picture when you post reviews
            anonymously
          </span>
        </div>

        <div className="mt-4">
          <div
            className="flex flex-col items-center gap-[20px]"
            onSubmit={submitHandler}
          >
            <div
              className="bg-white rounded-lg shadow-md overflow-hidden items-center"
              onClick={() => setModalOpen(true)}
            >
              <div className="">
                <div
                  id="image-preview"
                  className="max-w-sm p-6 mb-4 bg-gray-100 border-dashed border-2 border-gray-400 rounded-lg items-center mx-auto text-center cursor-pointer"
                >
                  <label htmlFor="upload" className="cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-8 h-8 text-gray-700 mx-auto mb-4"
                    >
                      {/* SVG path */}
                    </svg>
                    <div className="flex flex-col justify-center items-center mt-[-30px]">
                      <img
                        src={imageLogo}
                        className="w-[100px] pb-4"
                        alt="Upload Icon"
                      />
                      <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-700">
                        Upload picture
                      </h5>
                      <p className="font-normal text-sm text-gray-400 md:px-6">
                        Photo size should be less than{" "}
                        <b className="text-gray-600">2mb</b>
                      </p>
                      <p className="font-normal text-sm text-gray-400 md:px-6">
                        and should be in{" "}
                        <b className="text-gray-600">JPG, PNG, or GIF</b> divat.
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            {modalOpen && (
              <Modal
                updateAvatar={updateAvatar}
                closeModal={() => setModalOpen(false)}
                toRedirect="/edit-profile/username"
              />
            )}

            {/* Submit button */}
            <div className="flex flex-col gap-2 mt-4">
              <button className="bg-black w-[325px] h-[58px] text-white rounded-full">
                <span>Submit</span>
              </button>

              <Link to="/edit-profile/username">
                <span className="text-sm font-medium underline text-gray-600">
                  Skip for now
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
