import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";

export const UserProfileCard = forwardRef(
  (
    {
      fullName,
      username,
      email,
      image,
      id,
      currentCompany,
      currentRole,
      location,
      userRating,
      reviewCount,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const ClickHandler = () => {
      navigate(`/user-profile/${username}`);
    };

    return (
      <div
        ref={ref}
        tabIndex={0}
        className={`bg-gray-50 lg:w-full ml-1 mr-1 lg:mt-2 lg:mr-0 lg:ml-0 flex items-center justify-between lg:rounded-xl 
        border-b-2 cursor-pointer`}
        onClick={ClickHandler}
        onKeyDown={(e) => {
          if (e.key === "Enter") ClickHandler();
        }}
      >
        <div className="p-2 flex items-center gap-5">
          <div className="ml-1">
            <img
              className="hover:border-black lg:w-[40px] lg:h-[40px] w-[35px] h-[35px] hover:shadow-lg hover:border rounded-full"
              src={image}
              alt={`${fullName} profile`}
            />
          </div>
          <div className="flex flex-col items-start gap-0.5">
            <h2 className="font-medium text-sm">{fullName}</h2>
            <p className="font-normal text-xs" title="username">
              @{username}
            </p>
            <span className="font-normal text-xs text-wrap text-start text-gray-400">
              {currentRole}, {currentCompany}
            </span>
          </div>
        </div>
        <div className="p-5 flex items-center gap-1" title="User Rating">
          {reviewCount >= 1 && <span className="text-sm">{userRating}</span>}
          {userRating !== "NA" && <FaStar size={10} />}
        </div>
      </div>
    );
  }
);
