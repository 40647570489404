import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import { MdVerified } from "react-icons/md";

import { endpoints } from "../../services/apis";

const { VERIFY_PROFILE_API } = endpoints;

export const VerifyUser = () => {
  const { userId } = useParams();
  const [formData, setFormData] = useState({
    companyName: "",
    workEmail: "",
  });
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const [shakeComapanyName, setShakeComapanyName] = useState(false);
  const [errorComapanyName, setErrorComapanyName] = useState(false);

  const [shakeComapanyEmail, setShakeComapanyEmail] = useState(false);
  const [errorComapanyEmail, setErrorComapanyEmail] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value.length === 1 && index < 5) {
      document.getElementById(`otp${index + 1}`).focus();
    }
  };

  const handleRequestAccess = async () => {
    try {
      console.log("company", formData.companyName);
      console.log("workEmail", formData.workEmail);

      const toastId = toast.loading("Sending Data..");

      setShakeComapanyName(false);
      setShakeComapanyEmail(false);
      errorComapanyName(false);
      errorComapanyEmail(false);

      let hasError = false;

      if (!formData.companyName) {
        setShakeComapanyName(true);
        setErrorComapanyName(true);
        toast.dismiss(toastId);
        hasError = true;
        return;
      }
      if (!formData.workEmail) {
        setShakeComapanyEmail(true);
        setErrorComapanyEmail(true);
        hasError = true;
        toast.dismiss(toastId);
        return;
      }

      if (hasError) {
        toast.dismiss(toastId);
        toast.error("Please fill in all the required fields");
        setTimeout(() => {
          setShakeComapanyName(false);
          setShakeComapanyEmail(false);
        }, 500);
        return;
      }

      const response = await axios.post(
        VERIFY_PROFILE_API,
        // `http://localhost:4001/api/v2/verifyUser/103`,
        {
          company: formData.companyName,
          email: formData.workEmail,
        }
      );

      console.log("response", response);

      if (
        response.data.message ===
        "User verification approved and anonymity granted"
      ) {
        toast.success("User verified and approved");
        setSubmitted(true);
      } else if (response.data.message === "User verification pending") {
        toast.success("User verification pending");
        setSubmitted(true); // Enable OTP input fields after submission
      } else {
        toast.error("Unexpected status received");
      }
    } catch (error) {
      console.error("Error verifying company domain:", error);
      toast.error("Internal Server Error. Please try again later.");
    }
  };

  // const handleVerifyOTP = async (e) => {
  //   e.preventDefault();
  //   const enteredOtp = otp.join("");
  //   try {
  //     const response = await axios.post(
  //       `http://localhost:4001/api/v2/verifyOTP/`,
  //       {
  //         otp: enteredOtp,
  //       }
  //     );

  //     console.log("OTP verification response", response);

  //     if (response.data.success) {
  //       toast.success("OTP verified successfully");
  //       // Redirect to profile or any other page after OTP verification
  //     } else {
  //       toast.error("Invalid OTP. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error verifying OTP:", error);
  //     toast.error("Internal Server Error. Please try again later.");
  //   }
  // };

  const handleVerifyOTP = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Verifying OTP...");

    try {
      // Simulating API call for OTP verification
      await new Promise((resolve) => setTimeout(resolve, 2000));

      toast.dismiss(toastId);
      toast.success("Email verified successfully");

      navigate(`/user-profile/${userId}`);
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("OTP verification failed. Please try again.");
    }
  };

  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-md p-8">
      <div className="text-center flex flex-col items-center w-[500px] max-w-md">
        <MdVerified className="text-6xl text-gray-900 mb-4" />
        <h2 className="text-3xl font-semibold text-gray-900 mb-4 mt-2">
          Verify Your Profile
        </h2>
        <p className="text-gray-900 font-medium mb-4 mt-1">
          Please provide the following details for verification of your profile.
        </p>
        <div className="mb-4 mt-3">
          <div className="relative">
            <input
              type="text"
              name="companyName"
              placeholder=""
              value={formData.companyName}
              onChange={handleChange}
              className={`bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[69px] text-sm border rounded-md ${
                shakeComapanyName ? "animate-shake" : ""
              } ${errorComapanyName ? "border-red-600" : ""}`}
            />
            <label
              className={`absolute top-6 left-1 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-700 ${
                formData.companyName && "text-xs text-black -translate-y-8"
              }`}
              style={{
                backgroundColor: formData.companyName ? "#fff" : "transparent",
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
            >
              Company Name
            </label>
          </div>
          <div className="relative mt-2">
            <input
              type="text"
              name="workEmail"
              placeholder=""
              value={formData.workEmail}
              onChange={handleChange}
              className={`bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[69px] text-sm border rounded-md ${
                shakeComapanyEmail ? "animate-shake" : ""
              } ${errorComapanyEmail ? "border-red-600" : ""}`}
            />
            <label
              className={`absolute top-6 left-1 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-700 ${
                formData.workEmail && "text-xs text-black -translate-y-8"
              }`}
              style={{
                backgroundColor: formData.workEmail ? "#fff" : "transparent",
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
            >
              Work Email
            </label>
          </div>
        </div>
        {submitted && (
          <div className="mt-5 flex items-center justify-center space-x-4">
            {otp.map((_, index) => (
              <input
                key={index}
                id={`otp${index}`}
                type="text"
                maxLength="1"
                className="p-3 text-sm w-10 h-10 text-center border border-black rounded-md"
                value={otp[index]}
                onChange={(e) => handleOtpChange(e, index)}
              />
            ))}
          </div>
        )}
        {submitted && (
          <div className="mt-2 text-gray-500 text-md text-left mb-7">
            Haven’t received the verification code on your mobile. Click
            on&nbsp;
            <Link to="#" className="text-blue-600 hover:underline">
              Resend
            </Link>
          </div>
        )}
        <button
          className="w-[260px] bg-white text-black py-2 px-4 rounded-full border border-black hover:bg-black hover:text-white mt-8"
          onClick={submitted ? handleVerifyOTP : handleRequestAccess}
        >
          {submitted ? "Verify" : "Submit"}
        </button>
        <Link
          to={`/user-profile/${userId}`}
          className="text-gray-800 underline mt-2 hover:text-blue-500"
        >
          Back
        </Link>
      </div>
    </div>
  );
};
