import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { store } from "../index";
import { refreshAccessToken } from "../slices/authSlice";
import { jwtDecode } from "jwt-decode";

const axiosInstance = axios.create({});

function isTokenExpired(token) {
  const decoded = jwtDecode(token);
  const now = Date.now() / 1000;
  return decoded.exp < now;
}

axiosInstance.interceptors.request.use(
  async (config) => {
    const state = store.getState().auth;
    let accessToken = state.accessToken;

    if (accessToken && isTokenExpired(accessToken)) {
      accessToken = await store.dispatch(refreshAccessToken()).unwrap();
    }

    config.headers["Authorization"] = `Bearer ${accessToken}`;
    config.headers["M-Unique-ID"] = uuidv4();
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export const apiConnector = (method, url, bodyData, headers, params) => {
  return axiosInstance({
    method: method,
    url: url,
    data: bodyData || null,
    headers: headers
      ? { ...headers, "M-Unique-ID": uuidv4() }
      : { "M-Unique-ID": uuidv4() },
    params: params || null,
  });
};
