import React from "react";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";

export const CustomStars = ({ count, value, onChange, starSize }) => {
  const handleStarClick = (index) => {
    onChange(index + 1);
  };

  return (
    <div className="flex gap-2">
      {Array.from({ length: count }, (_, index) => (
        <span key={index} onClick={() => handleStarClick(index)}>
          {index < value ? (
            <FaStar color="515152" size={starSize} />
          ) : (
            <CiStar size={starSize} />
          )}
        </span>
      ))}
    </div>
  );
};
