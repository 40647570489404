import toast from "react-hot-toast";
import { apiConnector } from "../../../../services/apiconnector";
import { ActionModal } from "../../../core/Modals/ActionModal";

export const DeletePost = ({ close, id, fetchPostData }) => {
  const deleteHandler = async () => {
    const toastId = toast.loading("Deleting Post");

    try {
      const res = await apiConnector(
        "POST",
        `https://api.atmirrors.com/posts/delete/${id}`
      );
      console.log("Post Deleted SucessFully", res);
      close();
      fetchPostData();
      toast.dismiss(toastId);
      toast.success("Post Deleted SuccessFully !");
    } catch (error) {
      console.log(error);
      toast.dismiss(toastId);
      toast.error(`Error: ${error}`);
    }
  };

  return (
    <>
      <ActionModal
        close={close}
        ModalTitle="Delete Post"
        ModalBodyContent="Are you sure you want to delete this post? This action cannot be
        undone."
        ActionButtonValue="Delete"
        ActionButtonLogic={deleteHandler}
      />
    </>
  );
};
