import { toast } from "react-hot-toast";

import { setLoading, setToken } from "../../slices/authSlice";
import { setUser } from "../../slices/profileSlice";
import { endpoints } from "../apis";
import { apiConnector } from "../apiconnector";

const {
  SIGNUP_API,
  SENDOTP_API,
  LOGIN_API,
  RESET_PASSWORD_API,
  RESET_PASSWORD_TOKEN_API,
  CHANGE_PASSWORD_API,
  UPDATE_DISPLAY_PICTURE_API,
} = endpoints;

export function sendOtp(email, recaptchaValue, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Re-Sending OTP...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", SENDOTP_API, {
        email,
        recaptchaValue,
        checkUserPresent: true,
      });

      // console.log("recaptcha value in authApi", recaptchaValue);
      console.log("SENDOTP API RESPONSE............", response);

      console.log(response.data.success);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.success("OTP Sent Successfully");
      navigate("/verify-otp");
    } catch (error) {
      console.log("SENDOTP API ERROR............", error);
      toast.error("Could Not Send OTP");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function sendOtpNew(email, phoneNumber, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Re-Sending OTP...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        "https://api.atmirrors.com/auth/init-register",
        {
          email,
          phoneNumber,
        }
      );

      // console.log("recaptcha value in authApi", recaptchaValue);
      console.log("SENDOTP API RESPONSE............", response);

      console.log(response.data.success);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.success("OTP Sent Successfully");
      navigate("/verify-otp");
    } catch (error) {
      console.log("SENDOTP API ERROR............", error);
      toast.error("Could Not Send OTP");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function signUp(
  accountType,
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
  otp,
  navigate
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", SIGNUP_API, {
        accountType,
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        otp,
      });

      console.log("SIGNUP API RESPONSE............", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      toast.success("Signup Successful");
      navigate("/login");
    } catch (error) {
      console.log("SIGNUP API ERROR............", error);
      toast.error("Signup Failed");
      navigate("/signup");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function logout(navigate) {
  return (dispatch) => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    toast.success("Logged Out");
    navigate("/");
  };
}

export function getPasswordResetToken(email, setEmailSent) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const toastId = toast.loading("Sending password reset email...");

      const response = await apiConnector("POST", RESET_PASSWORD_TOKEN_API, {
        email,
      });

      console.log("RESET PASSWORD TOKEN RESPONSE....", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.remove(toastId);
      toast.success(
        "Email Sent Successfully, Please Check Your Email to Continue Further"
      );
      // setEmailSent(true);
    } catch (error) {
      console.log("RESET PASSWORD TOKEN Error", error);
      toast.error("Failed to send email for resetting password");
    }
    dispatch(setLoading(false));
  };
}

export function resetPassword(password, confirmPassword, token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", RESET_PASSWORD_API, {
        password,
        confirmPassword,
        token,
      });

      console.log("RESET Password RESPONSE ... ", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.success("Password has been reset successfully");
    } catch (error) {
      console.log("RESET PASSWORD TOKEN Error", error);
      toast.error("Unable to reset password");
    }
    dispatch(setLoading(false));
  };
}

export function changepassword(
  oldPassword,
  newPassword,
  confirmNewPassword,
  token,
  navigate
) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const toastId = toast.loading(
      "Almost there! Setting up your new password..."
    );

    try {
      if (oldPassword === newPassword) {
        toast.dismiss(toastId);
        toast.error("ERROR: Old and new passwords must be different.");
        return;
      }

      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`!@#\$%^&*()_\-+={[}\]|\\:;"'<,>.?\/]).{8,}$/;

      if (!passwordRegex.test(newPassword)) {
        toast.dismiss(toastId);
        toast.error(
          "Password must meet the following criteria:\n" +
            "- At least 8 characters long\n" +
            "- Contain at least one uppercase letter\n" +
            "- Contain at least one lowercase letter\n" +
            "- Contain at least one number\n" +
            "- Contain at least one special character (@$!%*?&)"
        );
        return;
      }

      if (newPassword === confirmNewPassword) {
        await apiConnector(
          "POST",
          CHANGE_PASSWORD_API,
          { newPassword, oldPassword },
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        )
          .then((res) => {
            toast.dismiss(toastId);
            toast.success("Your password has been successfully changed.");
            console.log(res);
            navigate("/login");
          })
          .catch((error) => {
            toast.dismiss(toastId);
            toast.error(error.response.data.message);
          });
      } else {
        toast.dismiss(toastId);
        toast.error("Password mismatch detected. Please verify and try again");
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateDisplayPicture(displayPicture, token, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Updating Your Profile Image..."); // Store toast ID

    try {
      await apiConnector("PUT", UPDATE_DISPLAY_PICTURE_API, displayPicture, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      })
        .then((res) => {
          toast.dismiss(toastId);
          toast.success("Profile Image Updated successfully");
          console.log("Profile Image Updated, Data received:", res);
          dispatch(setUser(res.data.data));

          navigate("/edit-profile/username");
        })
        .catch((error) => {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };
}
