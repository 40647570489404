import React, { useState, useRef, useEffect } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SignupUser } from "../../Components/Auth/SignupUser";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSignupData } from "../../slices/authSlice";
import ReCAPTCHA from "react-google-recaptcha";
import Logo from "../../Images/Icons/MirrorNewLogo.svg";
import { sendOtp } from "../../services/operations/authAPI";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import zxcvbn from "zxcvbn";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import flags from "react-phone-number-input/flags";
import LazyLoad from "react-lazyload";

export const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const siteKey = process.env.REACT_APP_SITE_KEY;

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    password: "",
    dateOfBirth: "2000-01-01",
    confirmPassword: "",
    isReferralCode: "",
  });

  const [isUsingPhone, setIsUsingPhone] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("referral");

    if (code) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        isReferralCode: code,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        isReferralCode: "",
      }));
    }
  }, [location.search]);

  const [recaptchaValue, setRecaptchaValue] = useState("");
  const captchaRef = useRef();
  const captchaHandler = (value) => {
    setRecaptchaValue(value);
    // console.log(value);
  };

  const { fullName, email, phone, password, confirmPassword } = formData;

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  // Function to calculate password strength in real-time
  const getPasswordStrength = (password) => {
    // if (password === "") return "Please enter a password";

    const result = zxcvbn(password);
    const score = result.score;

    if (score >= 0 && score < 2) {
      return "Weak Password";
    } else if (score >= 2 && score < 4) {
      return "Okay Password";
    } else if (score >= 4) {
      return "Strong Password";
    }
  };

  const getColorForStrength = (strength) => {
    if (strength === "Weak Password") {
      return "red";
    } else if (strength === "Okay Password") {
      return "orange";
    } else if (strength === "Strong Password") {
      return "green";
    }
  };

  const getColorClassForStrength = (strength) => {
    if (strength === "Weak Password") {
      return "border-red-500 focus:border-red-700 focus:ring-red-700";
    } else if (strength === "Okay Password") {
      return "border-yellow-500 focus:border-yellow-700 focus:ring-yellow-700";
    } else if (strength === "Strong Password") {
      return "border-green-500 focus:border-green-700 focus:ring-green-700";
    } else {
      return "";
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const toggleUsingPhone = () => {
    setIsUsingPhone(!isUsingPhone);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    // loading toast
    const toastId = toast.loading("Sending OTP..."); // Store toast ID

    try {
      // Email validation
      const disallowedEmailDomains = ["yahoo.com", "outlook.com"];
      const emailDomain = formData.email.split("@")[1];
      if (disallowedEmailDomains.includes(emailDomain)) {
        toast.dismiss(toastId);
        toast.error("Please enter a valid work email. ");
        return;
      }

      // const passwordRegex =
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`!@#\$%^&*()_\-+={[}\]|\\:;"'<,>.?\/]).{8,}$/;

      // if (!passwordRegex.test(formData.password)) {
      //   toast.dismiss(toastId);
      //   toast.error(
      //     "Password must meet the following criteria:\n" +
      //       "- At least 8 characters long\n" +
      //       "- Contain at least one uppercase letter\n" +
      //       "- Contain at least one lowercase letter\n" +
      //       "- Contain at least one number\n" +
      //       "- Contain at least one special character (@$!%*?&)"
      //   );
      //   return;
      // }

      if (!recaptchaValue) {
        toast.dismiss(toastId);
        toast.error("Invalid reCaptcha Value");
        return;
      }

      if (formData.strength === "Weak Password") {
        toast.dismiss(toastId);
        toast.error("Password is too weak. Please choose a stronger password.");
        return;
      }

      const SignedupData = {
        fullName: formData.password,
        ...(isUsingPhone
          ? { contactInfo: formData.phone }
          : { contactInfo: formData.email }),
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        isReferralCode: formData.isReferralCode,
      };

      if (formData.password === formData.confirmPassword) {
        const signupData = { ...formData };

        dispatch(setSignupData(signupData));
        captchaRef.current.reset();

        // console.log("recaptcha value in Signup", recaptchaValue);
        // dispatch(sendOtp(formData.email, recaptchaValue, navigate));

        axios
          .post("https://mirror-express-server.onrender.com/api/v1/sendotp", {
            //   .post("http://localhost:8000/api/v1/sendotp", {
            //...SignedupData
            ...formData,
            recaptchaValue,
          })
          .then((res) => {
            toast.dismiss(toastId);
            toast.success("OTP Sent Succefully");
            console.log("Data received:", res);
            navigate("/verify-otp");
          })
          .catch((error) => {
            toast.dismiss(toastId);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              toast.error(error.response.data.message);
            } else {
              toast.error(
                "An error occurred while sending OTP. Please try again."
              );
            }
            console.log(error);
            console.log(error.message);
          });
        // Call the signup API
        // const responseData = await SignupUser(formData);

        // // Log the data to the console
        // console.log("Signup successful. Data received:", responseData);

        // // If successful, navigate to the dashboard or another page
        // navigate("/dashboard");

        // // Display success toast
        // toast.success("Signed up successfully");
      } else {
        toast.dismiss(toastId);
        toast.error("Passwords did not match. Try again.");
      }
    } catch (error) {
      // Display error toast
      toast.dismiss(toastId);
      toast.error(error.message);
    }
  };

  const [step, setStep] = useState(1);
  const totalSteps = 3;
  const progressPercentage = (step / totalSteps) * 100;

  const birthDate = new Date(formData.dateOfBirth);
  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs);
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);

  const handleNextStep = () => {
    if (isUsingPhone) {
      const phoneNumber = parsePhoneNumberFromString(formData.phone);

      if (!phoneNumber || !phoneNumber.isValid()) {
        toast.error("Please enter a valid phone number.");
        return;
      }

      const expectedLength =
        phoneNumber.countryCallingCode.length +
        phoneNumber.nationalNumber.length;

      if (formData.phone.replace(/[^\d]/g, "").length !== expectedLength) {
        toast.error(
          "Please enter a valid phone number for the selected country."
        );
        return;
      }
    } else {
      if (!validateEmail(formData.email)) {
        toast.error("Please enter a valid email address.");
        return;
      } else {
        if (age < 18) {
          toast.error("You must be at least 18 years old to sign up.");
          return;
        }
      }
    }

    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "password") {
      const strength = value ? getPasswordStrength(value) : "";
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        strength: strength,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="relative">
              <input
                required
                autoComplete="name"
                onChange={handleChange}
                value={formData.fullName}
                placeholder=""
                name="fullName"
                type="text"
                className={`p-3 text-sm w-[325px] h-[58px] border rounded-md ${
                  formData.fullName && "not-empty"
                }`}
              />
              <label
                className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                  formData.fullName && "text-xs text-gray-700 -translate-y-9 "
                }`}
                style={{
                  backgroundColor: formData.fullName ? "#fff" : "transparent",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                Full Name
              </label>
            </div>
            <div className="flex flex-col items-right text-right">
              {isUsingPhone ? (
                <div className="relative">
                  <PhoneInput
                    flags={flags}
                    defaultCountry="IN"
                    required
                    placeholder=""
                    value={formData.phone}
                    onChange={(value) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        phone: value,
                      }))
                    }
                    className={`p-3 text-sm w-[325px] h-[58px] border rounded-md mt-2 focus:outline-none focus:ring-0 ${
                      formData.phone && "not-empty"
                    }`}
                  />
                  {/* <label
                    className={`absolute top-9  left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                      formData.phone && "text-xs text-gray-700 -translate-y-9 "
                    }`}
                    style={{
                      backgroundColor: formData.phone ? "#fff" : "transparent",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    Phone Number
                  </label> */}
                </div>
              ) : (
                <div className="relative">
                  <input
                    required
                    onChange={handleChange}
                    value={formData.email}
                    placeholder=""
                    name="email"
                    type="email"
                    className={`p-3 text-sm w-[325px] h-[58px] border rounded-md mt-2 ${
                      formData.email && "not-empty"
                    }`}
                  />
                  <label
                    className={`absolute top-9 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                      formData.email && "text-xs text-gray-700 -translate-y-9 "
                    }`}
                    style={{
                      backgroundColor: formData.email ? "#fff" : "transparent",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    Email address
                  </label>
                </div>
              )}
              <span
                className="text-xs p-1 text-right text-gray-600 cursor-pointer underline hover:text-blue-600 "
                onClick={toggleUsingPhone}
              >
                {isUsingPhone ? "Use email instead" : "Use phone instead"}
              </span>
            </div>
            <div className="relative">
              <input
                required
                autoComplete="bday"
                onChange={handleChange}
                value={formData.dateOfBirth}
                placeholder="Date of Birth"
                name="dateOfBirth"
                type="date"
                className="bg-richblack-800  text-richblack-5  p-3 w-[325px]  h-[59px] text-sm border rounded-md mt-1"
              />
            </div>
          </>
        );
      case 2:
        return (
          <div className="flex flex-col gap-2 items-center justify-center">
            <div className="relative">
              <input
                required
                onChange={handleChange}
                value={formData.password}
                placeholder=""
                name="password"
                type={showPassword ? "text" : "password"}
                className={`p-3 text-sm w-[325px] h-[58px] border rounded-md ${
                  formData.password && "not-empty"
                }`}
              />
              <label
                className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                  formData.password && "text-xs text-gray-700 -translate-y-9 "
                }`}
                style={{
                  backgroundColor: formData.password ? "#fff" : "transparent",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                Password
              </label>

              <span
                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <VscEye size={25} />
                ) : (
                  <VscEyeClosed size={25} />
                )}
              </span>
            </div>

            <div style={{ color: getColorForStrength(formData.strength) }}>
              <div className="text-right  w-[325px] text-xs font-medium">
                {formData.strength}
              </div>
            </div>

            <div className="relative">
              <input
                required
                onChange={handleChange}
                value={formData.confirmPassword}
                placeholder=""
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                className={`p-3 text-sm w-[325px] h-[58px] border rounded-md ${
                  formData.confirmPassword && "not-empty"
                }`}
              />
              <label
                className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                  formData.confirmPassword &&
                  "text-xs text-gray-700 -translate-y-9 "
                }`}
                style={{
                  backgroundColor: formData.confirmPassword
                    ? "#fff"
                    : "transparent",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                Confirm password
              </label>
              <span
                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? (
                  <VscEye size={25} />
                ) : (
                  <VscEyeClosed size={25} />
                )}
              </span>
            </div>
            <div className="mt-2">
              <ReCAPTCHA
                ref={captchaRef}
                onChange={captchaHandler}
                sitekey={process.env.REACT_APP_SITE_KEY}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center h-screen gap-3 ">
        <h2 className="text-gray-900 text-center text-4xl font-semibold ">
          <LazyLoad height={200} offset={100}>
            <img src={Logo} alt="Logo" />
          </LazyLoad>
        </h2>
        <span className="font-normal text-sm text-gray-500  ">
          Create your account
        </span>

        <div className="flex gap-1  text-sm font-medium">
          <span>Step</span>
          <div>
            {" "}
            <span className="text-blue-800">{step}</span> <span>/2</span>
          </div>
        </div>

        <div class="w-[300px] bg-gray-200 rounded-full h-1.5 mb-4 ">
          <div
            class="bg-black h-1.5 rounded-full transition-smooth"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>

        <div className="mt-3">
          <form
            onSubmit={submitHandler}
            className="flex flex-col justify-center items-center gap-1"
          >
            {renderStep()}
            <div className="flex flex-col gap-2 mt-5">
              {step === 2 && (
                <div className="flex flex-col gap-3">
                  <button
                    className="bg-black w-[325px] h-[58px] text-white rounded-full"
                    type="submit"
                  >
                    Sign Up
                  </button>

                  <span
                    className="text-sm underline cursor-pointer"
                    onClick={handlePrevStep}
                  >
                    Previous
                  </span>
                </div>
              )}

              {step !== 2 && (
                <button
                  className="bg-black w-[325px] h-[58px] text-white rounded-full"
                  type="button"
                  onClick={handleNextStep}
                >
                  Next
                </button>
              )}

              {step === 1 && (
                // <button
                //   className="w-[325px] h-[58px] border rounded-full"
                //   type="button"
                //   onClick={handlePrevStep}
                // >
                //   Previous
                // </button>
                <div className="mt-3 text-sm">
                  <span className="text-gray-500">
                    Already have an account?{" "}
                  </span>
                  <span
                    className="font-semibold underline"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Login
                  </span>
                </div>
              )}

              {/* <button
                className=" flex justify-center items-center gap-x-3  w-[325px] border h-[50px] rounded-full"
                type="button"
                onClick={() => navigate("/login")}
              >
                Skip for now
              </button> */}
            </div>
          </form>

          <div className="flex h-[50%] items-end justify-center ">
            <span className="text-sm font-small text-gray-500 pb-3">
              By signing up, you agree to our <br />
              <Link to="/terms">
                {" "}
                <span className="text-sky-500"> Terms,</span>and{" "}
                <span className="text-sky-500">Privacy Policy</span>.
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
