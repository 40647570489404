import React from "react";
import { BsIncognito } from "react-icons/bs";

export const AnonymityConfirmationModal = ({
  setShowAnonymityConfirmationModal,
}) => {
  const handleCloseModal = () => {
    setShowAnonymityConfirmationModal(false);
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-70 z-50`}
    >
      <div className="bg-white p-4 rounded-md shadow-md max-w-md">
        <div className="flex flex-col items-center">
          <BsIncognito className="text-7xl mb-2" />
          <div className="text-2xl font-semibold mb-2 mt-2">
            Anonymity Confirmation
          </div>
        </div>
        <p className="text-lg font-semibold text-gray-900 mt-2">
          Congratulation! You are now verified and can go anonymous.
        </p>
        <p className="text-md text-black mt-4">
          This verification would be valid for the next three months. With
          freedom comes responsibility. Take precaution while posting and never
          post your personally identifiable information.
        </p>
        <button
          onClick={handleCloseModal}
          className="bg-white text-black border border-black mt-4 px-5 py-1.5 mb-0 rounded-2xl hover:bg-black hover:text-white"
        >
          {" "}
          Close
        </button>
      </div>
    </div>
  );
};
