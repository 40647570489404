import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, Link } from "react-router-dom";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import Logo from "../../../Images/Icons/MirrorNewLogo.svg";
import { apiConnector } from "../../../services/apiconnector";

export const PasswordReset = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("token");

    if (tokenParam) {
      setToken(tokenParam);
    } else {
      toast.error("No token provided");
    }
  }, [location]);

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    if (!token) {
      toast.error("Token is missing. Cannot reset password.");
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    const toastId = toast.loading("Updating Password...");

    try {
      const response = await apiConnector(
        "POST",
        "https://api.atmirrors.com/password-reset/reset",
        {
          token,
          newPassword: formData.password,
        }
      );

      console.log("Password Reset", response);

      toast.dismiss(toastId);
      toast.success("Password updated successfully!");
      navigate("/auth/login");
    } catch (error) {
      toast.dismiss(toastId);
      toast.error(error.response?.data?.message || "Failed to reset password.");
    }

    // console.log(formData, token);
  };

  return (
    <div className="flex items-center flex-col justify-center w-screen h-screen">
      <div className="flex items-center flex-col justify-center w-[325px]">
        <div className="flex flex-col gap-2 justify-center items-center">
          <h2 className="text-gray-900 text-center text-4xl font-semibold">
            <img src={Logo} alt="Logo" />
          </h2>
          <span className="font-medium text-lg text-gray-500">
            Choose new password
          </span>
        </div>

        <div className="mt-8">
          <form
            className="flex flex-col items-center gap-[20px]"
            onSubmit={submitHandler}
          >
            <div className="relative">
              <input
                required
                onChange={changeHandler}
                value={formData.password}
                placeholder="Enter your password"
                name="password"
                type={showPassword ? "text" : "password"}
                className="p-3 text-sm w-[325px] h-[59px] border rounded-md"
              />
              <span
                className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <VscEye size={25} />
                ) : (
                  <VscEyeClosed size={25} />
                )}
              </span>
            </div>

            <div className="relative">
              <input
                required
                onChange={changeHandler}
                value={formData.confirmPassword}
                placeholder="Confirm password"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                className="p-3 text-sm w-[325px] h-[59px] border rounded-md"
              />
              <span
                className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? (
                  <VscEye size={25} />
                ) : (
                  <VscEyeClosed size={25} />
                )}
              </span>
            </div>

            <span className="font-normal text-sm text-gray-500">
              Almost there! Enter your new password and confirm it to complete
              the process.
            </span>

            <div className="mt-2">
              <button
                className="bg-black w-[325px] h-[58px] text-white rounded-md"
                disabled={loading}
              >
                <span>Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
