import React, { useState, useEffect } from "react";

const SkeletonLoading = () => {
  return (
    <div className="max-w-full md:max-w-3xl w-full shadow-md overflow-hidden mt-3 animate-pulse">
      <div className="md:flex-col m-4">
        {/* Skeleton loading content */}
        <div className="md:shrink-0 flex justify-between items-center">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-gray-300"></div>
            {/* Name and company */}
            <div className="flex flex-col">
              <div className="h-3 md:h-4 bg-gray-300 w-20 md:w-28"></div>
              <div className="h-2 md:h-3 mt-1 bg-gray-300 w-24 md:w-36"></div>
            </div>
          </div>
          <div className="flex justify-center items-start mt-3 gap-2">
            <div className="w-1 h-6 md:w-1 bg-gray-300 mb-3"></div>
          </div>
        </div>
        {/* Text below Name and company */}
        <div className="flex flex-col gap-1 text-left">
          <div className="ml-2 md:ml-3 mt-3 md:mt-4 h-2 md:h-3.5 bg-gray-300"></div>
          <div className="ml-2 md:ml-3 mt-1 h-2 md:h-3.5 bg-gray-300"></div>
          <div className="ml-2 md:ml-3 mt-1 h-2 md:h-3.5 bg-gray-300"></div>
          <div className="ml-2 md:ml-3 mt-1 h-2 md:h-3.5 w-24 md:w-36 bg-gray-300"></div>
        </div>
        {/* {Other features} */}
        <div className="flex justify-between items-center mt-5 h-[50px] ">
          <div className="ml-3 flex gap-3">
            <div className="w-1 h-6 md:w-2 md:h-8 bg-gray-300"></div>
            <div className="w-2 h-2 md:w-3 md:h-3 mt-1 md:mt-2 bg-gray-300"></div>
            <div className="w-1 h-6 md:w-2 md:h-8 bg-gray-300"></div>
            <div className="w-2 h-2 md:w-3 md:h-3 mt-1 md:mt-2 bg-gray-300"></div>
            <div className="w-3 h-3 ml-2 md:w-4 md:h-5 mt-1 bg-gray-300"></div>
            <div className="w-3 h-3 ml-1 md:w-4 md:h-5 mt-1 bg-gray-300"></div>
          </div>
          <div className="lg:text-sm lg:font-medium">
            <div className="h-3 md:h-4 bg-gray-300 w-10 md:w-14"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuoteLoading = ({ text }) => {
  return (
    <div className="max-w-full md:max-w-3xl w-full overflow-hidden p-3 pt-2 animate-pulse">
      <div className="text-center text-gray-700 font-bold md:text-sm lg:text-xl xl:text-xl">
        {text}
      </div>
    </div>
  );
};
const MultipleSkeletons = () => {
  const [texts, setTexts] = useState([
    "Feedback is the breakfast of champions - Ken Blanchard",
    "We all need people who will give us feedback. That's how we improve - Bill Gates",
    "The art of life is a constant readjustment to our surroundings - Kakuzo Okakura",
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numSkeletons, setNumSkeletons] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [texts]);

  useEffect(() => {
    const handleResize = () => {
      const viewportHeight = window.innerHeight;
      const skeletonHeight = 222;

      const maxSkeletons = Math.floor((viewportHeight - 222) / skeletonHeight);

      setNumSkeletons(maxSkeletons);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="flex flex-col items-center min-h-screen"
      style={{ paddingBottom: "110px", paddingTop: "60px" }}
    >
      {Array.from({ length: numSkeletons }).map((_, index) => (
        <SkeletonLoading key={index} />
      ))}
      <div className="flex-grow" />
      <QuoteLoading text={texts[currentIndex]} />
    </div>
  );
};

export default MultipleSkeletons;
