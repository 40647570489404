import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../Images/Icons/MirrorNewLogo.svg";

export const TermsAndPrivacy = ({ title, sections }) => {
  const navigate = useNavigate();
  return (
    <div className="w-screen h-screen flex items-center justify-center  ">
      <div
        className="bg-white px-4 md:px-6 rounded-lg overflow-y-auto overflow-x-auto relative"
        style={{ height: "90vh", width: "95vw", maxWidth: "1200px" }}
      >
        <div className="flex justify-between mb-4 sticky top-0 bg-white z-10 py-3 pt-1">
          <div className="flex flex-col ">
            <h2 className="text-gray-900 text-center text-3xl font-semibold ">
              <img src={Logo} alt="Logo" className="mb-2 w-[6.5rem]" />
              <h3 className="text-xl md:text-2xl item-center font-semibold">
                {title}
              </h3>
            </h2>
          </div>

          <button
            onClick={() => navigate("/auth/login")}
            className="bg-gray-200 h-[80%] hover:bg-black text-black hover:text-white py-1 px-2 md:py-1 md:px-3 rounded-lg shadow-md transition duration-300"
          >
            Login
          </button>
        </div>
        {sections.map((section, index) => (
          <div key={index} className="mb-4">
            <h4 className="text-md md:text-xl text-left font-semibold mb-2">
              {section.subheading}
            </h4>
            <div
              className="text-custom-sm md:text-base text-black text-left"
              dangerouslySetInnerHTML={{ __html: section.content }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
