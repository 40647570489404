import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setRefreshToken,
  setToken,
  updateUserDetailsSignupData,
} from "../../../slices/authSlice";
import Logo from "../../../Images/Icons/MirrorNewLogo.svg";
import { FaRegCheckCircle } from "react-icons/fa";
import { apiConnector } from "../../../services/apiconnector";
import { setUser } from "../../../slices/profileSlice";
import { RxCrossCircled } from "react-icons/rx";

export const ChooseUserName = () => {
  const apiUrl = "https://api.atmirrors.com/username/check";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ username: "" });
  const [shakeUserName, setShakeUserName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recommendedUserNames, setRecommendedUsernames] = useState([]);
  const [userNameAvailable, setUserNameAvailable] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const { signupData } = useSelector((state) => state.auth);
  // if (signupData === null) {
  //   navigate("/register/enter-email");
  // }

  useEffect(() => {
    const fetchUserNameRecommendations = async () => {
      setLoading(true);
      try {
        const responseData = await axios.get(
          `https://api.atmirrors.com/username/recommendations?fullName=${signupData?.fullName}`
        );
        setRecommendedUsernames(responseData.data);
      } catch (error) {
        console.error("Failed to load username recommendations:", error);
        toast.error("Failed to load username recommendations.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserNameRecommendations();

    return () => {
      setRecommendedUsernames([]);
    };
  }, [signupData]);

  useEffect(() => {
    if (formData.username.trim()) {
      if (debounceTimeout) clearTimeout(debounceTimeout);

      setDebounceTimeout(
        setTimeout(() => {
          checkUserNameAvailability();
        }, 500)
      );
    }

    return () => {
      if (debounceTimeout) clearTimeout(debounceTimeout);
    };
  }, [formData.username]);

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    setUserNameAvailable(null);
    setIsButtonDisabled(true);
  };

  const checkUserNameAvailability = async () => {
    try {
      const res = await axios.get(`${apiUrl}?username=${formData.username}`);

      if (res.data.exists) {
        setUserNameAvailable(false);
        setIsButtonDisabled(true);
        toast.error("Username already exists.");
      } else {
        setUserNameAvailable(true);
        setIsButtonDisabled(false);
        toast.success("Username is available.");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message || "Username already exists.");
        setUserNameAvailable(false);
      } else {
        console.error("Error checking username availability:", error);
        toast.error("Error checking username availability.");
      }
      setShakeUserName(true);
      setTimeout(() => setShakeUserName(false), 500);
    }
  };

  const handleSuggestedUsernameClick = (username) => {
    setFormData({ username });
    setUserNameAvailable(true);
    setIsButtonDisabled(false);
  };

  const renderRecommendedUsernames = () => {
    if (loading) return <span>Loading Username Suggestions...</span>;

    if (recommendedUserNames.length > 0) {
      return recommendedUserNames.map((name) => (
        <span
          key={name}
          onClick={() => handleSuggestedUsernameClick(name)}
          className="hover:underline cursor-pointer"
        >
          {name}
        </span>
      ));
    }

    return <span>No Username Suggestions Available</span>;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Registering your details...");

    if (!formData.username) {
      setShakeUserName(true);
      // setErrorEmail(true);
      toast.dismiss(toastId);
      toast.error("Please enter a username.");
      setTimeout(() => setShakeUserName(false), 500);
      return;
    }

    try {
      const response = await apiConnector(
        "POST",
        `https://api.atmirrors.com/auth/register`,
        { ...formData, ...signupData },
        { "Content-Type": "application/json" }
      );

      const { accessToken, refreshToken } = response.data.data;

      console.log("AccessToken in Register", accessToken);
      console.log("refreshToken in Register", refreshToken);

      axios
        .get("https://api.atmirrors.com/users", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          console.log("User details fetched successfully:", response.data.data);

          const user = response.data.data;

          dispatch(setUser(user));
          localStorage.setItem("user", JSON.stringify(user));
          console.log(user);

          toast.dismiss(toastId);
          toast.success("Registration successful! You’re all set.");
          console.log("User Created successfully. Data received:", response);

          dispatch(setToken(accessToken));
          localStorage.setItem("accessToken", JSON.stringify(accessToken));

          dispatch(setRefreshToken(refreshToken));
          localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
        });
    } catch (error) {
      console.error("Registration error:", error);
      toast.dismiss(toastId);
      toast.error(error.response?.data?.message || "Registration failed.");
    }
  };

  return (
    <div className="flex items-center flex-col justify-center w-screen h-screen">
      <div className="flex items-center flex-col justify-center w-[325px] h-[85%]">
        <div className="flex flex-col gap-4 text-center">
          <img src={Logo} alt="Logo" className="mx-auto" />
          <span className="font-normal text-sm text-gray-500">
            Create New Account
          </span>
        </div>

        <div className="mt-10 h-full">
          <form
            className="flex flex-col h-full items-center justify-between"
            onSubmit={submitHandler}
          >
            <div className="flex flex-col items-start w-full">
              <div className="relative w-full">
                <input
                  onChange={changeHandler}
                  value={formData.username}
                  placeholder="Enter username"
                  name="username"
                  type="text"
                  className={`bg-richblack-800 text-richblack-5 p-3 w-full h-[69px] text-sm border rounded-md ${
                    shakeUserName ? "animate-shake" : ""
                  } ${
                    userNameAvailable === false
                      ? "border-red-600"
                      : "border-gray-300"
                  }`}
                />
                {userNameAvailable === true && formData.username && (
                  <span
                    className="absolute top-[1.6rem] right-5"
                    title="Username is available"
                  >
                    <FaRegCheckCircle size="20" className="text-green-400" />
                  </span>
                )}
                {userNameAvailable === false && formData.username && (
                  <span
                    className="absolute top-[1.6rem] right-5"
                    title="Username is not available"
                  >
                    <RxCrossCircled size="20" className="text-red-600" />
                  </span>
                )}
              </div>

              <div className="flex justify-end">
                <span
                  className="text-xs font-medium text-gray-600 cursor-pointer underline hover:text-blue-600 mt-1"
                  onClick={checkUserNameAvailability}
                >
                  Check Username Availability
                </span>
              </div>

              <div className="flex flex-col p-2 gap-2 text-sm font-semibold mt-4 justify-start items-start">
                {renderRecommendedUsernames()}
              </div>
            </div>

            <div className="mt-8">
              <button
                type="submit"
                disabled={isButtonDisabled}
                className={`w-[325px] h-[50px] rounded-full ${
                  isButtonDisabled
                    ? "bg-gray-400 text-white cursor-not-allowed"
                    : "bg-black text-white"
                }`}
              >
                <span>Next</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
