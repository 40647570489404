import React, { Suspense, useState } from "react";
import { apiConnector } from "../../services/apiconnector";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";
import PasswordStrengthChecker from "../../utils/PasswordStrengthChecker";
import PasswordMatcher from "../../utils/PasswordMatcher";

export const ChangePassword = () => {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [newPasswordStrength, setNewPasswordStrength] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const getColorClassForStrength = (strength) => {
    if (strength === "Weak Password") {
      return "outline-none border-red-500 focus:border-red-700 focus:ring-red-700";
    } else if (strength === "Okay Password" || strength === "Strong Password") {
      return "outline-none border-green-500 focus:border-green-700 focus:ring-green-700";
    } else {
      return "";
    }
  };

  const getColorClassForPasswordsMatch = (formData) => {
    if (formData.newPassword === formData.confirmNewPassword) {
      return "outline-none border-green-500 focus:border-green-700 focus:ring-green-700";
    } else {
      return "outline-none border-red-500 focus:border-red-700 focus:ring-red-700";
    }
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmNewPasswordVisibility = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const toastId = toast.loading(
      "Almost there! Setting up your new password..."
    ); // Store toast ID

    try {
      // New Password validation

      if (formData.oldPassword === formData.newPassword) {
        toast.dismiss(toastId);
        toast.error("ERROR: Old and new passwords must be different.");
        return;
      }

      if (formData.strength === "Weak Password") {
        toast.dismiss(toastId);
        toast.error("Password is too weak. Please choose a stronger password.");
        return;
      }

      if (formData.newPassword === formData.confirmNewPassword) {
        await apiConnector(
          "POST",
          `https://mirror-express-server.onrender.com/api/v1/changepassword`,
          // "https://api.atmirrors.com//auth/change-password"
          // "http://localhost:8000/api/v1/changepassword",
          formData,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        )
          .then((res) => {
            toast.dismiss(toastId);
            toast.success("Your password has been successfully changed.");
            console.log(res);
            navigate("/login");
          })
          .catch((error) => {
            toast.dismiss(toastId);
            toast.error(error.response.data.message);
          });
      } else {
        toast.dismiss(toastId);
        toast.error("Password mismatch detected. Please verify and try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewPasswordChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen gap-4">
      <div className=" flex items-center flex-col justify-center w-[325px]">
        <div className="flex flex-col gap-2">
          <h2 className="text-gray-900 text-center text-4xl font-semibold">
            Mirror
          </h2>
          <span className="font-medium text-lg text-gray-500  ">
            Change Password
          </span>
        </div>

        <div className="mt-8">
          <form
            className="flex flex-col items-center gap-[20px]"
            onSubmit={submitHandler}
          >
            <div className="relative">
              <input
                required
                onChange={changeHandler}
                value={formData.oldPassword}
                placeholder=""
                name="oldPassword"
                type={showOldPassword ? "text" : "password"}
                className="p-3 text-sm w-[325px] h-[59px] border rounded-md "
              />
              <label
                className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                  formData.oldPassword &&
                  "text-xs text-gray-700 -translate-y-9 "
                }`}
                style={{
                  backgroundColor: formData.oldPassword
                    ? "#fff"
                    : "transparent",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                Enter old password
              </label>
              <span
                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={toggleOldPasswordVisibility}
              >
                {showOldPassword ? (
                  <VscEye size={25} />
                ) : (
                  <VscEyeClosed size={25} />
                )}
              </span>
            </div>
            <div className="relative">
              <input
                required
                onChange={handleNewPasswordChange}
                value={formData.newPassword}
                placeholder=""
                name="newPassword"
                type={showNewPassword ? "text" : "password"}
                className={`p-3 text-sm w-[325px] h-[58px] border rounded-md ${
                  formData.newPassword && "not-empty"
                } ${
                  newPasswordStrength &&
                  getColorClassForStrength(newPasswordStrength)
                }
                `}
              />
              <label
                className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                  formData.newPassword &&
                  "text-xs text-gray-700 -translate-y-9 "
                }`}
                style={{
                  backgroundColor: formData.newPassword
                    ? "#fff"
                    : "transparent",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                Enter new password
              </label>
              <span
                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={toggleNewPasswordVisibility}
              >
                {showNewPassword ? (
                  <VscEye size={25} />
                ) : (
                  <VscEyeClosed size={25} />
                )}
              </span>
            </div>
            {formData.newPassword && (
              <Suspense fallback={<div>Loading password strength...</div>}>
                <PasswordStrengthChecker
                  password={formData.newPassword}
                  setNewPasswordStrength={setNewPasswordStrength}
                ></PasswordStrengthChecker>
              </Suspense>
            )}
            <div className="relative">
              <input
                required
                onChange={changeHandler}
                value={formData.confirmNewPassword}
                placeholder=""
                name="confirmNewPassword"
                type={showConfirmNewPassword ? "text" : "password"}
                className={`p-3 text-sm w-[325px] h-[59px] border rounded-md ${
                  formData.newPassword &&
                  formData.confirmNewPassword &&
                  getColorClassForPasswordsMatch(formData)
                }`}
              />
              <label
                className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                  formData.confirmNewPassword &&
                  "text-xs text-gray-700 -translate-y-9 "
                }`}
                style={{
                  backgroundColor: formData.confirmNewPassword
                    ? "#fff"
                    : "transparent",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                Enter confirm new password
              </label>
              <span
                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={toggleConfirmNewPasswordVisibility}
              >
                {showConfirmNewPassword ? (
                  <VscEye size={25} />
                ) : (
                  <VscEyeClosed size={25} />
                )}
              </span>
            </div>

            <div>
              {formData.confirmNewPassword && (
                <PasswordMatcher
                  newPassword={formData.newPassword}
                  confirmNewPassword={formData.confirmNewPassword}
                ></PasswordMatcher>
              )}
            </div>
            {/* submit button */}
            <div className="mt-2">
              <button className="bg-black w-[325px] h-[58px] text-white rounded-md">
                <span>Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
