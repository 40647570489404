import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { apiConnector } from "../../../services/apiconnector";
import AsyncSelect from "react-select/async";
import { IoSearch, IoShareOutline } from "react-icons/io5";
import altImage from "../../../Images/profile-picture-3.jpg";
import { AnonymityConfirmationModal } from "../../../Components/NewPost/AnonymityConfirmationModal";
import { AnonymityRequestModal } from "../../../Components/NewPost/AnonymityRequestModal";
import { CustomStars } from "../../../Components/NewPost/CustomStars";
import { ResponseMessages } from "../../../Components/NewPost/responseMessages";
import Popup from "reactjs-popup";
import { FiX } from "react-icons/fi";
import { AnonymousStatus } from "../../../Components/Modals/AnonymousStatus";
import {
  setCreatePostData,
  setSavedCreatePostData,
} from "../../../slices/createPostSlice";

export const NewPost = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  // const [isAnonymous, setIsAnonymous] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [bodyCharCount, setBodyCharCount] = useState(0);
  const [showAnonymityConfirmationModal, setShowAnonymityConfirmationModal] =
    useState(false);
  const [showAnonymityRquestModal, setShowAnonymityRquestModal] =
    useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const userName = searchParams.get("user");
  const userDisplayName = searchParams.get("name");
  const userDisplayImage = searchParams.get("img");

  const dispatch = useDispatch();
  const savedCreatePostData = useSelector(
    (state) => state.createPost.savedPostData
  );
  // console.log("savedPostData", savedCreatePostData);

  const [formData, setFormData] = useState({
    reviewedUserName: userName || savedCreatePostData?.reviewedUserName || "",
    title: savedCreatePostData?.title || "",
    body: savedCreatePostData?.body || "",
    taggedUserRating: savedCreatePostData?.taggedUserRating || 0,
    isAnonymous: false,
  });

  const fetchUserData = async (inputValue) => {
    try {
      const response = await apiConnector(
        "POST",
        "https://api.atmirrors.com/search/users",
        {
          query: inputValue,
          limit: 10,
          page: 1,
        }
      );

      console.log(response);

      const users = response?.data?.data.items || [];

      return users.map((user) => ({
        value: user.username,
        label:
          (
            <div className="flex flex-col  ">
              {" "}
              <p className="font-semibold" title="fullName">
                {user.full_name}
              </p>{" "}
              <p className=" text-xs" title="username">
                {user.username}
              </p>{" "}
            </div>
          ) ||
          user.username ||
          "Unknown User",
        image: user.user_image || altImage,
      }));
    } catch (error) {
      console.log("error", error);
      return [];
    }
  };

  // const handleAnonymityToggle = async () => {
  //   try {
  //     if (!isAnonymous) {
  //       // Perform API call only if toggling to anonymous
  //       const response = await axios.get(
  //         "http://localhost:4001/api/v2/verifyAnonymity/103",
  //         { headers: { Authorization: `Bearer ${token}` } }
  //       );
  //       const { message } = response.data;

  //       switch (message) {
  //         case ResponseMessages.ALLOWED:
  //           setShowAnonymityConfirmationModal(true);
  //           setIsAnonymous(true);
  //           break;
  //         case ResponseMessages.REQUESTED:
  //           setShowAnonymityRquestModal(true);
  //           break;
  //         case ResponseMessages.PENDING:
  //         case ResponseMessages.PENDING_ANONYMITY:
  //           navigate(`/anonymous-verification/103`);
  //           break;
  //         case ResponseMessages.NOT_VERIFIED:
  //           setShowAnonymityConfirmationModal(true);
  //           setModalMessage(
  //             "Your profile needs to be verified first. Redirecting to verify your profile."
  //           );
  //           navigate(`/user-verification/103`);
  //           break;
  //         default:
  //           toast.error("Failed to verify anonymity status.");
  //           break;
  //       }
  //     } else {
  //       setIsAnonymous(false);
  //     }
  //   } catch (error) {
  //     console.error("Error verifying anonymity:", error);
  //     toast.error("Failed to verify anonymity status.");
  //   }
  // };

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => {
      if (name === "title") {
        setCharCount(value.length);
      }
      if (name === "body") {
        setBodyCharCount(value.length);
      }
      dispatch(setSavedCreatePostData(formData));
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });

    // if (name === "isAnonymous") {
    //   setIsAnonymous(checked);
    // }
  };

  const userChangeHandler = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      reviewedUserName: selectedOption,
    }));

    dispatch(
      setSavedCreatePostData({
        ...formData,
        reviewedUserName: selectedOption,
      })
    );
  };

  const ratingChanged = (newRating) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      taggedUserRating: newRating,
    }));
    dispatch(setSavedCreatePostData(formData));
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const workEmailVerificationStatusCheck = async () => {
    try {
      const res = await apiConnector(
        "GET",
        "https://api.atmirrors.com/email-verification/check"
      );

      console.log(res);

      if (res.data.verified === false) {
        setFormData({ ...formData, isAnonymous: false });
        setIsPopupOpen(true);
      } else {
        if (formData.isAnonymous === true) {
          toast.success("User successfully Switched to Public Mode");
        } else {
          toast.success("User successfully switched to anonymous mode");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to verify work email");
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const payload = {
      reviewedUserName: formData.reviewedUserName,
      title: formData.title,
      body: formData.body,
      reviewedUserRating: formData.taggedUserRating,
      isAnonymous: formData.isAnonymous,
    };

    const toastId = toast.loading("Creating New Review...");

    if (!formData.reviewedUserName) {
      toast.dismiss(toastId);
      return toast.error("Please select a valid User");
    }

    try {
      const response = await apiConnector(
        "POST",
        "https://api.atmirrors.com/posts/create",
        payload,
        { "Content-Type": "application/json" }
      );

      console.log("Post Created Successfully", response);
      toast.dismiss(toastId);
      toast.success("Review Posted successfully");
      navigate("/dashboard");
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("Failed to post review");
      console.error("Error posting review:", error);
    }

    console.log("payload", payload);
    dispatch(setSavedCreatePostData(null));
  };

  return (
    <div className="pt-[5.5rem] pb-0 p-2 mb-[7rem] flex items-center justify-center ">
      <div className="flex flex-col gap-3 items-center justify-center w-[365px]">
        <div className="text-3xl font-semibold text-left w-full pl-5">
          Add your review
        </div>

        <div className="mt-3">
          <form
            className="flex flex-col items-center gap-[20px] w-full"
            onSubmit={submitHandler}
          >
            <AsyncSelect
              cacheOptions
              loadOptions={fetchUserData}
              onChange={userChangeHandler}
              defaultValue={
                userName
                  ? {
                      value: userName,
                      label: userDisplayName,
                      image: userDisplayImage,
                    }
                  : savedCreatePostData
                  ? {
                      value: savedCreatePostData?.reviewedUserName.value,
                      label: savedCreatePostData?.reviewedUserName.label,
                      image: savedCreatePostData?.reviewedUserName.image,
                    }
                  : null
              }
              placeholder={
                <div className="flex items-center justify-start gap-3">
                  <IoSearch size={18} />
                  <div className="lg:pt-[2px]">Select User</div>
                </div>
              }
              name="reviewedUserName"
              className="w-full text-left"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              formatOptionLabel={(option) => (
                <div className="flex items-center gap-2">
                  <img
                    src={option.image}
                    alt="User"
                    className="w-7 h-7 rounded-full"
                  />
                  <span>{option.label}</span>
                </div>
              )}
            />

            <label
              htmlFor="title"
              className="w-full text-left font-medium mb-[-15px] pl-1"
            >
              Title
            </label>
            <div className="relative w-full">
              <input
                required
                onChange={changeHandler}
                value={formData.title}
                placeholder="Write your review title here..."
                name="title"
                id="title"
                type="text"
                maxLength="80"
                className="bg-richblack-800 text-richblack-5 p-3 w-full h-[58px] text-sm border rounded-md"
              />
              <div className="absolute bottom-2 right-2 text-xs text-gray-400">
                {charCount}/80
              </div>
            </div>
            <label
              htmlFor="body"
              className="w-full text-left font-medium mb-[-15px] pl-1"
            >
              Write your review
            </label>
            <div className="relative w-full">
              <textarea
                required
                onChange={changeHandler}
                value={formData.body}
                placeholder="Write your review here.. "
                name="body"
                type="text"
                id="body"
                maxLength="2000"
                className="bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[80px] text-sm border rounded-md"
              />
              <div className="absolute bottom-2 right-2 text-xs text-gray-400">
                {bodyCharCount}/2000
              </div>
            </div>

            <Popup
              open={isPopupOpen}
              closeOnEscape
              closeOnDocumentClick
              onClose={() => setIsPopupOpen(false)}
              modal
              contentStyle={{
                width: "0px",
                height: "auto",
                maxHeight: "80vh",
                overflowY: "auto",
                font: "inherit",
              }}
            >
              <div>
                <button
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                  }}
                  onClick={() => setIsPopupOpen(false)}
                >
                  <FiX />
                </button>
                <AnonymousStatus setIsPopupOpen={setIsPopupOpen} />
              </div>
            </Popup>

            <label class="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="isAnonymous"
                checked={formData.isAnonymous}
                onChange={changeHandler}
                onClick={workEmailVerificationStatusCheck}
                className="sr-only peer"
              />
              <span class="ms-3 text-md font-medium text-gray-900 mr-3">
                Post Anonymously
              </span>
              <div class="relative w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-800"></div>
            </label>

            <label
              htmlFor="Rating"
              className="w-full text-left font-medium mb-[-15px] pl-1"
            >
              Mirror Score
            </label>

            <div
              id="Rating"
              className="flex justify-center items-center gap-[5rem] p-3 h-[69px] border rounded-md w-full"
            >
              <div className="font-semibold text-3xl text-blue-600">
                {formData.taggedUserRating}
              </div>
              {/* <ReactStars
                count={5}
                onChange={ratingChanged}
                name="taggedUserRating"
                size={30}
                char={(index) =>
                  index <= formData.taggedUserRating - 1 ? (
                    <FaStar />
                  ) : (
                    <CiStar />
                  )
                }
                activeColor="#000000"
              /> */}
              <CustomStars
                count={5}
                value={formData.taggedUserRating}
                onChange={ratingChanged}
                starSize={22}
              />
            </div>

            <div className="mt-5">
              <button className="bg-black w-[325px] h-[58px] text-white rounded-md">
                <span>Post Review</span>
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* {showAnonymityConfirmationModal && (
        <AnonymityConfirmationModal
          message={modalMessage}
          onConfirm={() => setShowAnonymityConfirmationModal(false)}
          onCancel={() => {
            setShowAnonymityConfirmationModal(false);
            setIsAnonymous(false);
          }}
        />
      )}

      {showAnonymityRquestModal && (
        <AnonymityRequestModal
          onRequest={() => {
            setShowAnonymityRquestModal(false);
            navigate(`/anonymous-verification/103`);
          }}
          onCancel={() => setShowAnonymityRquestModal(false)}
        />
      )} */}
    </div>
  );
};
