import { useState, useEffect } from "react";

const Geolocation = ({ onLocationChange }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            console.log("Latitude:", latitude);
            console.log("Longitude:", longitude);

            try {
              const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`
              );
              const data = await response.json();
              console.log("The current address is", data);
              const { results } = data;
              if (results && results.length > 0) {
                const { formatted_address } = results[0];
                console.log("Formatted Address:", formatted_address);
                onLocationChange(formatted_address);
              }
            } catch (error) {
              console.error("Error fetching location details:", error);
            } finally {
              setLoading(false);
            }
          },
          (error) => {
            console.error("Error getting location:", error.message);
            setLoading(false);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        setLoading(false);
      }
    };

    getLocation();
  }, []);

  return null;
};

export default Geolocation;
