import React, { useState } from "react";
import { apiConnector } from "../../services/apiconnector";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export const EditComment = ({
  id,
  body,
  fetchUserData,
  closeModal,
  closeDropdown,
}) => {
  const [formData, setFormData] = useState({
    body: body,
  });

  const { token } = useSelector((state) => state.auth);

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  // console.log("token in storage", localStorage.getItem("token"));
  // console.log("User in storage", localStorage.getItem("user"));

  const submitHandler = async (event) => {
    event.preventDefault();
    console.log(formData);

    const toastId = toast.loading("Editing Your Comment..."); // Store toast ID

    try {
      await apiConnector(
        "PUT",
        `https://mirror-express-server.onrender.com/api/v1//posts/comments/${id}`,
        // "http://localhost:8000/api/v1/updateProfile",
        formData,
        { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
      )
        .then(async (res) => {
          toast.dismiss(toastId);
          toast.success("Comment Edited successfully");
          console.log("Comment Edited successfully. Data received:", res);
          setFormData({
            body: "",
          });

          closeModal();
          closeDropdown();
          await fetchUserData();
        })
        .catch((error) => {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="">
      <div className="flex flex-col justify-center items-center  ">
        <div className="text-center m-5 font-medium text-lg">Edit Comment</div>

        <form
          className="flex flex-col items-center gap-[20px] pb-5 "
          onSubmit={submitHandler}
        >
          <textarea
            onChange={changeHandler}
            value={formData.body}
            placeholder={body}
            name="body"
            type="text"
            className="p-3 text-sm w-[325px] h-[80px] lg:h-[120px] border rounded-md "
          />

          <div className="mt-5">
            <button className="bg-black w-[325px] h-[58px] text-white rounded-md">
              <span>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
