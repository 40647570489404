import React, { useState } from "react";
import { Link } from "react-router-dom";
import AltImage from "../../Images/profile-picture-3.jpg";
import Popup from "reactjs-popup";
import { EditComment } from "./EditComment";
import { useSelector } from "react-redux";

export const CommentCard = ({ user, createdAt, body, id, fetchUserData }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const closeModal = () => {};

  const ISTCreatedAt = new Date(createdAt).toLocaleDateString("en-IN", {
    timeZone: "Asia/Kolkata",
  });

  //   const ISTCreatedAt = new Date(createdAt).toLocaleString("en-IN", {
  //     timeZone: "Asia/Kolkata",
  //   });

  const profile = useSelector((state) => state.profile);
  // console.log(profile.user._id);
  // console.log(user._id);

  const canEditAndRemove = user._id === profile.user._id;

  return (
    <div className="text-left max-w-3xl mx-auto relative">
      <div className="p-6 mb-3 text-base bg-white border-t border-gray-200">
        <footer className="flex justify-between items-center mb-2">
          <div className="flex items-center">
            <p className="inline-flex items-center mr-3 text-sm text-gray-900 font-semibold">
              <img
                className="mr-2 w-6 h-6 rounded-full"
                src={user.image}
                alt={user.fullName}
              />
              {user.fullName}
            </p>
            <p className="text-sm text-gray-600">
              <time pubdate dateTime={createdAt}>
                {ISTCreatedAt}
              </time>
            </p>
          </div>
          <div className="relative">
            <button
              onClick={toggleDropdown}
              className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
              type="button"
              aria-expanded={dropdownVisible}
              aria-controls="dropdownComment"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 3"
              >
                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
              </svg>
              <span className="sr-only">Comment settings</span>
            </button>
            {dropdownVisible && (
              <div
                id="dropdownComment"
                className="absolute right-0 mt-2 z-10 w-36 bg-white rounded divide-y divide-gray-100 shadow"
              >
                <ul
                  className="py-1 text-sm text-gray-700"
                  aria-labelledby="dropdownMenuIconHorizontalButton"
                >
                  {canEditAndRemove && (
                    <li>
                      <div className="block py-2 px-4 hover:bg-gray-100">
                        <Popup
                          trigger={<button>Edit</button>}
                          modal
                          contentStyle={{
                            width: "365px",
                            height: "auto",
                            maxHeight: "80vh",
                            overflowY: "auto",
                          }}
                          // position="left center"
                        >
                          <EditComment
                            id={id}
                            body={body}
                            fetchUserData={fetchUserData}
                            closeModal={closeModal}
                            closeDropdown={closeDropdown}
                          />
                        </Popup>
                      </div>
                    </li>
                  )}
                  {canEditAndRemove && (
                    <li>
                      <Link
                        to="#"
                        className="block py-2 px-4 hover:bg-gray-100"
                        onClick={closeDropdown}
                      >
                        Remove
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      to="#"
                      className="block py-2 px-4 hover:bg-gray-100"
                      onClick={closeDropdown}
                    >
                      Report
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </footer>
        <p className="text-gray-500">{body}</p>
        <div className="flex items-center mt-4 space-x-4">
          <button
            type="button"
            className="flex items-center text-sm text-gray-500 hover:underline font-medium"
          >
            <svg
              className="mr-1.5 w-3.5 h-3.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 18"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
              />
            </svg>
            Reply
          </button>
        </div>
      </div>
    </div>
  );
};
