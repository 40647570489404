import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  setNewNotificationState,
} from "../../slices/notificationSlice";
import { formattedDate } from "../../utils/dateFormatter";
import { NotificationCard } from "../../Components/Notifications/NotificationCard";

export const Notifications = () => {
  const { newNotificationState, notificationData } = useSelector(
    (state) => state.notification
  );
  const dispatch = useDispatch();

  dispatch(setNewNotificationState(false));

  console.log("notifications", newNotificationState);
  console.log("notifications", notificationData);
  return (
    <div className="flex items-center justify-center h-[100%] ">
      <div className="flex flex-col pt-[4.5rem] pb-0 p-2 mb-[7rem] w-[365px] gap-1">
        {/* <h1 className="text-start mt-4 mb-1 ml-2 font-semibold text-xl">
          Notifications
        </h1> */}
        <div className="flex flex-col-reverse ">
          {notificationData && notificationData.length > 0 ? (
            notificationData.map((notification, index) => (
              <NotificationCard
                key={index}
                title={notification.notification.title}
                body={notification.notification.body}
                image={notification.notification.image}
                timestamp={notification.timestamp}
                messageId={notification.messageId}
                isNotificationOpened={notification.isNotificationOpened}
              />
            ))
          ) : (
            <div className="flex justify-center items-center h-[35rem] ">
              <div className="pb-5">No notifications available</div>
            </div>
          )}
        </div>

        {notificationData && notificationData.length > 0 && (
          <button
            className="mt-5 mb-5 ml-3 rounded-md mr-3 font-normal bg-black text-white p-1"
            onClick={() => dispatch(clearNotifications())}
          >
            Clear Notifications
          </button>
        )}
      </div>
    </div>
  );
};
