import React from "react";

const TermsModal = ({ isOpen, onClose, title, sections }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div
        className="bg-white px-4 md:px-4 rounded-lg max-w-3xl overflow-y-auto overflow-x-auto relative"
        style={{ maxHeight: "76vh", width: "90%", maxWidth: "800px" }}
      >
        <div className="flex justify-between mb-4 sticky top-0 bg-white z-10 py-3">
          <h3 className="text-xl md:text-3xl item-center font-semibold">
            {title}
          </h3>
          <button
            onClick={onClose}
            className="bg-gray-200 hover:bg-black text-black hover:text-white py-1 px-2 md:py-1 md:px-3 rounded-lg shadow-md transition duration-300"
          >
            Close
          </button>
        </div>
        {sections.map((section, index) => (
          <div key={index} className="mb-4">
            <h4 className="text-md md:text-xl text-left font-semibold mb-2">
              {section.subheading}
            </h4>
            <p className="text-custom-sm md:text-base text-black text-left">
              {section.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsModal;
