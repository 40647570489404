import React, { useState, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import Popup from "reactjs-popup";
import UpvoteIcon from "../../../Images/Icons/Upvote.svg";
import DownvoteIcon from "../../../Images/Icons/Downvote.svg";
import CommentIcon from "../../../Images/Icons/Comment.svg";
import ShareIcon from "../../../Images/Icons/Share.svg";
import { Link, useNavigate } from "react-router-dom";
import { setPost } from "../../../slices/postSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiConnector } from "../../../services/apiconnector";
import { BsIncognito } from "react-icons/bs";
import { BiEdit, BiUpvote } from "react-icons/bi";
import { BiDownvote } from "react-icons/bi";
import { BiSolidUpvote } from "react-icons/bi";
import { MdOutlineSaveAlt } from "react-icons/md";
import { BiSolidDownvote } from "react-icons/bi";
import { VscLiveShare } from "react-icons/vsc";
import { PiShare } from "react-icons/pi";
import { ReportPostCard } from "./ReportPostCard";
import { IoCloseCircleOutline } from "react-icons/io5";
import { EditPost } from "./EditPost";
import { SharePost } from "./SharePost";
import { PiShareFatLight } from "react-icons/pi";
import { formattedDate } from "../../../utils/dateFormatter";
import { HiTrendingUp } from "react-icons/hi";
import reflectIcon from "../../../Images/Icons/reflect.svg";
import { ReflectModal } from "./ReflectModal";
import { CustomStars } from "../../NewPost/CustomStars";
import { ReportButton } from "./PostCard/ReportButton";
import toast from "react-hot-toast";
import { refreshAccessToken } from "../../../slices/authSlice";
import { FaRegComment } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { DeletePost } from "./PostCard/DeletePost";

export const PostCard = ({
  Name,
  CompanyName,
  image,
  postId,
  body,
  authorName,
  upvoteCount,
  downVoteCount,
  isAnonymous,
  authorUsername,
  reviewedUserUsername,
  fetchPostData,
  createdAt,
  updatedAt,
  postTitle,
  authorImage,
  reviewedUserRating,
  currentCompany,
  currentRole,
  voteType,
  hasUserVoted,
  authorUserName,
  reviewedUserUserName,
  seletedSection,
  onRemove,
  removedPost,
}) => {
  const postData = {
    Name,
    CompanyName,
    image,
    postId,
    body,
    authorName,
    upvoteCount,
    downVoteCount,
    isAnonymous,
    authorUsername,
    authorImage,
    reviewedUserUsername,
    fetchPostData,
    createdAt,
    updatedAt,
    postTitle,
    reviewedUserRating,
    currentCompany,
    currentRole,
    voteType,
    hasUserVoted,
    authorUserName,
    reviewedUserUserName,
    seletedSection,
    onRemove,
    removedPost,
  };

  // console.log(`Upvote for ${postId}`, upvoteCount);
  // console.log(`DownVote for ${postId}`, downVoteCount);

  const [showFullBody, setShowFullBody] = useState(false);
  const [upvotes, setUpVotes] = useState(upvoteCount);
  const [downVotes, setDownVotes] = useState(downVoteCount);
  const [voteStatus, setVoteStatus] = useState(null);
  const [saveStatus, setSaveStatus] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  // console.log("Profile in PostCard", profile.user._id);

  const MAX_WORDS = 30;
  const words = body.split(" ");
  const truncatedBody = words.slice(0, MAX_WORDS).join(" ");
  const isTruncated = words.length > MAX_WORDS;

  useEffect(() => {
    const userId = profile.user.username;
    // const userUpvoted = upvoteCount.some((vote) => vote.user === userId);
    // const userDownvoted = downVoteCount.some((vote) => vote.user === userId);
    const userUpvoted = hasUserVoted && voteType === "upvote";
    const userDownvoted = hasUserVoted && voteType === "downvote";

    if (userUpvoted) {
      setVoteStatus("upvoted");
    } else if (userDownvoted) {
      setVoteStatus("downvoted");
    } else {
      setVoteStatus(null);
    }
  }, [upvoteCount, downVoteCount, hasUserVoted, voteType, seletedSection]);

  const toggleBody = () => {
    setShowFullBody(!showFullBody);
  };

  const upvote = async () => {
    if (voteStatus === "upvoted") {
      // User wants to remove their upvote
      try {
        const response = await apiConnector(
          "POST",
          "https://api.atmirrors.com/vote",
          { votableId: postId, voteType: "upvote", votableType: "post" }
        );

        setUpVotes(upvotes - 1);
        setVoteStatus(null);
        console.log(response);
      } catch (error) {
        console.error("Error removing upvote:", error);
      }
    } else {
      // User wants to upvote
      try {
        const response = await apiConnector(
          "POST",
          "https://api.atmirrors.com/vote",
          { votableId: postId, voteType: "upvote", votableType: "post" }
        );

        setUpVotes(upvotes + 1);
        if (voteStatus === "downvoted") {
          setDownVotes(downVotes - 1);
        }
        setVoteStatus("upvoted");
        console.log(response);
      } catch (error) {
        console.error("Error upvoting post:", error);
      }
    }
  };

  const downVote = async () => {
    if (voteStatus === "downvoted") {
      // User wants to remove their downvote
      try {
        const response = await apiConnector(
          "POST",
          "https://api.atmirrors.com/vote",
          { votableId: postId, voteType: "downvote", votableType: "post" }
        );
        setDownVotes(downVotes - 1);
        setVoteStatus(null);
        console.log(response);
      } catch (error) {
        console.error("Error removing downvote:", error);
      }
    } else {
      // User wants to downvote
      try {
        const response = await apiConnector(
          "POST",
          "https://api.atmirrors.com/vote",
          { votableId: postId, voteType: "downvote", votableType: "post" }
        );

        setDownVotes(downVotes + 1);
        if (voteStatus === "upvoted") {
          setUpVotes(upvotes - 1);
        }
        setVoteStatus("downvoted");
        console.log(response);
      } catch (error) {
        console.error("Error downvoting post:", error);
      }
    }
  };

  const savePost = async () => {
    // if (saveStatus) {
    //   toast.info("You have already saved this post");
    //   return;
    // }
    // try {
    //   const response = await apiConnector(
    //     "POST",
    //     "https://mirror-express-server.onrender.com/api/v1/post/savePost",
    //     { post: postId },
    //     { Authorization: `Bearer ${token}` }
    //   );
    //   setSaveStatus(true);
    //   toast.success("Post saved successfully");
    // } catch (error) {
    //   console.error("Error saving post:", error);
    //   toast.error("Failed to save the post");
    // }
  };
  const PinReview = async () => {
    const toastId = toast.loading("Pinning your review to the profile...");
    try {
      const response = await apiConnector(
        "POST",
        `https://backend-2-0-production.onrender.com/users/pin-review/${postId}`,
        { post: postId }
      );
      setSaveStatus(true);
      toast.dismiss(toastId);
      toast.success("Review successfully pinned to the profile!");
      console.log("Review successfully pinned to the profile!", response);
    } catch (error) {
      console.error(
        "Oops! Something went wrong while pinning the review:",
        error
      );
      toast.dismiss(toastId);
      toast.error("Couldn't pin the review to the profile. Please try again.");
    }
  };

  const commentButtonHandler = () => {
    navigate(`/post/${postId}`);
    dispatch(setPost(postData));
    localStorage.setItem("post", JSON.stringify(postData));
  };

  const taggedUserButtonHandler = () => {
    navigate(`/user-profile/${reviewedUserUserName}`);
  };
  const authorButtonHandler = () => {
    navigate(`/user-profile/${authorUsername}`);
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const [openReport, setOpenReport] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openReflect, setOpenReflect] = useState(false);

  const closeModalEdit = () => setOpenEdit(false);
  const closeModalReport = () => setOpenReport(false);
  const closeModalShare = (open) => setOpenShare(!open);
  const closeModalReflect = (open) => setOpenShare(!open);

  // console.log(profile.user._id);
  // console.log(user._id);

  const canEditAndRemove = authorUserName === profile.user.username;
  const canSaveAndRemove = authorUsername !== profile.user.username;
  const canPinReview = reviewedUserUserName === profile.user.username;

  const [canEdit, setCanEdit] = useState(false);
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const updateTimeDifference = new Date(currentTime) - new Date(createdAt);

      const secondsDifference = Math.floor(updateTimeDifference / 1000);
      const minutesDifference = Math.floor(updateTimeDifference / (1000 * 60));
      const hoursDifference = Math.floor(
        updateTimeDifference / (1000 * 60 * 60)
      );
      const daysDifference = Math.floor(
        updateTimeDifference / (1000 * 60 * 60 * 24)
      );
      const weeksDifference = Math.floor(daysDifference / 7);

      setCanEdit(
        minutesDifference <= 15 && authorUserName === profile.user.username
      );

      const formatNumber = (number) => String(number).padStart(2, "0");

      if (weeksDifference >= 4) {
        setFormattedTime(formattedDate(createdAt));
      } else if (weeksDifference >= 1) {
        setFormattedTime(
          `${formatNumber(weeksDifference)} week${
            weeksDifference > 1 ? "s" : ""
          } ago`
        );
      } else if (daysDifference >= 1) {
        setFormattedTime(
          `${formatNumber(daysDifference)} day${
            daysDifference > 1 ? "s" : ""
          } ago`
        );
      } else if (hoursDifference >= 1) {
        setFormattedTime(
          `${formatNumber(hoursDifference)} hour${
            hoursDifference > 1 ? "s" : ""
          } ago`
        );
      } else if (minutesDifference >= 1) {
        setFormattedTime(
          `${formatNumber(minutesDifference)} minute${
            minutesDifference > 1 ? "s" : ""
          } ago`
        );
      } else {
        setFormattedTime(`a few seconds ago`);
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup
  }, [createdAt]);

  // console.log("createdAt", createdAt);
  // console.log("updatedAt", updatedAt);

  const [isAnimating, setIsAnimating] = useState(false);

  const handleCardClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
      commentButtonHandler();
    }, 100);
  };

  const [showReportPopup, setShowReportPopup] = useState(false);
  const [selectedReportOption, setSelectedReportOption] = useState([]);

  const toggleReportPopup = () => {
    setShowReportPopup(!showReportPopup);
  };

  const selectReportOption = (option) => {
    setSelectedReportOption((prevSelected) =>
      prevSelected.includes(option)
        ? prevSelected.filter((item) => item !== option)
        : [...prevSelected, option]
    );
  };

  const submitReport = async () => {
    onRemove();
    if (!selectedReportOption.length) {
      toast.error("Please select at least one reason to report the post.");
      return;
    }

    console.log(
      `Reporting post with ID ${postId} initiated with reasons:`,
      selectedReportOption
    );

    const delayPromise = new Promise((resolve, reject) => {
      const timeout = setTimeout(() => resolve(), 5000);

      const handleRemovedPostChange = () => {
        if (removedPost) {
          clearTimeout(timeout);
          reject(new Error("Report canceled due to post removal"));
        }
      };
      handleRemovedPostChange();
    });

    try {
      await delayPromise;

      const response = await apiConnector(
        "POST",
        `https://api.atmirrors.com/posts/report/${postId}`,
        {
          reasons: selectedReportOption,
        }
      );

      if (response.status === 200) {
        toast.success("Post reported successfully.");
      }

      setShowReportPopup(false);
      setSelectedReportOption([]);
      setOpenReport(false);
    } catch (error) {
      if (error.message === "Report canceled due to post removal") {
        toast.info("Report action canceled because the post was removed.");
      } else if (error.response?.status === 404) {
        toast.error("Post not found.");
      } else {
        toast.error(
          "An error occurred while reporting the post. Please try again later."
        );
      }
      console.error("Error reporting post:", error);
    }
  };

  return (
    <div>
      <div
        className={`max-w-md mx-auto border-b-2 overflow-hidden md:max-w-3xl mb-5 pt-3 pb-3 ${
          isAnimating ? "bg-gray-200" : ""
        } transition-colors duration-200`}
        onMouseDown={handleCardClick}
      >
        <div className="md:flex-col mb-4">
          <div className="md:shrink-0">
            <div className="flex justify-between">
              <div
                className="flex items-center justify-center gap-2"
                title={`@${reviewedUserUserName}`}
              >
                <img
                  className="w-[45px] h-[45px] rounded-full shadow-lg hover:border "
                  src={image}
                  alt="User Profile"
                  onClick={() => {
                    taggedUserButtonHandler();
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                />

                <div className="flex flex-col">
                  <div className="flex gap-2">
                    <h5
                      className="text-lg font-semibold text-left text-gray-900 hover:underline cursor-pointer"
                      onClick={taggedUserButtonHandler}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      {Name}
                    </h5>

                    {/* <span className=" text-xs  mt-2">
                      @{reviewedUserUserName}
                    </span> */}
                  </div>

                  {currentCompany && currentRole && (
                    <span className="text-sm text-left text-gray-500 dark:text-gray-400">
                      {/* Software Engineer @ {CompanyName} */}
                      {currentRole} @{currentCompany}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex justify-center items-start  gap-2">
                <div className="relative">
                  <button
                    onClick={() => {
                      toggleDropdown();
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500  rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                    type="button"
                    aria-expanded={dropdownVisible}
                    aria-controls="dropdownComment"
                  >
                    <BsThreeDotsVertical color="black" size={15} />
                    <span className="sr-only">Comment settings</span>
                  </button>
                  {dropdownVisible && (
                    <div
                      id="dropdownComment"
                      className="absolute right-0 mt-2 z-10 w-28 bg-white rounded divide-y divide-gray-100 shadow"
                    >
                      <ul
                        className="py-1 text-sm text-gray-700 text-left"
                        aria-labelledby="dropdownMenuIconHorizontalButton"
                      >
                        {canEdit && (
                          <li>
                            <div
                              className="block py-1 px-3 hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                setOpenEdit(true);
                              }}
                              onMouseDown={(e) => e.stopPropagation()}
                            >
                              <button>Edit</button>
                              <Popup
                                modal
                                open={openEdit}
                                closeOnEscape
                                closeOnDocumentClick
                                onClose={closeModalEdit}
                                contentStyle={{
                                  width: "365px",
                                  height: "auto",
                                  maxHeight: "80vh",
                                  overflowY: "auto",
                                }}
                              >
                                {(close) => (
                                  <div>
                                    <div className="flex justify-end ">
                                      <button type="button">
                                        <IoCloseCircleOutline
                                          type="button"
                                          onClick={close}
                                          size={25}
                                        />
                                      </button>
                                    </div>

                                    <EditPost
                                      id={postId}
                                      fetchPostData={fetchPostData}
                                      title={postTitle}
                                      reviewedUserRating={reviewedUserRating}
                                      body={body}
                                      closeModal={closeModalEdit}
                                    />
                                  </div>
                                )}
                              </Popup>
                            </div>
                          </li>
                        )}

                        {canPinReview && (
                          <li>
                            <div className="block py-1 px-3 hover:bg-gray-100">
                              <button
                                onClick={PinReview}
                                onMouseDown={(e) => e.stopPropagation()}
                              >
                                Pin
                              </button>
                              {/* <button onClick={savePost}>Save</button> */}
                            </div>
                          </li>
                        )}

                        <li>
                          <div
                            className="block py-1 px-3 hover:bg-gray-100"
                            onMouseDown={(e) => e.stopPropagation()}
                          >
                            <Popup
                              trigger={<button>Share</button>}
                              modal
                              open={openShare}
                              closeOnEscape
                              closeOnDocumentClick
                              contentStyle={{
                                width: "300px",
                                height: "auto",
                                maxHeight: "80vh",
                                overflowY: "auto",
                              }}
                            >
                              {(close) => {
                                return (
                                  <div>
                                    <div className="flex justify-end ">
                                      <button type="button">
                                        <IoCloseCircleOutline
                                          type="button"
                                          onClick={close}
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                          size={25}
                                        />
                                      </button>
                                    </div>

                                    <SharePost postId={postId} />
                                  </div>
                                );
                              }}
                            </Popup>
                          </div>
                        </li>

                        {canEditAndRemove && (
                          <li>
                            <div
                              className="block py-1 px-3 hover:bg-gray-100 cursor-pointer"
                              onMouseDown={(e) => e.stopPropagation()}
                            >
                              <Popup
                                modal
                                closeOnEscape
                                closeOnDocumentClick
                                trigger={<button>Delete</button>}
                                contentStyle={{
                                  width: "0px",
                                  height: "auto",
                                  maxHeight: "80vh",
                                  overflowY: "auto",
                                }}
                              >
                                {(close) => (
                                  <div>
                                    <div className="flex justify-end pr-2 pt-2">
                                      <button type="button">
                                        <IoMdClose
                                          type="button"
                                          onClick={close}
                                          className="text-gray-500 hover:text-gray-700 hover:bg-gray-100 "
                                          size={18}
                                        />
                                      </button>
                                    </div>

                                    <DeletePost
                                      id={postId}
                                      close={close}
                                      fetchPostData={fetchPostData}
                                    />
                                  </div>
                                )}
                              </Popup>
                            </div>
                          </li>
                        )}

                        {canSaveAndRemove && (
                          <li>
                            <div className="block py-1 px-3 hover:bg-gray-100">
                              <button
                                onClick={savePost}
                                onMouseDown={(e) => e.stopPropagation()}
                              >
                                Save
                              </button>
                              {/* <button onClick={savePost}>Save</button> */}
                            </div>
                          </li>
                        )}

                        <li>
                          <div
                            className="block py-1 px-3 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              setOpenReport(true);
                            }}
                            onMouseDown={(e) => e.stopPropagation()}
                          >
                            <Popup
                              trigger={<button>Report</button>}
                              modal
                              open={openReport}
                              closeOnDocumentClick
                              onClose={closeModalReport}
                              contentStyle={{
                                width: "300px",
                                height: "auto",
                                maxHeight: "80vh",
                                overflowY: "auto",
                              }}
                            >
                              {(close) => (
                                <div>
                                  <div className="flex justify-end">
                                    <button type="button">
                                      <IoCloseCircleOutline
                                        type="button"
                                        onClick={close}
                                        size={25}
                                      />
                                    </button>
                                  </div>
                                  <div className="text-center">
                                    <div className="flex justify-between items-center">
                                      <h2 className="text-xl font-semibold text-center mb-6 mx-auto">
                                        Report this post
                                      </h2>
                                    </div>
                                    <div className="flex justify-center">
                                      <div className="font-semibold flex-col gap-2">
                                        <ReportButton
                                          option="spam_or_advertising"
                                          label="Spam or Advertising"
                                          selectedReportOption={
                                            selectedReportOption
                                          }
                                          selectReportOption={
                                            selectReportOption
                                          }
                                        />
                                        <ReportButton
                                          option="profanity"
                                          label="Profanity"
                                          selectedReportOption={
                                            selectedReportOption
                                          }
                                          selectReportOption={
                                            selectReportOption
                                          }
                                        />
                                        <ReportButton
                                          option="offensive_content"
                                          label="Offensive Content"
                                          selectedReportOption={
                                            selectedReportOption
                                          }
                                          selectReportOption={
                                            selectReportOption
                                          }
                                        />
                                      </div>
                                    </div>
                                    <button
                                      className="mt-4 px-4 py-2 bg-black text-white rounded-full"
                                      onClick={() => {
                                        close();
                                        submitReport();
                                      }}
                                    >
                                      Submit Report
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1 text-left mt-3">
            <div className=" ml-1">
              <div className="  flex justify-between gap-5 ">
                <h3
                  className="font-bold hover:underline hover:font-ultrabold cursor-pointer text-lg"
                  onClick={() => {
                    commentButtonHandler();
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  {postTitle}
                </h3>

                <div className="flex gap-1.5 items-center mr-5">
                  <span className="font-semibold text-lg text-blue-600">
                    {reviewedUserRating}.0
                  </span>

                  <div className="pb-0.5">
                    <CustomStars
                      count={1}
                      // count={5}
                      value={reviewedUserRating}
                      starSize={10}
                    />
                  </div>
                </div>
              </div>

              <p className="mt-1 text-black lg:text-base sm:text-normal font-normal leading-normal">
                {isTruncated && !showFullBody ? (
                  <>
                    {truncatedBody}...
                    <button
                      className="text-blue-500"
                      onClick={() => {
                        toggleBody();
                      }}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      See more
                    </button>
                  </>
                ) : (
                  <>
                    {body}
                    {showFullBody && (
                      <button
                        className="text-blue-500"
                        onClick={() => {
                          toggleBody();
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                      >
                        See less
                      </button>
                    )}
                  </>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-start ml-1 mb-1">
          <h3 className="text-sm">{formattedTime}</h3>
        </div>

        <div className="flex justify-between items-center h-[50px]  ">
          <div className="flex  items-center justify-center gap-3   ">
            <button
              className="flex gap-1"
              onClick={() => {
                upvote();
              }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <span className="pt-0.5">
                {voteStatus === "upvoted" ? (
                  <BiSolidUpvote size={18} color="#3468C0" />
                ) : (
                  <BiUpvote size={18} />
                )}
              </span>

              <span className="w-[15px]">{upvotes}</span>
            </button>

            <button
              onClick={() => {
                downVote();
              }}
              onMouseDown={(e) => e.stopPropagation()}
              className="flex gap-1"
            >
              <span className="pt-0.5">
                {voteStatus === "downvoted" ? (
                  <BiSolidDownvote color="#EA4335" size={18} />
                ) : (
                  <BiDownvote size={18} />
                )}
              </span>
              <span className="w-[15px]">{downVotes}</span>
            </button>

            {/* <Popup
              trigger={
                <button
                  className="mb-1 ml-1 flex items-center justify-center"
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  <img
                    className=" mt-1 w-[25px]"
                    src={reflectIcon}
                    alt="ReflectIcon"
                  />
                </button>
              }
              modal
              closeOnEscape
              closeOnDocumentClick
              contentStyle={{
                width: "350px",
                height: "auto",
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              {(close) => (
                <div>
                  <div className="flex justify-end ">
                    <button type="button">
                      <IoCloseCircleOutline
                        type="button"
                        onClick={close}
                        onMouseDown={(e) => e.stopPropagation()}
                        size={25}
                      />
                    </button>
                  </div>
                  <ReflectModal postId={postId} />
                </div>
              )}
            </Popup> */}
            {/* <div className=""></div> */}
            <button
              onClick={() => {
                commentButtonHandler();
              }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <img
                className=" ml-1 text-black"
                src={CommentIcon}
                alt="Comment"
              />
              {/* <FaRegComment className="ml-2 mb-0.5" size={17} /> */}
            </button>

            <Popup
              trigger={
                <button
                  className="mb-1 ml-1 flex items-center justify-center"
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  <span className="pt-1">
                    <PiShareFatLight size={18} />
                  </span>
                </button>
              }
              modal
              closeOnEscape
              closeOnDocumentClick
              // onClose={(open) => setOpenShare(!open)}
              contentStyle={{
                width: "300px",
                height: "auto",
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              {(close) => (
                <div>
                  <div className="flex justify-end ">
                    <button type="button">
                      <IoCloseCircleOutline
                        type="button"
                        onClick={close}
                        onMouseDown={(e) => e.stopPropagation()}
                        size={25}
                      />
                    </button>
                  </div>

                  <SharePost postId={postId} />
                </div>
              )}
            </Popup>
            {/* <button
              className="mb-1 flex items-center justify-center"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="pt-1">
                <MdOutlineSaveAlt size={18} />
              </span>
            </button> */}
          </div>

          {isAnonymous ? (
            <div className="p-2">
              {" "}
              <BsIncognito />
            </div>
          ) : (
            <div
              className="lg:text-sm lg:font-medium hover:underline flex flex-col justify-center items-end lg:mb-4 mb-6 gap-1 cursor-pointer"
              onClick={() => {
                navigate(`/user-profile/${authorUserName}`);
              }}
              onMouseDown={(e) => e.stopPropagation()}
              title={`@${authorUserName}`}
            >
              <img
                className="w-[20px] h-[20px] rounded-full shadow-lg hover:border mr-2"
                src={authorImage}
                alt="User Profile"
                onClick={(e) => {
                  authorButtonHandler();
                }}
                onMouseDown={(e) => e.stopPropagation()}
              />
              <span className="lg:text-xs text-xs">{authorName}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
