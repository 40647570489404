import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const refreshAccessToken = createAsyncThunk(
  "auth/refreshAccessToken",
  async (_, { getState, dispatch }) => {
    const { refreshToken } = getState().auth;

    try {
      const response = await fetch(
        "https://api.atmirrors.com/auth/refresh",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refreshToken }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to refresh token: ${response.statusText}`);
      }

      const result = await response.json();

      console.log("Response result:", result);

      if (
        !result.success ||
        !result.data ||
        !result.data.accessToken ||
        !result.data.refreshToken
      ) {
        throw new Error("Invalid response data");
      }

      const { accessToken, refreshToken: newRefreshToken } = result.data;

      localStorage.setItem("accessToken", JSON.stringify(accessToken));
      localStorage.setItem("refreshToken", JSON.stringify(newRefreshToken));

      dispatch(setToken(accessToken));
      dispatch(setRefreshToken(newRefreshToken));

      return accessToken;
    } catch (error) {
      console.error("Error refreshing access token:", error);
      throw error; // Re-throw the error to be caught by `unwrap()`
    }
  }
);

const initialState = {
  signupData: null,
  loading: false,
  accessToken: localStorage.getItem("accessToken")
    ? JSON.parse(localStorage.getItem("accessToken"))
    : null,
  refreshToken: localStorage.getItem("refreshToken")
    ? JSON.parse(localStorage.getItem("refreshToken"))
    : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSignupData(state, value) {
      state.signupData = value.payload;
    },
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setToken(state, value) {
      state.accessToken = value.payload;
    },
    setRefreshToken(state, value) {
      state.refreshToken = value.payload;
    },
    updateSignupDataOtp(state, action) {
      state.signupData.otp = action.payload;
    },
    updateUserDetailsSignupData(state, action) {
      state.signupData = { ...state.signupData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshAccessToken.fulfilled, (state, action) => {
      state.accessToken = action.payload;
    });
  },
});

export const {
  setSignupData,
  setLoading,
  setToken,
  setRefreshToken,
  updateSignupDataOtp,
  updateUserDetailsSignupData,
} = authSlice.actions;

export default authSlice.reducer;
