import React, { useEffect, useRef, useState, useCallback } from "react";
import AltImage from "../../../Images/profile-picture-3.jpg";
import { useSelector } from "react-redux";
import { apiConnector } from "../../../services/apiconnector";
import { PostCard } from "../../../Components/Dashboard/NewUI/PostCard";
import SkeletonLoading from "../../../Components/Dashboard/NewUI/SkeletonLoading";
import throttle from "lodash/throttle";

export const Dashboard = ({ showAutoSuggestResults }) => {
  const { accessToken, refreshToken } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [postData, setPostData] = useState(null);
  const [postLoading, setPostLoading] = useState(false);
  const [postError, setPostError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [removedPost, setRemovedPost] = useState(null);
  const scrollPositionRef = useRef(0);
  const undoTimeoutRef = useRef(null);

  const [progress, setProgress] = useState(100);
  let timeLeft = 5000;

  useEffect(() => {
    if (showAutoSuggestResults) {
      console.log("Auto-suggestions are being fetched...");
    }
  }, [showAutoSuggestResults]);

  const fetchPostData = async () => {
    if (postLoading || !hasMore) return;

    setPostLoading(true);
    try {
      const responseData = await apiConnector(
        "GET",
        `https://api.atmirrors.com/posts?page=${page}`,
        null
      );
      console.log("Home Page data Received", responseData);

      const posts = responseData.data.data;
      const moreDataExists = responseData.data.hasMore;

      setPostData((prevData) => ({
        ...prevData,
        posts:
          prevData && prevData.posts ? [...prevData.posts, ...posts] : posts,
      }));

      setHasMore(moreDataExists);
    } catch (error) {
      console.log("Error fetching user data", error);
      setPostError("Error fetching posts. Please try again later.");
    } finally {
      setPostLoading(false);
    }
  };

  const handleScroll = useCallback(
    throttle(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 300 >=
        document.documentElement.offsetHeight
      ) {
        scrollPositionRef.current = window.scrollY;
        if (hasMore && !postLoading) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    }, 500),
    [hasMore, postLoading]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    fetchPostData();
  }, [page]);

  useEffect(() => {
    window.scrollTo(0, scrollPositionRef.current);
  }, [postData]);

  const removePostFromList = (postId) => {
    const postToRemove = postData.posts.find((post) => post.id === postId);
    setRemovedPost(postToRemove);
    setPostData((prevData) => ({
      ...prevData,
      posts: prevData.posts.filter((post) => post.id !== postId),
    }));

    setProgress(100);
    undoTimeoutRef.current = setTimeout(() => {
      setRemovedPost(null);
    }, 5000);
  };

  const undoRemove = () => {
    if (removedPost) {
      setPostData((prevData) => ({
        ...prevData,
        posts: [removedPost, ...prevData.posts],
      }));
      setRemovedPost(null);
      clearTimeout(undoTimeoutRef.current);
    }
  };

  useEffect(() => {
    if (removedPost) {
      let interval = setInterval(() => {
        timeLeft -= 100;
        setProgress((timeLeft / 5000) * 100);
        if (timeLeft <= 0) {
          clearInterval(interval);
          setRemovedPost(null);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [removedPost]);

  return (
    <div>
      {removedPost && (
        <div className="lg:w-[51%] w-11/12 fixed lg:bottom-[8.5rem] bottom-[8rem] left-1/2 transform -translate-x-1/2">
          <div className="flex flex-col items-center w-full justify-center p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-lg">
            <div className="flex flex-col items-center p-2">
              <span className="text-gray-700 font-medium">
                This post has been removed.
              </span>
              <span className="text-sm text-gray-500">
                You can undo this action or dismiss the notification.
              </span>

              <div className="flex mt-3 space-x-4">
                <button
                  onClick={undoRemove}
                  className="text-blue-600 underline font-medium hover:text-blue-800"
                >
                  Undo
                </button>
                <button
                  onClick={() => setRemovedPost(null)}
                  className="text-gray-600 underline font-medium hover:text-gray-800"
                >
                  Dismiss
                </button>
              </div>
            </div>

            <div className="h-1 bg-gray-300 mt-4 w-full rounded-full overflow-hidden">
              <div
                className="h-full bg-black"
                style={{ width: `${progress}%`, transition: "width 5s linear" }}
              />
            </div>
          </div>
        </div>
      )}
      <div>
        {page <= 1 && postLoading ? (
          <SkeletonLoading />
        ) : postLoading ? (
          <div>Loading...</div>
        ) : postData && postData.posts && postData.posts.length > 0 ? (
          <div className="pt-[4.2rem] p-3 pb-0 mb-[7rem]">
            {postData.posts.map((post, index) => (
              <PostCard
                key={index}
                Name={post?.reviewedUser?.fullName || "Unknown"}
                CompanyName={post?.reviewedUser?.profile?.company || "Unknown"}
                image={post?.reviewedUser?.userImage || AltImage}
                postId={post?.id || ""}
                postTitle={post?.title || ""}
                body={post?.body || ""}
                authorName={post?.author?.fullName || "Unknown"}
                upvoteCount={post?.upVoteCount || 0}
                downVoteCount={post?.downVoteCount || 0}
                isAnonymous={post?.isAnonymous || false}
                authorId={post?.author?.id || ""}
                authorImage={post?.author?.userImage || AltImage}
                taggedUserId={post?.reviewedUser?.id || ""}
                fetchPostData={fetchPostData}
                createdAt={post?.createdAt || ""}
                updatedAt={post?.updatedAt || ""}
                reviewedUserRating={post?.reviewedUserRating || 1}
                currentCompany={
                  post?.reviewedUser?.profile?.company || "Unknown"
                }
                currentRole={post?.reviewedUser?.profile?.workRole || "Unknown"}
                voteType={post?.voteType || ""}
                hasUserVoted={post?.hasUserVoted || false}
                authorUserName={post?.author?.username}
                reviewedUserUserName={post?.reviewedUser?.username}
                onRemove={() => removePostFromList(post?.id)}
                removedPost={removedPost}
              />
            ))}
          </div>
        ) : (
          <div className="pt-[5.5rem] pb-0 p-2 mb-[7rem] flex items-center justify-center h-[90vh]">
            <div className="flex flex-col gap-3 items-center justify-center w-[365px] h-full">
              <div>No Posts found.</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
