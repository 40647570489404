import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import checkAnimation from "../../../Images/Animations/checkMark.gif";
import { apiConnector } from "../../../services/apiconnector";

export const WorkEmailVerification = () => {
  const [loading, setLoading] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [showError, setShowError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [timer, setTimer] = useState(0);

  //git check

  const validateEmailDomain = (email) => {
    const allowedDomains =
      /@(outlook\.com|hotmail\.com|atmirrors\.com|gmail\.com)$/;
    return allowedDomains.test(email);
  };

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const sendEmail = async (data, toastMessage) => {
    const toastId = toast.loading(toastMessage);

    if (!data.email.trim()) {
      setIsShaking(true);
      setShowError(true);
      setTimeout(() => setIsShaking(false), 500);
      toast.dismiss(toastId);
      toast.error("Email cannot be empty.");
      return;
    }

    if (!validateEmailDomain(data.email)) {
      setIsShaking(true);
      setShowError(true);
      setTimeout(() => setIsShaking(false), 500);
      toast.dismiss(toastId);
      toast.error("Please enter a valid work email");
      return;
    }

    setLoading(true);

    try {
      const res = await apiConnector(
        "POST",
        "https://api.atmirrors.com/email-verification/initiate",
        data
      );
      setLoading(false);
      toast.dismiss(toastId);
      console.log("WorkEmail Verification", res);
      toast.success(
        "Email Sent Successfully, Please Check Your Email to Continue Further"
      );
      setEmailSent(true);
      setShowError(false);
      setTimer(120); // 2 minutes timer
    } catch (error) {
      setLoading(false);
      setIsShaking(true);
      setShowError(true);
      setTimeout(() => setIsShaking(false), 500);
      toast.dismiss(toastId);
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const onSubmit = (data) => {
    sendEmail(data, "Sending password reset email...");
  };

  const resendMailHandler = async () => {
    const email = watch("email");
    sendEmail({ email }, "Resending verification email...");
  };

  useEffect(() => {
    let timerId;
    if (timer > 0) {
      timerId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [timer]);

  const changeEmail = () => {
    setEmailSent(false);
    setTimer(0);
    reset();
  };

  return (
    <div className="flex items-center flex-col justify-center w-screen h-screen">
      <div className="flex items-center flex-col justify-center w-[325px]">
        <div className="flex flex-col gap-2">
          <h2 className="text-gray-900 text-center text-4xl font-semibold">
            Mirror
          </h2>
          <span className="font-medium text-lg text-gray-500">
            Work Email Verification
          </span>
        </div>

        <div className="mt-8">
          {!emailSent ? (
            <form
              className="flex flex-col items-center gap-[20px]"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                {...register("email")}
                placeholder="Email address"
                name="email"
                type="email"
                className={`bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[69px] text-sm border rounded-md ${
                  isShaking ? "animate-shake" : ""
                } ${showError || errors.email ? "border-red-600" : ""}`}
              />
              {errors.email && (
                <span className="text-red-600">{errors.email.message}</span>
              )}

              <span className="font-normal text-sm text-gray-500">
                Enter your work email to receive a verification code and confirm
                your company affiliation.
              </span>

              <div className="mt-2">
                <button
                  className="bg-black w-[325px] h-[58px] text-white rounded-md"
                  disabled={loading}
                >
                  <span>Submit</span>
                </button>
              </div>
            </form>
          ) : (
            <div className="flex flex-col justify-center items-center gap-2">
              <img
                src={checkAnimation}
                className="w-[30%] h-[30%] mt-[-40px]"
              />

              <div className="mt-[-10px]">
                <span className="font-semibold">{watch("email")}</span>
              </div>

              <p className="text-sm font-semibold text-gray-500">
                Email Sent Successfully! Please check your email to continue
                further.
              </p>

              <button
                className="bg-transparent text-lg text-black underline rounded-md hover:text-blue-600"
                onClick={changeEmail}
              >
                <span className="text-sm">Change Email</span>
              </button>

              <div>
                {timer > 0 ? (
                  <p className="text-sm text-gray-500 mt-4">
                    You can resend the email in {Math.floor(timer / 60)}:
                    {timer % 60 < 10 ? `0${timer % 60}` : timer % 60} minutes.
                  </p>
                ) : (
                  <div className="flex gap-2 justify-center items-center flex-col">
                    <button
                      className="bg-transparent text-lg text-black underline rounded-md hover:text-blue-600"
                      onClick={resendMailHandler}
                    >
                      <span className="text-sm">Resend Link</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
