import React from "react";
import image from "../../Images/profile-picture-3.jpg";

const Posts = ({ posts }) => {
  // console.log(reviews);
  return (
    <div className="grid mb-8 h-full  border border-gray-200 rounded-lg shadow-sm md:mb-12 md:grid-cols-2 bg-white">
      {posts.map((post, index) => (
        <figure
          key={index}
          className={`flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 ${
            index === posts.length - 1
              ? "rounded-b-lg"
              : index % 2 === 0
              ? "md:rounded-se-lg"
              : "md:rounded-es-lg"
          }`}
        >
          <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8">
            <h3 className="text-lg font-semibold text-gray-900">
              {post.title}
            </h3>
            <p className="my-4">{post.body}</p>
          </blockquote>
          <figcaption className="flex items-center justify-center ">
            <img
              className="rounded-full w-9 h-9"
              src={post.taggedUser.image || image}
              alt="profile picture"
            />
            <div className="space-y-0.5 font-medium text-left ms-3">
              <div>{post.taggedUser.fullName}</div>
              <div className="text-sm text-gray-500">
                {post.taggedUser.email}
              </div>
            </div>
          </figcaption>
        </figure>
      ))}
    </div>
  );
};

export default Posts;
