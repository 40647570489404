import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../slices/authSlice";
import { setUser } from "../slices/profileSlice";
import toast from "react-hot-toast";
import image from "../Images/profile-picture-3.jpg";
import { apiConnector } from "../services/apiconnector";
import Popup from "reactjs-popup";
import { IoMdClose } from "react-icons/io";
import { ActionModal } from "../Components/core/Modals/ActionModal";

export const Settings = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleClickUpdateProfileDetails = () => {
    navigate("/settings/updateProfileDetails");
  };

  const handleClickUpdateProfileImage = () => {
    navigate("/settings/updateProfileImage");
  };

  const handleReportBug = () => {
    navigate("/settings/reportBug");
  };

  const handleClickChangePassword = () => {
    navigate("/settings/changePassword");
  };
  const dispatch = useDispatch();

  const handleClickSignOut = () => {
    setShowModal(true);
  };

  const { refreshToken } = useSelector((state) => state.auth);
  console.log("RefreshToken in Settings", refreshToken);

  const handleConfirmSignOut = async () => {
    const toastId = toast.loading("Logging Out");

    try {
      const res = await apiConnector(
        "POST",
        `https://api.atmirrors.com/auth/logout`,
        {
          refreshToken: refreshToken,
        }
      );

      console.log("LoggedOut SuccessFully", res);

      dispatch(setToken(null));
      dispatch(setUser(null));
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");

      toast.dismiss(toastId);
      toast.success("Logged Out Successfully");
      navigate("/auth/login");
    } catch (error) {
      console.log(error);
      toast.dismiss(toastId);
      toast.error(`Error: Unable to Logout`);
    }
  };

  console.log("accessToken in settings", localStorage.getItem("accessToken"));

  const [isToggledReview, setIsToggledReview] = useState(false);
  const [isToggledComment, setIsToggledComment] = useState(false);

  const [reviewNotification, setreviewNotification] = useState(false);
  const [commentNotification, setcommentNotification] = useState(false);

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;

    if (name === "reviewNotification") {
      setreviewNotification(checked); // Update reviewNotification based on the checked value
    }

    if (name === "commentNotification") {
      setcommentNotification(checked);
    }
  };

  const handleReviewNotifDivClick = (event) => {
    if (event.target.tagName !== "INPUT") {
      setreviewNotification((prev) => !prev);
    }
  };

  const handleCommentNotifDivClick = (event) => {
    if (event.target.tagName !== "INPUT") {
      setcommentNotification((prev) => !prev);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const avatarUrl = useRef(image);
  const updateAvatar = (imgSrc) => {
    avatarUrl.current = imgSrc;
  };

  return (
    <div className="bg-white min-h-screen flex justify-center items-start">
      <div className="p-6 md:w-[390px] w-full max-w-md h-auto relative mt-[70px]">
        <h1 className="text-4xl font-semibold mb-10 text-left">Settings</h1>
        <div className="text-lg text-black space-y-2">
          <div
            className="flex items-center justify-between cursor-pointer hover:bg-black hover:text-white px-4 py-2 rounded-lg"
            onClick={handleClickUpdateProfileDetails}
          >
            <span>Update Profile Details</span>
            <MdArrowForwardIos
              className="text-2xl cursor-pointer hover:text-black"
              style={{ color: "#3468c0" }}
            />
          </div>

          <div
            className="flex items-center justify-between cursor-pointer hover:bg-black hover:text-white px-4 py-2 rounded-lg"
            onClick={() => setModalOpen(true)}
          >
            <span>Update Profile Image</span>
            <MdArrowForwardIos
              className="text-2xl cursor-pointer hover:text-black"
              style={{ color: "#3468c0" }}
            />
            {/* <MdArrowForwardIos
              className="text-2xl cursor-pointer hover:text-black"
              onClick={handleClickUpdateProfileImage}
              style={{ color: "#3468c0" }}
            /> */}
          </div>
          <div
            className="flex items-center justify-between cursor-pointer hover:bg-black hover:text-white px-4 py-2 rounded-lg"
            onClick={handleClickChangePassword}
          >
            <span>Change Password</span>
            <MdArrowForwardIos
              className="text-2xl cursor-pointer hover:text-black"
              style={{ color: "#3468c0" }}
            />
          </div>
          <div
            className="flex items-center justify-between cursor-pointer hover:bg-black hover:text-white px-4 py-2 rounded-lg"
            onClick={handleReviewNotifDivClick}
          >
            <span>Review Notifications</span>
            <label class="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                id="reviewNotification"
                name="reviewNotification"
                checked={reviewNotification}
                onChange={changeHandler}
                className="sr-only peer"
              />
              <div
                class="relative w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-800"
                onClick={handleReviewNotifDivClick}
              ></div>
            </label>
          </div>
          <div
            className="flex items-center justify-between cursor-pointer hover:bg-black hover:text-white px-4 py-2 rounded-lg"
            style={{ marginTop: "0.5em" }}
            onClick={handleCommentNotifDivClick}
          >
            <span>Comment Notifications</span>
            <label class="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                id="reviewNotification"
                name="commentNotification"
                checked={commentNotification}
                onChange={changeHandler}
                className="sr-only peer"
              />
              <div
                class="relative w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-800"
                onClick={handleCommentNotifDivClick}
              ></div>
            </label>
          </div>

          <Popup
            modal
            closeOnEscape
            closeOnDocumentClick
            trigger={
              <div className="flex items-center justify-between cursor-pointer hover:bg-black hover:text-white px-4 py-2 rounded-lg">
                <span>Sign Out</span>
                <MdArrowForwardIos
                  className="text-2xl cursor-pointer hover:text-black"
                  style={{ color: "#3468c0" }}
                />
              </div>
            }
            contentStyle={{
              width: "0px",
              height: "auto",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            {(close) => (
              <div>
                <ActionModal
                  close={close}
                  ModalTitle="Sign Out"
                  ModalBodyContent="Are you sure you want to sign out? This action will log you out of the application."
                  ActionButtonValue="Sign Out"
                  ActionButtonLogic={handleConfirmSignOut}
                />
              </div>
            )}
          </Popup>

          <div
            className="flex items-center justify-between cursor-pointer hover:bg-black hover:text-white px-4 py-2 rounded-lg"
            onClick={handleReportBug}
          >
            <span>Report a Bug</span>
            <MdArrowForwardIos
              className="text-2xl cursor-pointer hover:text-black"
              style={{ color: "#3468c0" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
