import React, { useState } from "react";
import { MdEdit } from "react-icons/md";

/*
  UserName,
  CompanyName,
  UserImageURL,
  UserRating,
  UserReviewCount,
   */
export const UsercardAtCompanyPage = ({ userCompanyPageProfile }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const userProfilePageRedirectHandler = () => {
    console.log("User userCompanyPageProfile handler called.");
  };

  const handleUserCardAtCompanyPageClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
      userProfilePageRedirectHandler();
    }, 200);
  };

  return (
    <div>
      {/* <div
        className={`max-w-md mx-auto shadow-md overflow-hidden md:max-w-3xl mb-3 p-1 pt-3 pb-3 ${
          isAnimating ? "bg-gray-200" : ""
        }} transition-colors duration-200`}
        onClick={handleUserCardAtCompanyPageClick}
      > */}
        <div
          className="relative flex flex-col items-center p-2 bg-white border border-gray-200 
        rounded-3xl shadow-md w-48 m-2"
        >
          <div className="flex items-center mb-2">
            <img
              src={userCompanyPageProfile.profileImageURL}
              alt={userCompanyPageProfile.fullName}
              className="w-8 h-8 rounded-full mr-2"
            />
            <div className="flex flex-col text-left">
              <h3 className="text-lg font-semibold">
                {userCompanyPageProfile.fullName}
              </h3>
              <p className="text-gray-500 text-xs">{userCompanyPageProfile.role}</p>
            </div>
          </div>
          <div className="flex flex-col items-center w-full">
            <div>
              <div className="flex items-center justify-center">
                <span className="text-xl font-semibold">
                  {userCompanyPageProfile.rating}
                </span>
                <span className="text-black text-xs ml-1">★</span>
              </div>
              <p className="text-gray-500 text-sm text-center">
                {userCompanyPageProfile.reviews} Reviews
              </p>
            </div>
            <button className="absolute bottom-3 right-3 bg-black text-white p-2 rounded-full shadow-md">
              <MdEdit size={12} />
            </button>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default UsercardAtCompanyPage;
