import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { apiConnector } from "../../services/apiconnector";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../slices/profileSlice";

export const UpdateProfileDetails = () => {
  const { user } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  // console.log("User in UpdateUser", user);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    workRole: "",
    company: "",
    about: "",
    location: "",
    // gender: "",
    // dateOfBirth: "",
  });

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    console.log(formData);

    const filteredData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== "")
    );

    const toastId = toast.loading("Updating Your Profile..."); // Store toast ID

    try {
      await apiConnector(
        "POST",
        "https://api.atmirrors.com/users/update",
        filteredData
      )
        .then((res) => {
          toast.dismiss(toastId);
          toast.success("Profile Updated successfully");
          console.log("Profile Updated successfully. Data received:", res);
          dispatch(setUser(res.data.data));
          navigate(`/user-profile/${user.username}`);
        })
        .catch((error) => {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
          // console.log(error);
          // console.log(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen gap-4">
      <div className="flex flex-col items-center justify-center gap-5 w-full lg:w-auto lg:mb-0 mb-[14rem]">
        <div className="flex flex-col gap-2 lg:mt-0 mt-[200px]">
          <span className="font-semibold text-xl text-gray-800">
            Update Profile Details
          </span>
        </div>

        <div className=" w-full lg:w-auto ">
          <div className="overflow-y-auto  max-h-[60vh] w-full p-4">
            <form
              className="flex flex-col items-center gap-5"
              onSubmit={submitHandler}
            >
              <div className="flex lg:flex-row flex-col lg:gap-8 gap-2">
                <div className="relative">
                  <input
                    onChange={changeHandler}
                    value={formData.workRole}
                    placeholder={
                      user.profile.workRole
                        ? user.profile.workRole
                        : "Please enter your current workRole"
                    }
                    name="workRole"
                    type="text"
                    className={`bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[69px] text-sm border rounded-md
                      ${
                        user.profile.workRole
                          ? "border-green-500"
                          : "border-red-500"
                      }`}
                  />
                  <label className="bg-white pl-1 pr-1 absolute top-1/3 left-3 transform opacity-100 -translate-y-8 text-xs pointer-events-none transition-all duration-300 text-gray-400">
                    Current Role
                  </label>
                </div>
                <div className="relative">
                  <input
                    onChange={changeHandler}
                    value={formData.company}
                    placeholder={
                      user.profile.company
                        ? user.profile.company
                        : "Please enter your current company"
                    }
                    name="company"
                    type="text"
                    className={`p-3 text-sm w-[325px] h-[69px] border rounded-md ${
                      user.profile.company
                        ? "border-green-500"
                        : "border-red-500"
                    } `}
                  />
                  <label className="bg-white pl-1 pr-1 absolute top-1/3 left-3 transform opacity-100 -translate-y-8 text-xs pointer-events-none transition-all duration-300 text-gray-400">
                    Current Company
                  </label>
                </div>
              </div>

              {/* <div className="flex lg:flex-row flex-col lg:gap-8 gap-2">
                <input
                  onChange={changeHandler}
                  value={formData.dateOfBirth}
                  placeholder="Date Of Birth"
                  name="dateOfBirth"
                  type="date"
                  className="p-3 text-sm w-[325px] h-[69px] border rounded-md"
                />

                <select
                  onChange={changeHandler}
                  value={formData.gender}
                  name="gender"
                  className="p-3 text-sm w-[325px] h-[69px] border rounded-md"
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div> */}

              <div className="flex lg:flex-row flex-col lg:gap-8 gap-2">
                {/* 
                <div className="relative">
                  <input
                    onChange={changeHandler}
                    value={formData.location}
                    placeholder=""
                    name="location"
                    type="tel"
                    pattern="[0-9]{10}"
                    title="Please enter a 10-digit phone number"
                    className="p-3 text-sm w-[325px] h-[69px] border rounded-md"
                  />
                  <label
                    className={`absolute left-3 top-9 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                      formData.location &&
                      "text-xs text-gray-700 -translate-y-10"
                    }`}
                    style={{
                      backgroundColor: formData.location
                        ? "#fff"
                        : "transparent",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    Phone Number
                  </label>
                </div> */}
                <div className="relative">
                  <input
                    onChange={changeHandler}
                    value={formData.location}
                    placeholder={
                      user.profile.location
                        ? user.profile.location
                        : "Please enter your current location"
                    }
                    name="location"
                    type="text"
                    title="Please enter your current location"
                    className={`p-3 text-sm w-[325px] h-[69px] border rounded-md ${
                      user.profile.location
                        ? "border-green-500"
                        : "border-red-500"
                    }`}
                  />
                  <label className="bg-white pl-1 pr-1 absolute top-1/3 left-3 transform opacity-100 -translate-y-8 text-xs pointer-events-none transition-all duration-300 text-gray-400">
                    Current Location
                  </label>
                </div>
                <div className="relative">
                  <textarea
                    onChange={changeHandler}
                    value={formData.about}
                    placeholder={
                      user.profile.about ? user.profile.about : "About"
                    }
                    name="about"
                    type="text"
                    title="Write something about you"
                    className={`p-3 text-sm w-[325px] h-[69px] border rounded-md ${
                      user.profile.about ? "border-green-500" : "border-red-500"
                    }`}
                  />
                  <label className="bg-white pl-1 pr-1 absolute top-1/3 left-3 transform opacity-100 -translate-y-8 text-xs pointer-events-none transition-all duration-300 text-gray-400">
                    About
                  </label>
                  {/* <label
                    className={`absolute top-1/3 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                      formData.about
                        ? "opacity-100 -translate-y-8 text-xs"
                        : "opacity-0"
                    }`}
                    style={{
                      backgroundColor: formData.about ? "#fff" : "transparent",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    About
                  </label> */}
                </div>
              </div>
            </form>
          </div>
          <div className="mt-2 w-full flex justify-center ">
            <button
              onClick={submitHandler}
              className="bg-black w-[325px] h-[58px] text-white rounded-md"
            >
              <span>Update Profile</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
