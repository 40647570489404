import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { setRefreshToken, setToken } from "../../../slices/authSlice";
import { useDispatch } from "react-redux";
import { setUser } from "../../../slices/profileSlice";

export const OAuthStatus = () => {
  const [status, setStatus] = useState({ loading: true, message: "" });
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUrlParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      token: params.get("token"),
      refreshToken: params.get("refreshToken"),
    };
  };

  useEffect(() => {
    const { token, refreshToken } = getUrlParams(location.search);

    if (token && refreshToken) {
      setStatus({ loading: true, message: "Redirecting... Auth Success" });

      axios
        .get("https://api.atmirrors.com/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("User details fetched successfully:", response.data.data);
          console.log("accessToken", token);
          console.log("refreshToken", refreshToken);

          //token
          dispatch(setToken(token));
          localStorage.setItem("accessToken", JSON.stringify(token));

          //refresh Token
          dispatch(setRefreshToken(refreshToken));
          localStorage.setItem("refreshToken", JSON.stringify(refreshToken));

          //user
          dispatch(setUser(response.data.data));
          localStorage.setItem("user", JSON.stringify(response.data.data));

          setStatus({
            loading: false,
            message: "Authentication Successful! Redirecting...",
          });

          // Redirect to dashboard or any appropriate page
          //   setTimeout(() => {
          //     navigate("/dashboard");
          //   }, 2000);
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
          setStatus({
            loading: false,
            message: "Error: Unable to fetch user details",
          });
        });
    } else {
      setStatus({
        loading: false,
        message: "Error: Missing token or refreshToken",
      });
    }
  }, [location.search, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-6 rounded-lg text-center max-w-lg">
        {status.loading ? (
          <div className=" text-lg">Loading...</div>
        ) : (
          <div className="text-lg ">{status.message}</div>
        )}
      </div>
    </div>
  );
};
