import React, { useState, useEffect, useCallback, useRef } from "react";
import { UserProfileCard } from "./UserProfileCard";
import ShareProfileCard from "./ShareProfileCard";
import { apiConnector } from "../../services/apiconnector";
import { useSelector } from "react-redux";
import { GoClock } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { debounce } from "lodash";

export const SearchUserProfile = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const profile = useSelector((state) => state.profile);
  const cardRefs = useRef([]);

  useEffect(() => {
    const storedSearches =
      JSON.parse(localStorage.getItem("recentSearches")) || [];
    setRecentSearches(storedSearches);
  }, []);

  const saveRecentSearch = (term) => {
    if (term && term.trim() && term !== previousSearchTerm) {
      setRecentSearches((prevSearches) => {
        const updatedSearches = [
          term,
          ...prevSearches.filter((item) => item !== term),
        ].slice(0, 10);
        localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
        return updatedSearches;
      });
      setPreviousSearchTerm(term);
    }
  };

  const removeRecentSearch = (term) => {
    const updatedSearches = recentSearches.filter((search) => search !== term);
    setRecentSearches(updatedSearches);
    localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
  };

  const clearAllRecentSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem("recentSearches");
  };

  const fetchUsers = async (query) => {
    if (loading) return;
    setLoading(true);
    setError("");
    setNoDataFound(false);

    try {
      const response = await apiConnector(
        "POST",
        "https://api.atmirrors.com/search/users",
        {
          query,
          limit: 10,
          page: 1,
        }
      );
      const usersData = response.data.data.items;
      setNoDataFound(usersData.length === 0);
      setUsers(usersData);
      saveRecentSearch(query);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError(
        err.response?.status === 404 ? "No users found" : "Error fetching users"
      );
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchUsersRef = useRef(
    debounce((query, fetchFn) => fetchFn(query), 500)
  );

  useEffect(() => {
    if (searchTerm.trim()) {
      debouncedFetchUsersRef.current(searchTerm, fetchUsers);
    } else {
      debouncedFetchUsersRef.current.cancel();
      setUsers([]);
      setLoading(false);
    }
  }, [searchTerm]);

  const handleRecentSearchClick = (term) => {
    setSearchTerm(term);
  };

  return (
    <div className="pt-[5.5rem] flex flex-col justify-center items-center">
      <div className="relative w-[325px] lg:w-[400px] mt-2">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="text-sm md:text-base bg-richblack-800 text-richblack-5 p-3 w-[325px] lg:w-[400px] h-[50px] border rounded-md"
          placeholder="Search User"
        />
      </div>

      <div className="lg:w-[400px] w-[325px] ">
        {recentSearches.length > 0 && !searchTerm ? (
          <div className="w-full bg-white rounded-lg">
            <div className="pt-4 md:pt-6 flex justify-between items-center">
              <h2 className="text-sm md:text-base pl-2 md:pl-3 text-left font-medium w-3/4">
                Recent Searches
              </h2>
            </div>

            <button
              onClick={clearAllRecentSearches}
              className="text-xs w-full text-end text-blue-500 hover:underline"
            >
              Clear All
            </button>

            <ul className="space-y-2 md:space-y-3">
              {recentSearches.map((search, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between bg-muted p-2 md:p-3 rounded-md hover:bg-gray-100"
                >
                  <div
                    className="flex items-center gap-2 md:gap-3"
                    onClick={() => handleRecentSearchClick(search)}
                  >
                    <GoClock className="text-muted-foreground w-4 h-4" />
                    <span className="text-sm md:text-base hover:underline hover:text-blue-500">
                      {search}
                    </span>
                  </div>
                  <button
                    onClick={() => removeRecentSearch(search)}
                    className=""
                  >
                    <RxCross2 className="w-4 h-4" />
                    <span className="sr-only">Remove search</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          !searchTerm && (
            <div className="mt-4 lg:w-[400px] w-[325px] text-center text-gray-500">
              <p>
                No recent searches found. Start typing to search for profiles!
              </p>
            </div>
          )
        )}
      </div>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {noDataFound && searchTerm && (
        <div className="mt-[2rem] flex flex-col gap-5">
          <p>No users found</p>
          <ShareProfileCard profile={profile.user} />
        </div>
      )}
      {recentSearches.length > 0 && searchTerm && (
        <div className="w-full max-w-md h-[450px] overflow-x-hidden overflow-y-auto mt-2">
          {users.length > 0 && (
            <ul className="lg:space-y-4">
              {users.map((user, index) => (
                <UserProfileCard
                  key={user.email}
                  ref={(el) => (cardRefs.current[index] = el)}
                  fullName={user.full_name}
                  username={user.username}
                  email={user.email}
                  image={user.user_image}
                  userRating={user.average_rating}
                  currentCompany={user.profile.company}
                  currentRole={user.profile.work_role}
                  reviewCount={user.review_count}
                />
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};
