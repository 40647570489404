import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { apiConnector } from "../../services/apiconnector";
import { PostCard } from "../../Components/Dashboard/NewUI/PostCard";
import { CommentCard } from "../../Components/CommentPage/CommentCard";
import toast from "react-hot-toast";
import { IoSend } from "react-icons/io5";
import AltImage from "../../Images/profile-picture-3.jpg";

export const SharedPostPage = () => {
  const location = useLocation();
  const postId = location.pathname.split("/").at(-1);

  const [postData, setPostData] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const commentRef = useRef();

  const fetchData = async () => {
    setLoading(true);
    try {
      const responseData = await apiConnector(
        "GET",
        `https://api.atmirrors.com/posts/${postId}`,
        // `http://localhost:8000/api/v1/getPost/${postId}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
      console.log("Shared Post Page data Received", responseData);

      setPostData(responseData.data.data);
      // console.log("hello", postData.user);
      //   console.log("Data in PostData", PostData.comments);
      if (responseData.data.data) {
        console.log("hello", responseData.data.data.author.username);
        // console.log("Comments SharedPost", postData.comments);
      }
    } catch (error) {
      console.log("Error fetching Post data:", error); // Log the error for debugging
      setError("Error fetching Post data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const [formData, setFormData] = useState({
    post: postId,
    body: "",
  });

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    // console.log(formData);

    const toastId = toast.loading("Creating New Comment..."); // Store toast ID

    try {
      await apiConnector(
        "POST",
        "https://mirror-express-server.onrender.com/api/v1/posts/comments/create",
        // "http://localhost:8000/api/v1/posts/comments/create",
        formData,
        { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
      )
        .then((res) => {
          toast.dismiss(toastId);
          toast.success("Comment Created successfully");
          console.log("Comment Created Successfully. Data received:", res);
          fetchData();
          commentRef.current.reset();
          setFormData((prevFormData) => ({ ...prevFormData, body: "" }));
        })
        .catch((error) => {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
          // console.log(error);
          // console.log(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className=" max-w-3xl mx-auto">
      <div className="pt-[5.5rem] mb-[5.5rem]">
        {isLoading ? (
          <div className="flex h-full w-full flex-col gap-4 items-center justify-center">
            <div class="spinner"></div>
            <div className="pb-[100px] font-medium ">Loading</div>
          </div>
        ) : error ? (
          <div>{error}</div>
        ) : postData ? (
          <div>
            <div className="">
              <PostCard
                key={0}
                Name={postData.reviewedUser?.username || "Anonymous User"}
                CompanyName={
                  postData.reviewedUser?.company || "Unknown Company"
                }
                image={postData.reviewedUser?.image || AltImage}
                postId={postData.id || "N/A"}
                body={postData.body || "No content available"}
                createdAt={postData.createdAt || "N/A"}
                updatedAt={postData.updatedAt || "N/A"}
                authorName={postData.author?.username || "Unknown Author"}
                downVoteCount={postData.downVoteCount ?? 0}
                upvoteCount={postData.upVoteCount ?? 0}
                isAnonymous={postData.isAnonymous ?? false}
                authorId={postData.author?.id || "N/A"}
                taggedUserId={postData.reviewedUser?.id || "N/A"}
                authorImage={postData.author?.image || AltImage}
                postTitle={postData.title || "Untitled"}
                taggedUserRating={postData.reviewedUserRating ?? "Not Rated"}
              />
            </div>

            <div className="p-2">Comments</div>
            <form
              onSubmit={submitHandler}
              ref={commentRef}
              className="flex p-2  pb-5 items-center justify-center "
            >
              <input
                required
                onChange={changeHandler}
                value={formData.body}
                placeholder="Write your review "
                name="body"
                type="text"
                className="bg-richblack-800  text-richblack-5  p-3 w-[80%]  h-[40px] text-sm border rounded-md"
              />
              <div className=" w-[20%]">
                <button className="bg-black w-[50%] h-[40px] text-white rounded-md">
                  <span className="flex justify-center">
                    <IoSend />
                  </span>
                </button>
              </div>
            </form>
            <div className="mb-[7rem]">
              {postData.comments &&
              postData.comments &&
              postData.comments.length > 0 ? (
                postData.comments
                  .slice()
                  .reverse()
                  .map((comment, index) => (
                    <CommentCard
                      key={index}
                      user={comment.user}
                      createdAt={comment.createdAt}
                      body={comment.body}
                      id={comment._id}
                      // fetchData={fetchData}
                      fetchUserData={fetchData}
                    />
                  ))
              ) : (
                <p>No comments available</p>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
