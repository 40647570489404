import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice";
import profileReducer from "../slices/profileSlice";
import postReducer from "../slices/postSlice";
import notificationsReducer from "../slices/notificationSlice";
import createPostSlice from "../slices/createPostSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  post: postReducer,
  createPost: createPostSlice,
  notification: notificationsReducer,
});

export default rootReducer;
