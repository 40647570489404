import React, { useState, useEffect } from "react";

const SkeletonProfileLoading = () => {
  return (
    <div className="w-[320px] md:w-[650px] h-[200px] md:h-[240px] shadow-md overflow-hidden animate-pulse relative">
      <div className="md:flex-col p-6">
        {/* Skeleton loading content */}
        <div className="md:shrink-0 ">
          <div className="flex flex-col items-center">
            <div className="flex flex-row md:gap-5">
              {/* Profile picture container */}
              <div className="flex flex-col justify-center items-center gap-5 mt-4 md:mt-5 md:mr-10 md:w-[95px] w-[80px] md:h-[154px] h-[130px]">
                {/* Profile picture */}
                <div className="w-full h-full md:w-25 md:h-25 w-10 h-10 rounded-full bg-gray-300"></div>
                {/* Name and company */}
                <div className="flex flex-col items-center gap-3 mt-2">
                  <div className="flex flex-row gap-2">
                    <div className="h-2 md:h-3 bg-gray-300 w-10 md:w-18"></div>
                    <div className="h-2 md:h-3 bg-gray-300 w-2 md:w-5"></div>
                  </div>
                  <div className="h-1.5 md:h-2 bg-gray-300 w-8 md:w-11"></div>
                </div>
              </div>
              {/* Side */}
              <div className="flex flex-col gap-1 text-left ml-7 md:ml-7 w-36 md:w-60">
                <div className="ml-2 md:ml-3 mt-4 md:mt-4 h-2 w-20 md:w-40 md:h-3 bg-gray-300"></div>
                <div className="ml-2 md:ml-3 mt-2 h-1 md:h-1.5 w-14 md:w-20 bg-gray-300"></div>
                <div className="ml-2 md:ml-3 mt-2 h-1 md:h-1.5 w-14 md:w-20 bg-gray-300"></div>
                <div className="ml-2 md:ml-3 mt-2 h-1 md:h-1.5 w-14 md:w-20 bg-gray-300"></div>

                <div className="ml-2 md:ml-3 mt-10 md:mt-14 h-3 md:h-3 bg-gray-300"></div>
                <div className="ml-2 md:ml-3 mt-1 h-3 md:h-3 bg-gray-300"></div>
              </div>
            </div>
          </div>
          {/* three dots */}
          <div className="absolute top-2 right-2">
            <div className="w-4 h-1 md:w-4 bg-gray-300 "></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkeletonAboutLoading = () => {
  return (
    <div className="w-[350px] md:w-[720px] h-auto shadow-md overflow-hidden animate-pulse mt-4 md:mt-7">
      {" "}
      {/* Set a fixed width */}
      <div className="md:flex-col px-6 py-2">
        {/* Skeleton loading content */}
        <div className="md:shrink-0 items-center">
          <div className="flex flex-col items-left gap-5">
            {/* Profile picture */}
            <div className="w-20 h-4 md:w-15 md:h-15 bg-gray-300"></div>
            {/* Name and company */}
            <div className="mt-2 flex flex-col items-left gap-2.5 ml-1">
              <div className="h-2 md:h-3 bg-gray-300 w-50 md:w-30"></div>
              <div className="h-2 md:h-3 bg-gray-300 w-50 md:w-18"></div>
              <div className="h-2 md:h-3 bg-gray-300 w-50 md:w-18"></div>
              <div className="h-2 md:h-3 bg-gray-300 w-50 md:w-18"></div>

              {/* More loading */}
              <div className="flex justify-center">
                {" "}
                {/* Added flex container */}
                <div className="h-1 md:h-2 bg-gray-300 w-20 md:w-18"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkeletonPostLoading = () => {
  return (
    <div className="w-[350px] md:w-[720px] shadow-md overflow-hidden mt-3 animate-pulse">
      <div className="md:flex-col m-4">
        {/* Skeleton loading content */}
        <div className="md:shrink-0 flex justify-between items-center">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-gray-300"></div>
            {/* Name and company */}
            <div className="flex flex-col">
              <div className="h-3 md:h-4 bg-gray-300 w-20 md:w-28"></div>
              <div className="h-2 md:h-3 mt-1 bg-gray-300 w-24 md:w-36"></div>
            </div>
          </div>
          <div className="flex justify-center items-start mt-3 gap-2">
            <div className="w-1 h-6 md:w-1 bg-gray-300 mb-3"></div>
          </div>
        </div>
        {/* Text below Name and company */}
        <div className="flex flex-col gap-1 text-left">
          <div className="ml-2 md:ml-3 mt-3 md:mt-4 h-2 md:h-3.5 bg-gray-300"></div>
          <div className="ml-2 md:ml-3 mt-1 h-2 md:h-3.5 bg-gray-300"></div>
          <div className="ml-2 md:ml-3 mt-1 h-2 md:h-3.5 bg-gray-300"></div>
          <div className="ml-2 md:ml-3 mt-1 h-2 md:h-3.5 w-24 md:w-36 bg-gray-300"></div>
        </div>
        {/* {Other features} */}
        <div className="flex justify-between items-center mt-5 h-[50px] ">
          <div className="ml-3 flex gap-3">
            <div className="w-1 h-6 md:w-2 md:h-8 bg-gray-300"></div>
            <div className="w-2 h-2 md:w-3 md:h-3 mt-1 md:mt-2 bg-gray-300"></div>
            <div className="w-1 h-6 md:w-2 md:h-8 bg-gray-300"></div>
            <div className="w-2 h-2 md:w-3 md:h-3 mt-1 md:mt-2 bg-gray-300"></div>
            <div className="w-3 h-3 ml-2 md:w-4 md:h-5 mt-1 bg-gray-300"></div>
            <div className="w-3 h-3 ml-1 md:w-4 md:h-5 mt-1 bg-gray-300"></div>
          </div>
          <div className="lg:text-sm lg:font-medium">
            <div className="h-3 md:h-4 bg-gray-300 w-10 md:w-14"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
const MultipleSkeletons = () => {
  return (
    <div
      className="flex flex-col items-center min-h-screen"
      style={{ paddingBottom: "110px" }}
    >
      <SkeletonProfileLoading />
      <div className="bg-gray-100 shadow-md overflow-hidden animate-pulse mt-3 md:mt-4 w-[300px] md:w-[340px] h-[30px] md:h-[45px] rounded-full" />
      <SkeletonAboutLoading />
      <SkeletonPostLoading />
    </div>
  );
};

export default MultipleSkeletons;
