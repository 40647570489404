import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
  XIcon,
  RedditShareButton,
  RedditIcon,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";
import { apiConnector } from "../../services/apiconnector";
import { RxClipboardCopy } from "react-icons/rx";
import { RiFileCopyLine } from "react-icons/ri";

const ShareProfileCard = ({ profile }) => {
  // const { referralCode } = useSelector((state) => profile.state);

  const shareUrl = `https://mirror-web-app.vercel.app/?referral=${profile.referralCode}`; // Replace with your profile URL
  const title = "Join Mirror";
  // checking Vercel Deployment

  const { token } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    email: "",
    referralCode: profile.referralCode,
  });

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      toast.success("Link copied to clipboard");
    } catch (error) {
      console.error("Failed to copy:", error);
      toast.error("Failed to copy link to clipboard");
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    console.log(formData);

    const toastId = toast.loading("Sending Invite..."); // Store toast ID

    try {
      await apiConnector(
        "POST",
        "https://mirror-express-server.onrender.com/api/v1/invite/email",
        // "http://localhost:8000/api/v1/invite/email",
        formData,
        { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
      )
        .then((res) => {
          toast.dismiss(toastId);
          toast.success("Invite Sent Successfully");
          console.log("Invite Sent Successfully, Data received:", res);
        })
        .catch((error) => {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
          // console.log(error);
          // console.log(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center p-3">
      <div className="pt-2">
        <form onSubmit={submitHandler}>
          <input
            required
            onChange={changeHandler}
            value={formData.email}
            placeholder="Email address"
            name="email"
            type="email"
            className="bg-richblack-800  text-richblack-5  p-3 w-[325px]  text-sm border rounded-md"
          />

          <div className="mt-2 flex justify-center ">
            <button className="bg-black w-[50%] h-[40px] text-white rounded-md">
              <span>Invite</span>
            </button>
          </div>
        </form>
      </div>
      <div></div>
      <div className="flex gap-3 px-6 py-4">
        {/* Share buttons */}
        <button
          onClick={copyToClipboard}
          className="bg-gray-200 p-2 rounded-full"
        >
          {/* <RxClipboardCopy /> */}
          <RiFileCopyLine size="25" />
        </button>
        <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <TwitterShareButton
          url={shareUrl}
          hashtags={["Mirror", "Workplace"]}
          title={title}
        >
          <XIcon size={32} round />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl} title={title} summary={profile.bio}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>

        <TelegramShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>

        <RedditShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className="Demo__some-network__share-button"
        >
          <RedditIcon size={32} round />
        </RedditShareButton>
      </div>
    </div>
  );
};

export default ShareProfileCard;
