import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
  XIcon,
  RedditShareButton,
  RedditIcon,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";

import { FaWhatsapp } from "react-icons/fa";
import { RiFileCopyLine } from "react-icons/ri";
import { FaL, FaLink, FaReddit, FaTelegram } from "react-icons/fa6";
import toast from "react-hot-toast";
import { ImEmbed2 } from "react-icons/im";
import { IoIosShareAlt } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";

export const ShareProfileCardNew = () => {
  const shareUrl = window.location.href;
  const title = "Mirror Profile";

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      toast.success("Link copied to clipboard");
    } catch (error) {
      console.error("Failed to copy:", error);
      toast.error("Failed to copy link to clipboard");
    }
  };

  return (
    <div>
      <div className="p-2 flex flex-col gap-5">
        <h2 className="font-bold flex items-center gap-2 text-xl">
          <span>
            <IoIosShareAlt />
          </span>
          <span>Share Profile</span>
        </h2>

        <button
          onClick={copyToClipboard}
          className="pointer flex items-center gap-2 text-base "
        >
          <span>
            <FaLink />
          </span>
          <span>Copy Link</span>
        </button>
        <hr className="mt-[-10px]" />

        <div className="flex gap-3 ">
          {/* Share buttons */}

          <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
            <div>
              <FaWhatsapp size={32} round />
              {/* <span>Whatsapp</span> */}
            </div>
          </WhatsappShareButton>
          <TwitterShareButton
            url={shareUrl}
            hashtags={["Mirror", "Workplace"]}
            title={title}
          >
            <FaXTwitter size={28} round />
          </TwitterShareButton>
          <LinkedinShareButton
            url={shareUrl}
            title={title}
            // summary={profile.bio}
          >
            <FaLinkedin size={32} round />
          </LinkedinShareButton>

          <TelegramShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <FaTelegram size={32} round />
          </TelegramShareButton>

          <RedditShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="Demo__some-network__share-button"
          >
            <FaReddit size={32} round />
          </RedditShareButton>
        </div>
      </div>
    </div>
  );
};
