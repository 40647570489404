import React, { useRef } from "react";
import { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { RxCountdownTimer } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";
import Logo from "../../../Images/Icons/MirrorNewLogo.svg";
import { sendOtp, sendOtpNew } from "../../../services/operations/authAPI";
import Popup from "reactjs-popup";
import ReCAPTCHA from "react-google-recaptcha";
import { FiX } from "react-icons/fi";
import { setSignupData, updateSignupDataOtp } from "../../../slices/authSlice";

export const VerifyOTP = (props) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { signupData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // if data is not available in the form data state
  useEffect(() => {
    if (!signupData) {
      navigate("/register/enter-email");
    }
  }, [signupData, navigate]);

  console.log("verifyOtp Form Data", signupData);
  console.log("Email", signupData.email);
  console.log("PhoneNumber", signupData.phoneNumber);

  let payload = {};

  if (signupData?.email) {
    payload = {
      email: signupData?.email,
      otp: otp,
    };
  } else if (signupData?.phoneNumber) {
    payload = {
      phoneNumber: signupData?.phoneNumber,
      otp: otp,
    };
  }

  //   console.log(payload);

  const submitHandler = async (event) => {
    event.preventDefault();

    if (!otp || otp.length < 6) {
      toast.error("Please enter a valid OTP.");
      return;
    }

    // Loading toast
    const toastId = toast.loading("Verifying OTP...");

    try {
      const res = await axios.post(
        "https://api.atmirrors.com/auth/verify-otp",
        payload
      );

      toast.dismiss(toastId);
      toast.success("OTP verified successfully.");
      console.log("OTP Verification Done. Data received:", res.data);

      dispatch(updateSignupDataOtp(otp));

      navigate("/register/enter-details");
    } catch (error) {
      toast.dismiss(toastId);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred. Please try again.");
      }

      console.log(error);
    }
  };

  const [recaptchaValue, setRecaptchaValue] = useState("");
  const captchaRef = useRef();
  const captchaHandler = (value) => {
    setRecaptchaValue(value);
    // console.log(value);
  };

  const resendOTPHandler = (event, close) => {
    event.preventDefault();
    console.log(signupData.email);
    dispatch(sendOtpNew(signupData.email, signupData.phoneNumber, navigate));
    close();
  };

  return (
    <div>
      <div className="  flex items-center flex-col justify-center w-screen h-screen">
        <div className=" flex items-center flex-col justify-center w-[325px] gap-5   ">
          <div className="flex justify-center items-center flex-col gap-8">
            <h2 className="text-gray-900  text-4xl font-semibold ">
              <img src={Logo} />
            </h2>

            <div className="flex flex-col gap-2  w-[325px] text-left">
              <span className="font-semibold text-2xl  w-[100%]   ">
                Enter Your OTP
                {/* {signupData.email} */}
              </span>
              <p className="font-normal text-sm">
                We’ve sent a 6-digit confirmation code to{" "}
                <span className="font-semibold">
                  {signupData?.email
                    ? signupData?.email
                    : signupData?.phoneNumber}{" "}
                </span>
                Make sure you enter correct code.
              </p>
            </div>
          </div>

          <div className=" ">
            <form
              className="flex flex-col items-center gap-[20px]"
              onSubmit={submitHandler}
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => (
                  <input
                    {...props}
                    placeholder="-"
                    style={{
                      boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                    }}
                    className="w-[48px]  border-0 bg-richblack-800 rounded-[0.5rem] text-richblack-5 aspect-square text-center focus:border-0 focus:outline-2 focus:outline-black"
                  />
                )}
                containerStyle={{
                  justifyContent: "space-between",
                  gap: "0 6px",
                }}
              />

              <div className="flex flex-col gap-1 w-[325px] font-normal text-sm">
                <span className=" text-gray-500 text-left  w-[325px]">
                  Haven’t recieve the verification code
                </span>

                {/* <span className="text-left underline text-sky-500">Resend</span> */}

                <Popup
                  trigger={
                    <span
                      className="text-left underline text-sky-500"
                      onClick={resendOTPHandler}
                    >
                      Resend
                    </span>
                  }
                  modal
                  closeOnEscape
                  closeOnDocumentClick
                  contentStyle={{
                    width: "330px",
                    height: "auto",
                    maxHeight: "80vh",
                    overflowY: "auto",
                    font: "inherit",
                    paddingBottom: "20px",
                  }}
                >
                  {(close) => (
                    <div>
                      <button
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                        onClick={close}
                      >
                        <FiX />
                      </button>
                      <div className="w-full flex flex-col justify-center items-center gap-5">
                        <div className="text-center mt-5 font-medium text-lg">
                          Resend OTP
                        </div>

                        <ReCAPTCHA
                          ref={captchaRef}
                          onChange={captchaHandler}
                          sitekey={process.env.REACT_APP_SITE_KEY}
                        />

                        <button
                          className=" flex justify-center items-center gap-x-3  w-[200px] border h-[50px] rounded-full text-white bg-black"
                          onClick={(event) => resendOTPHandler(event, close)}
                        >
                          <RxCountdownTimer />
                          <span className="font-medium">Resend OTP</span>
                        </button>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>

              {/* <button className="flex items-center  gap-x-2">
                <RxCountdownTimer />
                Resend OTP
              </button> */}

              {/* submit button */}
              <div className="mt-5">
                <button className="bg-black w-[325px] h-[50px] text-white rounded-full">
                  <span>Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
