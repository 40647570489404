export const privacySections = [
  {
    subheading: "What is the Privacy Policy and what does it cover?",
    content:
      "We at Mirror want you to understand what information we collect, and how we use and share it. That's why we encourage you to read our Privacy Policy. This helps you use Mirror in the way that's right for you. In the Privacy Policy, we explain how we collect, use, share, retain and transfer information.",
  },
  {
    subheading: "What information do we collect?",
    content: `
    To use our platform and services you need to have an account, and to create an account, you need to provide us certain information. Basically, certain information is necessary if you want to use many of our platform and services. In this policy, we list the kinds of information that we collect. Here are some important ones. We collect:
    <ul class="list-disc pl-7 mt-2 mb-2">
      <li>The information that you give us when you sign up for our platform and create a profile, such as your email address or phone number.</li>
      <li>What you do on our platform. This includes what you click on or upvote, your posts and photos.</li>
      <li>Who your followers are, and what they do on our platform.</li>
      <li>Information from the phone, computer, or tablet that you use our platform on, like what kind it is and what version of our app you're using.</li>
    </ul>
    The information that we collect and process about you depends on how you use our platform. When you use our platform, we collect some information about you even if you don't have an account.
  `,
  },
  {
    subheading: "Here's the information we collect:",
    content: `
    <ul class="pl-7 mt-2.5 mb-2 font-semibold">
    <li>Your activity and information that you provide</li>
    </ul>
    <ul class="pl-7 mt-2 mb-2.5">
      <p>On our platform, you can write a review for someone, browse through other people’s reviews, and interact with reviews by upvoting, downvoting or commenting on them. We call all of the things you can do on our platform "activity". We collect your activity on our platform and the information you provide such as:</p>
    </ul>
    <ul class="list-disc ml-5 pl-7 mt-2 mb-2">
      <li>Content that you create, such as reviews, comments or upvotes, downvotes.</li>
      <li>Metadata about the content. Such as the location where a review was written or the date that it was written on.</li>
      <li>Types of content, including ads, that you view or interact with, and how you interact with it.</li>
      <li>Hashtags you use.</li>
      <li>The time, frequency and duration of your activities on our platform.</li>
    </ul>
    <ul class="pl-7 mt-3 mb-2 font-semibold">
    <li>Information with special protections</li>
    </ul>
    <ul class="pl-7 mt-2 mb-2">
      <p>You might choose to provide information about your religious views, your sexual orientation, political views, health, racial or ethnic origin, philosophical beliefs or trade union membership. These and other types of information could have special protections under the laws of your jurisdiction.</p>
    </ul>
  `,
  },
  {
    subheading: "Followers",
    content: `
    <ul class="pl-7 mt-2 mb-2.5">
      <p>On our platform, you can write a review for someone, browse through other people’s reviews, and interact with reviews by upvoting, downvoting or commenting on them. We call all of the things you can do on our platform "activity". We collect your activity on our platform and the information you provide such as:</p>
    </ul>
    <ul class="pl-7 mt-2.5 mb-2 font-semibold">
    <li>Information we collect about contacts</li>
    </ul>
    <ul class="pl-7 mt-2 mb-2.5">
      <p>We also collect your contacts' information, such as their name and email address or phone number, if you choose to upload or import it from a device, such as by syncing an address book</p>
    </ul>
    <ul class="pl-7 mt-2.5 mb-2 font-semibold">
    <li>Information we collect or infer about you based on others' activity</li>
    </ul>
    <ul class="pl-7 mt-2 mb-2.5">
      <p>We collect information about you based on others' activity. For example, we collect information about you on our platform when others</p>
    </ul>
    <ul class="list-disc ml-5 pl-7 mt-2 mb-2">
      <li>Share or comment on a review you're tagged in.</li>
      <li>Upload their address book that has your contact information in it.</li>
      <li>Invite you to play a game.</li>
    </ul>
    <ul class="pl-7 mt-2.5 mb-2 font-semibold">
    <li>We also infer things about you based on others' activity. For example:</li>
    </ul>
    <ul class="pl-7 mt-2 mb-2.5">
      <p>We may suggest you to follow someone if you both appear on a contact list that someone uploads</p>
    </ul>
  `,
  },
  {
    subheading: "App, browser and device information",
    content: `
    <ul class="pl-7 mt-2 mb-2.5">
      <p>We collect and receive information from and about the different devices (computers/phones) you use and how you use them.
Device information we collect and receive includes:</p>
    </ul>
    <ul class="list-disc pl-7 mt-2 mb-2">
      <li>The device and software you're using, and other device characteristics. For eg. We collect device information such as:</li>
      <ul class="list-decimal pl-9 mt-2 mb-2">
        <li>The type of device</li>
        <li>Details about its operating system</li>
        <li>Details about its hardware and software</li>
        <li>Brand and model</li>
        <li>Battery level</li>
        <li>Signal strength</li>
        <li>Available storage</li>
        <li>Browser type</li>
        <li>App and file names, and types</li>
        <li>Plugins</li>
      </ul>
      <li>What you're doing on your device, such as whether our app is in the foreground or if your mouse is moving (which can help tell humans from bots)</li>
      <li>Identifiers that tell your device apart from other users' devices.</li>
      <li>Signals from your device. For eg. GPS, Bluetooth signals, nearby Wi-Fi access points, beacons and cell towers.</li>
      <li>Information you've shared with us through device settings, such as GPS location</li>
      <li>Information about the network that you connect your device to and your connection, including your IP address.</li>
      <li>Some location-related information, even if location services is turned off in your device settings. This includes using IP addresses to estimate your general location.</li>
    </ul>
    <ul class="ml-5 pl-9 mt-2 mb-2.5">
      <p>We use location-related information that you allow us to receive if you turn on the Location Services device setting. This includes things such as your GPS location and, depending on the operating system you're using, other device signals.</p>
<p>We also receive and use some location-related information even if location services is turned off. This includes:</p>
    </ul>
     <ul class="list-disc ml-5 pl-9 mt-2 mb-2">
      <li>IP addresses, which we use to estimate your general location. We can use IP addresses to estimate your specific location if it's necessary to protect the safety and security of you or others.</li>
      <li>Your and others' activity on our platform, such as check-ins and events</li>
      <li>Information that you give us directly, such as if you enter your current city on your profile.</li>
      <li>We use location-related information, such as your current location, where you live, the places you like to go to do the things described in the "How do we use your information?" section of the Policy, such as:</li>
      </ul>
      <ul class="list-decimal ml-5 pl-11 mt-2 mb-2">
      <li>Provide, personalise and improve our platform, including ads, for you and others.</li>
      <li>Detect suspicious activity and help keep your account secure.</li>
    </ul>
    <ul class="list-disc pl-7 mt-2 mb-2">
    <li>Information about our platform' performance on your device.</li>
    </ul>
    <ul class="list-decimal ml-5 pl-11 mt-2 mb-2">
      <li>We collect device information to prevent, diagnose, troubleshoot and fix errors and bugs. This includes how long the app was running, what model of device you were using and other performance and diagnostic information.</li>
    </ul>
    <ul class="list-disc pl-7 mt-2 mb-2">
    <li>Information from cookies and similar technologies.</li>
    </ul>
  `,
  },
  {
    subheading: "How do we use your information?",
    content: `
    <ul class=" mt-2 mb-2.5">
      <li>We use information we collect to provide a personalised experience to you on our platform along with the other purposes that we explain in detail below.</li>
      <ul class="list-disc pl-5 mt-2 mb-2">
        <li>For some of these purposes, we use information across your devices. The information that we use for these purposes is automatically processed by our systems. But in some cases, we also use manual review to access and review your information</li>
        <li>What you're doing on your device, such as whether our app is in the foreground or if your mouse is moving (which can help tell humans from bots)</li>
        <li>We also use manual review to analyse content to train our algorithms to review content the same way a person would. This improves our automatic processing, which in turn helps us improve our platform</li>
        <li>When our algorithms detect that someone might need help, a reviewer can review their post and offer support if needed.</li>
        <li>Our reviewers help us promote safety, security and integrity across our platform. For example, reviewers can look for and remove content that violates our terms and policies, and keep content that doesn't break our terms and policies available. Their work supplements our technology that detects violations.</li>
      </ul>
      <p>To use less information that's connected to individual users, in some cases we de-identify or aggregate information or anonymise it so that it no longer identifies you. We use this information in the same ways we use your information as described in this section.</p>
      <ul class="mt-2 mb-2.5 text-lg font-semibold">
        <li>To provide, personalise and improve our platform</li>
      </ul>
      <p>We use information we have to provide and improve our platform. This includes personalising features, content and recommendations, such as your Mirror feed and ads.</p>
      <ul class="ml-7 mt-2 mb-2.5 text-lg font-semibold">
        <li>How we use information to improve our platform</li>
      </ul>
      <ul class="ml-7 mt-2 mb-2.5">
        <p>We're always trying to make our platform better and create new ones with the features you want. Information we collect from you helps us learn how.We use information we collect to:</p>
      </ul>
      <ul class="list-disc ml-12 mt-2 mb-2.5">
        <li>See if a feature is working correctly</li>
        <li>Troubleshoot and fix it when it's not</li>
        <li>Try out new platform and features to see if they work</li>
        <li>Get feedback on our ideas for platform or features</li>
        <li>Conduct surveys and other research about what you like about our platform and brands, and what we can do better</li>
      </ul>
      <ul class="ml-7 mt-2 mb-2.5 text-lg font-semibold">
        <li>How we use location related information</li>
      </ul>
      <ul class="ml-7 mt-2 mb-2.5">
        <p>We use location-related information that you allow us to receive if you turn on the Location Services device setting. This includes things such as your GPS location and, depending on the operating system you're using, other device signals. We also receive and use some location-related information even if location services is turned off. This includes:</p>
      </ul>
      <ul class="list-disc ml-12 mt-2 mb-2.5">
        <li>IP addresses, which we use to estimate your general location. We can use IP addresses to estimate your specific location if it's necessary to protect the safety and security of you or others.</li>
        <li>Your and others' activity on our platform, such as sign-ins and events</li>
        <li>Information that you give us directly, such as if you enter your current city on your profile, or provide your address in Marketplace</li>
      </ul>
      <ul class="ml-7 mt-2 mb-2.5">
        <p>We use location-related information, such as your current location, where you live, the places you like to go and the businesses and people you're near, to do the things described in the "How do we use your information?" section of the Policy, such as:</p>
      </ul>
      <ul class="list-disc ml-12 mt-2 mb-2.5">
        <li>Provide, personalise and improve our Platform, for you and others.</li>
        <li>Detect suspicious activity and help keep your account secure.</li>
      </ul>
      <ul class="mt-2 mb-2.5 text-lg font-semibold">
        <li>To promote safety, security and integrity</li>
      </ul>
      <p>We use the information we collect to help protect people from harm and provide safe, secure platform.</p>
      <ul class="list-disc ml-5 mt-2 mb-2.5">
        <li>Verify accounts and activity</li>
        <li>Find and address violations of our terms or policies. </li>
        <li>Investigate suspicious activity</li>
        <li>Detect, prevent and combat harmful or unlawful behaviour</li>
        <li>Detect and prevent spam and other bad experiences </li>
        <li>Detect when someone needs help and provide support</li>
        <li>Detect and stop threats to our personnel and property</li>
        <li>Maintain the integrity of our Platform</li>
      </ul>
      <ul class="mt-2 mb-2.5 text-lg font-semibold">
        <li>To research and innovate for social good</li>
      </ul>
      <p>We use the information we collect to help protect people from harm and provide safe, secure platform.</p>
      <ul class="list-disc ml-5 mt-2 mb-2.5">
        <li>Contributing to social good and areas of public interest</li>
        <li>Advancing technology </li>
      </ul>
    </ul>
  `,
  },
];
