import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { apiConnector } from "../../../services/apiconnector";
import { useNavigate } from "react-router-dom";
import { CustomStars } from "../../NewPost/CustomStars";

export const EditPost = ({
  id,
  title,
  body,
  fetchUserData,
  closeModal,
  closeDropdown,
  fetchPostData,
  reviewedUserRating,
}) => {
  const [formData, setFormData] = useState({
    title: title,
    body: body,
    reviewedUserRating: reviewedUserRating,
  });

  const [charCount, setCharCount] = useState(title.length || 0);
  const [bodyCharCount, setBodyCharCount] = useState(body.length || 0);

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => {
      if (name === "title") {
        setCharCount(value.length);
      }
      if (name === "body") {
        setBodyCharCount(value.length);
      }
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const ratingChanged = (newRating) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      reviewedUserRating: newRating,
    }));
  };

  // console.log("token in storage", localStorage.getItem("token"));
  // console.log("User in storage", localStorage.getItem("user"));

  const navigate = useNavigate();
  const submitHandler = async (event) => {
    event.preventDefault();
    console.log(formData);

    const toastId = toast.loading("Editing Your Post...");
    try {
      await apiConnector(
        "POST",
        `https://api.atmirrors.com/posts/edit/${id}`,
        formData
      )
        .then(async (res) => {
          toast.dismiss(toastId);
          toast.success("Post Edited successfully");
          console.log("Post Edited successfully. Data received:", res);
          setFormData({
            body: "",
          });

          navigate(`/sharedPost/${id}`);
          closeModal();
          closeDropdown();
          await fetchPostData();
        })
        .catch((error) => {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="">
      <div className="flex flex-col justify-center items-center  ">
        <div className="text-center mt-0 m-5 font-medium text-lg">
          Edit Post
        </div>

        <form
          className="flex flex-col items-center gap-[20px] pb-5 "
          onSubmit={submitHandler}
        >
          <label
            htmlFor="title"
            className="w-full text-left font-medium mb-[-15px] pl-1"
          >
            Post Title
          </label>
          <div className="relative w-full">
            <input
              required
              onChange={changeHandler}
              value={formData.title}
              placeholder="Write your review title here..."
              name="title"
              id="title"
              type="text"
              maxLength="80"
              className="bg-richblack-800 text-richblack-5 p-3 w-full h-[58px] text-sm border rounded-md"
            />

            <div className="absolute bottom-2 right-2 text-xs text-gray-400">
              {charCount}/80
            </div>
          </div>
          <label
            htmlFor="body"
            className="w-full text-left font-medium mb-[-15px] pl-1"
          >
            Post Content
          </label>
          <div className="relative w-full">
            <textarea
              required
              onChange={changeHandler}
              value={formData.body}
              placeholder="Write your review here.. "
              name="body"
              type="text"
              id="body"
              maxLength="2000"
              className="bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[100px] text-sm border rounded-md"
            />
            <div className="absolute bottom-2 right-2 text-xs text-gray-400">
              {bodyCharCount}/2000
            </div>
          </div>

          <label
            htmlFor="Rating"
            className="w-full text-left font-medium mb-[-15px] pl-1"
          >
            Mirror Score
          </label>

          <div
            id="Rating"
            className="flex justify-center items-center gap-[5rem] p-3 h-[69px] border rounded-md w-full"
          >
            <div className="font-semibold text-3xl text-blue-600">
              {formData.reviewedUserRating}
            </div>

            <CustomStars
              count={5}
              value={formData.reviewedUserRating}
              onChange={ratingChanged}
              starSize={22}
            />
          </div>

          <div className="mt-5">
            <button className="bg-black w-[325px] h-[58px] text-white rounded-md">
              <span>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
