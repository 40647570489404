export const termsSections = [
  {
    content:
      "Welcome to Mirror! By using our platform, you agree to abide by the following terms and conditions. Please read them carefully.",
  },
  {
    subheading: "1. Acceptance of Terms:",
    content:
      "By accessing or using Mirror, you agree to be bound by these Terms of Service. If you do not agree with any part of these terms, you may not use our services.",
  },
  {
    subheading: "2. User Eligibility:",
    content:
      "You must be at least 16 years old to use our platform. By using Mirror, you represent and warrant that you have the right, authority, and capacity to enter into these Terms of Service.",
  },
  {
    subheading: "3. User Content:",
    content:
      "You are solely responsible for any content you post on Mirror.You agree not to post content that violates any applicable laws, infringes on any intellectual property rights, or is otherwise offensive or inappropriate. ",
  },
  {
    subheading: "4. Privacy and Data Security:",
    content:
      "We respect your privacy. Please refer to our Privacy Policy to understand how we collect, use, and protect your personal information.",
  },
  {
    subheading: "5. Prohibited Conduct:",
    content:
      "You agree not to engage in any activity that disrupts or interferes with the functionality of Mirror. You may not attempt to gain unauthorized access to our platform or user accounts.",
  },
  {
    subheading: "6. Intellectual Property:",
    content:
      "All content and materials available on Mirror are protected by intellectual property laws. You may not use, reproduce, or distribute any content without permission. ",
  },
  {
    subheading: "7. Termination of Account:",
    content:
      "We reserve the right to suspend or terminate your account at our discretion if you violate these Terms of Service. ",
  },
  {
    subheading: "8. Changes to Terms:",
    content:
      "Mirror reserves the right to update or modify these Terms of Service at any time without prior notice. It is your responsibility to review these terms periodically.",
  },
  {
    subheading: "9. Contact Information:",
    content:
      "For any questions regarding these Terms of Service, please contact us at support@atmirrors.com",
  },
  {
    content: "Thank you for using Mirror! We hope you enjoy your experience.",
  },
];
