import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: localStorage.getItem("post")
    ? JSON.parse(localStorage.getItem("post"))
    : null,
  loading: false,
};

const postSlice = createSlice({
  name: "post",
  initialState: initialState,
  reducers: {
    setPost(state, value) {
      state.user = value.payload;
    },
    setLoading(state, value) {
      state.loading = value.payload;
    },
  },
});

export const { setPost, setLoading } = postSlice.actions;
export default postSlice.reducer;
