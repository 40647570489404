import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSignupData } from "../../../slices/authSlice";
import Logo from "../../../Images/Icons/MirrorNewLogo.svg";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

export const EnterEmail = () => {
  const apiUrl =
    "https://api.atmirrors.com/auth/init-register";
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    phoneNumber: "",
    password: "",
    dateOfBirth: "",
    fullName: "",
    currentCompany: "",
    currentRole: "",
    workLocation: "",
    userImage: "",
    currentRole: "",
    otp: "",
  });

  const [shakeEmail, setShakeEmail] = useState(false);
  // const [shakePhone, setShakePhone] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);

  const [isUsingPhone, setIsUsingPhone] = useState(false);

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const toggleUsingPhone = () => {
    setIsUsingPhone(!isUsingPhone);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const toastId = toast.loading("Sending OTP...");

    // Reset shake and error states
    setShakeEmail(false);
    // setShakePhone(false);

    setErrorEmail(false);
    setErrorPhone(false);

    let hasError = false;

    if (!formData.phoneNumber && !formData.email) {
      setShakeEmail(true);
      // setShakePhone(true);
      setErrorEmail(true);
      setErrorPhone(true);
      hasError = true;
    }

    if (hasError) {
      toast.dismiss(toastId);
      toast.error("Please fill in all the required fields");
      setTimeout(() => {
        setShakeEmail(false);
        // setShakePhone(false);
      }, 500);
      return;
    }
    try {
      const res = await axios.post(apiUrl, {
        ...(isUsingPhone
          ? { phoneNumber: formData.phoneNumber }
          : { email: formData.email }),
      });

      toast.dismiss(toastId);
      console.log(res);
      toast.success(res.data.message);
      navigate("/register/verify-otp");
      // console.log(formData);
      dispatch(setSignupData(formData));
    } catch (error) {
      toast.dismiss(toastId);
      toast.error(error.response.data.message);
      console.log(error);
      setShakeEmail(true);
      setTimeout(() => {
        setShakeEmail(false);
      }, 500);
    }
  };

  return (
    <div className="flex items-center flex-col justify-center w-screen  h-screen ">
      <div className="flex items-center flex-col justify-center  w-[325px] h-[85%]  ">
        <div className="flex flex-col gap-4">
          <h2 className="text-gray-900 text-center text-4xl font-semibold">
            <img src={Logo} alt="Logo" />
          </h2>
          <span className="font-normal text-sm text-gray-500">
            Create New Account
          </span>
        </div>

        <div className="mt-10 h-full">
          <form
            className="flex flex-col h-full items-center justify-between"
            onSubmit={submitHandler}
          >
            <div className="flex flex-col items-right text-right">
              {isUsingPhone ? (
                <div className="relative">
                  <PhoneInput
                    flags={flags}
                    defaultCountry="IN"
                    placeholder=""
                    value={formData.phoneNumber}
                    onChange={(value) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        phoneNumber: value,
                      }))
                    }
                    className={`bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[69px] text-sm border rounded-md`}
                    //   shakePhone ? "animate-shake" : ""
                    // } ${errorPhone ? "border-red-600" : ""}`}
                  />
                </div>
              ) : (
                <div className="relative">
                  <input
                    onChange={changeHandler}
                    value={formData.email}
                    placeholder=""
                    name="email"
                    type="email"
                    className={`bg-richblack-800 text-richblack-5 p-3 w-[325px] h-[69px] text-sm border rounded-md ${
                      shakeEmail ? "animate-shake" : ""
                    } ${errorEmail ? "border-red-600" : ""}`}
                  />
                  <label
                    className={`absolute top-9 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                      formData.email && "text-xs text-gray-700 -translate-y-10 "
                    }`}
                    style={{
                      backgroundColor: formData.email ? "#fff" : "transparent",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    Email address
                  </label>
                </div>
              )}
              <span
                className="text-xs font-medium text-right ml-2 mt-1 text-gray-600 cursor-pointer underline hover:text-blue-600 "
                onClick={toggleUsingPhone}
              >
                {isUsingPhone ? "Use email instead" : "Use phone instead"}
              </span>
            </div>

            <div className="">
              <div className="flex flex-col gap-3 items-center justify-center">
                <button className="bg-black w-[325px] h-[50px] text-white rounded-full">
                  <span>Next</span>
                </button>
              </div>

              <div className="mt-3 text-sm">
                <span className="text-gray-500">Already have an account? </span>
                <span
                  className="font-semibold underline cursor-pointer"
                  onClick={() => {
                    navigate("/auth/login");
                  }}
                >
                  Login
                </span>
              </div>
            </div>
          </form>
          {/* <div className="flex mt-5 items-end justify-center ">
            <span className="text-sm font-small text-gray-500 pb-3">
              By signing in, you agree to our <br />
              <Link to="/terms">
                <span className="text-sky-500"> Terms,</span> and{" "}
                <span className="text-sky-500">Privacy Policy</span>.
              </Link>
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};
