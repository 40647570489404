import React, { useState, useEffect, useRef } from "react";
import image from "../Images/profile-picture-3.jpg";
import { FiEdit } from "react-icons/fi";
import { BsFillPatchCheckFill } from "react-icons/bs";
import Reviews from "../Components/UserProfile/Reviews";
import { useSelector } from "react-redux";
import { apiConnector } from "../services/apiconnector";
import Posts from "../Components/UserProfile/Posts";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { FiX } from "react-icons/fi";
import { IoShareOutline } from "react-icons/io5";
import ShareProfileCard from "../Components/UserProfile/ShareProfileCard";
import { FaStar } from "react-icons/fa";
import { PencilIcon } from "@heroicons/react/24/outline";
import Modal from "../Components/UserProfileByID/Modal";
import { CgProfile } from "react-icons/cg";

export const UserProfile = () => {
  const navigate = useNavigate();
  const reviewsData = [
    {
      title: "Exceptional Team Player",
      description:
        "Always willing to lend a helping hand and collaborate with the team. Their positive attitude and dedication make them a valuable asset to any project.",
      name: "Emily Johnson",
      position: "Project Manager",
      avatar:
        "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png",
    },
    {
      title: "Innovative Problem Solver",
      description:
        "Consistently comes up with creative solutions to complex problems. Their ability to think outside the box and find innovative approaches is commendable.",
      name: "Alex Rodriguez",
      position: "Software Developer",
      avatar:
        "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png",
    },
    {
      title: "Dependable and Reliable",
      description:
        "A coworker you can always count on. They meet deadlines with precision and produce high-quality work. Dependability is their middle name!",
      name: "Sara Patel",
      position: "QA Engineer",
      avatar:
        "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png",
    },
    {
      title: "Excellent Communication Skills",
      description:
        "Their ability to communicate effectively is outstanding. Whether its in meetings, emails, or documentation, they ensure everyone is on the same page.",
      name: "Michael Chen",
      position: "Product Manager",
      avatar:
        "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/joseph-mcfall.png",
    },
  ];

  //adding prev commit (f10f48a) ref
  const [data, setData] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const currentCompany = "current Company";
  const bio = "About section";
  const [selectedSection, setSelectedSection] = useState("reviews"); // State to track selected section

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const responseData = await apiConnector(
        "GET",
        "https://mirror-express-server.onrender.com/api/v1/getUserDetails",
        // "http://localhost:8000/api/v1/getAllUsers",
        null,
        { Authorization: `Bearer ${token}` }
      );
      console.log("Profile Page data Received", responseData);

      setData(responseData.data.userDetails);
      // console.log(data.posts);
      // console.log(data.reviews);
      // console.log(data.additionalDetails);
    } catch (error) {
      console.log("error", error);
      setError("Error fetching user data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  const closeModal = () => {};

  useEffect(() => {
    fetchUserData();
  }, []);

  //Edit Form
  const [formData, setFormData] = useState({
    currentRole: "",
    currentCompany: "",
    about: "",
  });

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  // console.log("token in storage", localStorage.getItem("token"));
  // console.log("User in storage", localStorage.getItem("user"));

  const submitHandler = async (event) => {
    event.preventDefault();
    console.log(formData);

    const toastId = toast.loading("Updating Your Profile..."); // Store toast ID

    try {
      await apiConnector(
        "PUT",
        "https://mirror-express-server.onrender.com/api/v1/updateProfile",
        // "http://localhost:8000/api/v1/updateProfile",
        formData,
        { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
      )
        .then(async (res) => {
          toast.dismiss(toastId);
          toast.success("Profile Updated successfully");
          console.log("Profile Updated successfully. Data received:", res);
          setFormData({
            currentRole: "",
            currentCompany: "",
            about: "",
          });

          closeModal();
          await fetchUserData();
        })
        .catch((error) => {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const avatarUrl = useRef(image);
  const updateAvatar = (imgSrc) => {
    avatarUrl.current = imgSrc;
  };

  return (
    <div className="w-screen h-screen pt-[5.5rem] pb-0 p-2">
      {isLoading ? (
        <div className="flex h-full w-full flex-col gap-4 items-center justify-center">
          <div class="spinner"></div>
          <div className="pb-[100px] font-medium ">Loading</div>
        </div>
      ) : error ? (
        <div>{error}</div>
      ) : data ? (
        <div>
          <div className=" relative bg-gray-200 h-[194px] overflow-hidden"></div>

          <img
            className="absolute lg:left-[50px] left-[25px] lg:top-[160px] top-[180px] lg:w-[160px] lg:h-[160px] w-[140px] h-[140px] rounded-full shadow-lg"
            src={data.image || image}
            alt="Profile image"
          />

          <div className="flex justify-end pr-6 pt-2 text-xl gap-2 text-black">
            <button
              className=" pt-0.5"
              title="Change photo"
              onClick={() => setModalOpen(true)}
            >
              <CgProfile size="22" className="" />
            </button>

            {modalOpen && (
              <Modal
                updateAvatar={updateAvatar}
                closeModal={() => setModalOpen(false)}
                toRedirect={`/user-profile/${data._id}`}
              />
            )}
            <Popup
              trigger={
                <button>
                  <IoShareOutline size="22" />
                </button>
              }
              closeOnEscape
              closeOnDocumentClick
              modal
              contentStyle={{
                width: "365px",
                height: "auto",
                maxHeight: "80vh",
                overflowY: "auto",
                font: "inherit",
              }}
            >
              {(close) => (
                <div>
                  <button
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                    onClick={close}
                  >
                    <FiX />
                  </button>
                  <ShareProfileCard profile={data} />
                </div>
              )}
            </Popup>

            <Popup
              trigger={
                <button>
                  <FiEdit />
                </button>
              }
              modal
              contentStyle={{
                width: "365px",
                height: "auto",
                maxHeight: "80vh",
                overflowY: "auto",
                font: "inherit",
              }}
              // position="left center"
            >
              <div className="w-full">
                <div className="text-center m-5 font-medium text-lg">
                  Edit Profile
                </div>

                <form
                  className="flex flex-col items-center gap-[20px] p-5 "
                  onSubmit={submitHandler}
                >
                  <input
                    onChange={changeHandler}
                    value={formData.currentRole}
                    placeholder="Current Role"
                    name="currentRole"
                    type="text"
                    className="bg-richblack-800  text-richblack-5  p-3 w-[325px]  h-[59px] lg:h-[69px] text-sm border rounded-md"
                  />
                  <input
                    onChange={changeHandler}
                    value={formData.currentCompany}
                    placeholder="Current Company"
                    name="currentCompany"
                    type="text"
                    className="p-3 text-sm w-[325px] h-[59px] lg:h-[69px] border rounded-md "
                  />
                  <textarea
                    onChange={changeHandler}
                    value={formData.about}
                    placeholder="About"
                    name="about"
                    type="text"
                    className="p-3 text-sm w-[325px] h-[59px] lg:h-[69px] border rounded-md "
                  />

                  <div className="mt-5">
                    <button className="bg-black w-[325px] h-[58px] text-white rounded-md">
                      <span>Submit</span>
                    </button>
                  </div>
                </form>
              </div>
            </Popup>
            {/* <FiEdit /> */}
          </div>
          <div className="flex flex-col text-left lg:pl-10 lg:pr-11 pl-2 pr-5 absolute top-[340px] w-full lg:w-[calc(100% - 60px)]">
            <div className="flex items-center gap-3 relative ">
              <span className="font-semibold text-xl">{data.fullName}</span>
              <BsFillPatchCheckFill className="absolute text-medium left-[140px] top-[6px]" />
            </div>
            <span className="font-medium text-gray-600">
              {data.additionalDetails.currentRole} @
              {data.additionalDetails.currentCompany || currentCompany}
            </span>
            <span className="pt-1 font-small lg:text-sm text-xs text-gray-500">
              {data.additionalDetails.about || bio}
            </span>

            <div className="flex justify-center items-center gap-2 bg-gray-200 h-[59px] mt-2">
              <span className="text-lg font-semibold">
                {data.averageRating}
                {/* {localStorage.getItem("token")}
                {localStorage.getItem("user")} */}
              </span>
              <FaStar size="15" />
              <span className="text-xs">({data.reviews.length} Reviews)</span>
            </div>

            {/* <div className="m-5 flex items-center lg:justify-center lg:gap-5  justify-between pl-5 pr-[50px] font-medium text-md">
              <div>Reviews</div>
              <div>Posts</div>
            </div>

            <div className="m-5 ml-0 font-semibold">Top Reviews</div>
            <div className="mb-[120px]">
              <Reviews reviews={data.reviews} />
             
            </div> */}

            <div className="m-5 flex items-center lg:justify-center lg:gap-5  justify-between pl-5 pr-[50px] font-medium text-md">
              {/* Update the onClick handlers to update selectedSection */}
              <div
                onClick={() => setSelectedSection("reviews")}
                className={`  cursor-pointer ${
                  selectedSection === "reviews"
                    ? "border-b-2 border-gray-900"
                    : ""
                }`}
              >
                Reviews
              </div>
              <div
                onClick={() => setSelectedSection("posts")}
                className={`cursor-pointer ${
                  selectedSection === "posts"
                    ? "border-b-2 border-gray-900"
                    : ""
                }`}
              >
                Posts
              </div>
            </div>

            {/* <div className="m-5 ml-0 font-semibold">
              {selectedSection === "reviews" ? "Top Reviews" : "Top Posts"}
            </div> */}
            <div className="mb-[120px]">
              {/* Render Reviews or Posts component based on selectedSection */}
              {selectedSection === "reviews" ? (
                <Reviews reviews={data.reviews} />
              ) : (
                <Posts posts={data.posts} />
                // <Reviews reviews={data.posts} />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
