import React from "react";
import { IoPauseSharp, IoPlaySharp } from "react-icons/io5";
import toast from "react-hot-toast";

export const PauseProfile = ({
  showModal,
  setShowModal,
  setIsPaused,
  isPaused,
}) => {
  const handleYes = () => {
    setIsPaused((prev) => !prev);
    setShowModal(false);
    toast.success(`Profile ${isPaused ? "resumed" : "paused"} successfully`);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div>
      {showModal && (
        <div className="fixed z-10 inset-0 flex items-center justify-center bg-gray-700 bg-opacity-40">
          <div className="flex items-center justify-center">
            <div className="relative bg-white rounded-lg w-[300px]  px-7 py-3 shadow-md sm:w-auto">
              <div className="flex felx-row gap-2">
                {isPaused ? (
                  <IoPlaySharp className="absolute top-3 left-4 mt-1 w-5 h-5" />
                ) : (
                  <IoPauseSharp className="absolute top-3 left-4 mt-1 w-5 h-5" />
                )}
                <h2 className="text-xl text-left font-semibold mb-6 pl-4">
                  {isPaused ? "Resume" : "Pause"}
                </h2>
              </div>
              <p className="text-lg sm:text-md mb-8 font-medium">
                Are you sure you want {isPaused ? "resume" : "pause"} your
                Profile
              </p>
              <div className="flex flex-col items-center gap-3 mb-2">
                <button
                  className="bg-white text-lg font-medium text-black border border-black rounded-full w-[130px] py-2 transition-colors duration-300 hover:bg-black hover:text-white"
                  onClick={handleYes}
                >
                  Yes
                </button>
                <button
                  className="text-black underline hover:text-blue-900 hover:underline"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
