import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { apiConnector } from "../../services/apiconnector";
import { Link, useNavigate } from "react-router-dom";
import {
  StandaloneSearchBox,
  LoadScript,
  Loader,
} from "@react-google-maps/api";
import Geolocation from "../../Geolocation";
import Logo from "../../Images/Icons/MirrorNewLogo.svg";

export const EditProfile = () => {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    currentRole: "",
    currentCompany: "",
    location: "",
  });

  const handleLocationChange = (location) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: location,
    }));
  };

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const inputRef = useRef();
  const libraries = ["places"];

  const handlePlaceChanges = () => {
    const places = inputRef.current?.getPlaces();

    if (places && places.length > 0) {
      const place = places[0];
      console.log(place.formatted_address);
      setFormData((prevFormData) => ({
        ...prevFormData,
        location: place.formatted_address,
      }));
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
    } else {
      console.log("No places found.");
    }
  };

  const [googleScriptLoaded, setGoogleScriptLoaded] = useState(false);
  useEffect(() => {
    if (!googleScriptLoaded) {
      setGoogleScriptLoaded(true);
    }
  }, [googleScriptLoaded]);

  const submitHandler = async (event) => {
    event.preventDefault();
    console.log(formData);

    const toastId = toast.loading("Updating Your Profile..."); // Store toast ID

    try {
      await apiConnector(
        "PUT",
        "https://mirror-express-server.onrender.com/api/v1/updateProfile",
        // "http://localhost:8000/api/v1/updateProfile",
        formData,
        { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
      )
        .then((res) => {
          toast.dismiss(toastId);
          toast.success("Profile Updated successfully");
          console.log("Profile Updated successfully. Data received:", res);
          // navigate("/profile");
          navigate("profileImage");
        })
        .catch((error) => {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
          // console.log(error);
          // console.log(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen gap-4">
      <div className=" flex items-center flex-col justify-center w-[325px] lg:mb-0 mb-[14rem] ">
        <div className="flex flex-col gap-2">
          <h2 className="text-gray-900 text-center text-4xl font-semibold ">
            <img src={Logo} />
          </h2>
        </div>

        <div className="mt-12  w-full text-left">
          <span className="font-semibold text-3xl   ">Add to your profile</span>
        </div>

        <div className="mt-6">
          <Geolocation onLocationChange={handleLocationChange} />
          <form
            className="flex flex-col items-center justify-center gap-4"
            onSubmit={submitHandler}
          >
            <div className="relative">
              <input
                onChange={changeHandler}
                value={formData.currentRole}
                placeholder=""
                name="currentRole"
                type="text"
                className={`p-3 text-sm w-[325px] h-[48px] border rounded-md ${
                  formData.currentRole && "not-empty"
                }`}
              />
              <label
                className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                  formData.currentRole && "text-xs text-gray-700 -translate-y-8"
                }`}
                style={{
                  backgroundColor: formData.currentRole
                    ? "#fff"
                    : "transparent",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                Current Role
              </label>
            </div>
            <div className="relative">
              <input
                onChange={changeHandler}
                value={formData.currentCompany}
                placeholder=""
                name="currentCompany"
                type="text"
                className={`p-3 text-sm w-[325px] h-[48px] border rounded-md ${
                  formData.currentCompany && "not-empty"
                }`}
              />
              <label
                className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                  formData.currentCompany &&
                  "text-xs text-gray-700 -translate-y-8"
                }`}
                style={{
                  backgroundColor: formData.currentCompany
                    ? "#fff"
                    : "transparent",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                Current Company
              </label>
            </div>

            <div>
              {googleScriptLoaded && (
                <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                  libraries={libraries}
                >
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputRef.current = ref)}
                    onPlacesChanged={handlePlaceChanges}
                  >
                    <div className="relative">
                      <input
                        required
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={changeHandler}
                        placeholder=""
                        className={`p-3 text-sm w-[325px] h-[48px] border rounded-md ${
                          formData.location && "not-empty"
                        }`}
                      />
                      <label
                        className={`absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                          formData.location &&
                          "text-xs text-gray-700 -translate-y-8"
                        }`}
                        style={{
                          backgroundColor: formData.location
                            ? "#fff"
                            : "transparent",
                          paddingLeft: "4px",
                          paddingRight: "4px",
                        }}
                      >
                        Enter Location
                      </label>
                    </div>
                  </StandaloneSearchBox>
                </LoadScript>
              )}
            </div>

            {/* submit button */}
            <div className="flex flex-col gap-2 mt-10">
              <button className="bg-black w-[325px] h-[50px] text-white rounded-full">
                <span>Submit</span>
              </button>

              <Link to="profileImage">
                <span className="text-sm font-medium underline text-gray-600">
                  Skip for now
                </span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
