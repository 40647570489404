export const ActionModal = ({
  ModalTitle,
  ModalBodyContent,
  close,
  cancelButton,
  ActionButtonValue,
  ActionButtonLogic,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-xl shadow-lg lg:w-full lg:max-w-sm w-[90%] p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          {ModalTitle}
        </h3>
        <p className="text-sm text-gray-500 mb-4">{ModalBodyContent}</p>

        <div className="flex justify-end space-x-2">
          <button
            onClick={close}
            className="text-gray-500 hover:text-gray-700 hover:bg-gray-100 px-4 py-2 rounded-md"
          >
            {cancelButton ? cancelButton : "Cancel"}
          </button>
          <button
            onClick={ActionButtonLogic}
            className="bg-gray-900 text-white hover:bg-black px-4 py-2 rounded-md"
          >
            {ActionButtonValue}
          </button>
        </div>
      </div>
    </div>
  );
};
