import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AltImage from "../../Images/profile-picture-3.jpg";
import Popup from "reactjs-popup";
import { EditComment } from "./EditComment";
import { useSelector } from "react-redux";
import { IoCloseCircleOutline, IoSend } from "react-icons/io5";
import CommentIcon from "../../Images/Icons/Comment.svg";
import { MdOutlineQuickreply } from "react-icons/md";
import {
  BiDownvote,
  BiSolidDownvote,
  BiSolidUpvote,
  BiUpvote,
} from "react-icons/bi";
import { CommentReplyCard } from "./CommentReplyCard";
import toast from "react-hot-toast";
import { PiShareFatLight } from "react-icons/pi";
import { apiConnector } from "../../services/apiconnector";

export const CommentCardNew = ({
  user,
  createdAt,
  body,
  id,
  fetchUserData,
  hasUserVoted,
  voteType,
  upvoteCount,
  downVoteCount,
}) => {
  const [formattedDate, setFormattedTime] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const closeModal = () => {};

  const backupDate = "2024-01-01T00:00:00Z";
  const commentDate = createdAt || backupDate;

  const ISTCreatedAt = new Date(commentDate).toLocaleDateString("en-IN", {
    timeZone: "Asia/Kolkata",
  });

  const userProfile = useSelector((state) => state.profile.user);
  // console.log("User in the commentCardNew", userProfile.username);
  // console.log("prop User in the commentCardNew", user);

  const canEditAndRemove = user.username === userProfile.username;

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const updateTimeDifference =
        new Date(currentTime) - new Date(commentDate);

      const secondsDifference = Math.floor(updateTimeDifference / 1000);
      const minutesDifference = Math.floor(updateTimeDifference / (1000 * 60));
      const hoursDifference = Math.floor(
        updateTimeDifference / (1000 * 60 * 60)
      );
      const daysDifference = Math.floor(
        updateTimeDifference / (1000 * 60 * 60 * 24)
      );
      const weeksDifference = Math.floor(daysDifference / 7);

      const formatNumber = (number) => String(number).padStart(2, "0");

      if (weeksDifference >= 4) {
        setFormattedTime(formattedDate(commentDate));
      } else if (weeksDifference >= 1) {
        setFormattedTime(`${formatNumber(weeksDifference)} w`);
      } else if (daysDifference >= 1) {
        setFormattedTime(
          `${formatNumber(daysDifference)} d${
            daysDifference > 1 ? "ay's" : ""
          } ago`
        );
      } else if (hoursDifference >= 1) {
        setFormattedTime(
          `${formatNumber(hoursDifference)} hr${
            hoursDifference > 1 ? "s" : ""
          } ago`
        );
      } else if (minutesDifference >= 1) {
        setFormattedTime(
          `${formatNumber(minutesDifference)} min${
            minutesDifference > 1 ? "s" : ""
          } ago`
        );
      } else {
        setFormattedTime(`a few seconds ago`);
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup
  }, [commentDate]);

  const [upvotes, setUpVotes] = useState(upvoteCount);
  const [downVotes, setDownVotes] = useState(downVoteCount);
  const [voteStatus, setVoteStatus] = useState(null);

  useEffect(() => {
    // const userUpvoted = upvoteCount.some((vote) => vote.user === userId);
    // const userDownvoted = downVoteCount.some((vote) => vote.user === userId);
    const userUpvoted = hasUserVoted && voteType === "upvote";
    const userDownvoted = hasUserVoted && voteType === "downvote";

    if (userUpvoted) {
      setVoteStatus("upvoted");
    } else if (userDownvoted) {
      setVoteStatus("downvoted");
    } else {
      setVoteStatus(null);
    }
  }, [upvoteCount, downVoteCount]);

  const upvote = async () => {
    if (voteStatus === "upvoted") {
      // User wants to remove their upvote
      try {
        const response = await apiConnector(
          "POST",
          "https://api.atmirrors.com/vote",
          { votableId: id, voteType: "upvote", votableType: "comment" }
        );

        setUpVotes(upvotes - 1);
        setVoteStatus(null);
        console.log(response);
      } catch (error) {
        console.error("Error removing upvote:", error);
      }
    } else {
      // User wants to upvote
      try {
        const response = await apiConnector(
          "POST",
          "https://api.atmirrors.com/vote",
          { votableId: id, voteType: "upvote", votableType: "comment" }
        );

        setUpVotes(upvotes + 1);
        if (voteStatus === "downvoted") {
          setDownVotes(downVotes - 1);
        }
        setVoteStatus("upvoted");
        console.log(response);
      } catch (error) {
        console.error("Error upvoting :", error);
      }
    }
  };

  const downVote = async () => {
    if (voteStatus === "downvoted") {
      // User wants to remove their downvote
      try {
        const response = await apiConnector(
          "POST",
          "https://api.atmirrors.com/vote",
          { votableId: id, voteType: "downvote", votableType: "comment" }
        );
        setDownVotes(downVotes - 1);
        setVoteStatus(null);
        console.log(response);
      } catch (error) {
        console.error("Error removing downvote:", error);
      }
    } else {
      // User wants to downvote
      try {
        const response = await apiConnector(
          "POST",
          "https://api.atmirrors.com/vote",
          { votableId: id, voteType: "downvote", votableType: "comment" }
        );

        setDownVotes(downVotes + 1);
        if (voteStatus === "upvoted") {
          setUpVotes(upvotes - 1);
        }
        setVoteStatus("downvoted");
        console.log(response);
      } catch (error) {
        console.error("Error downvoting :", error);
      }
    }
  };

  const [formData, setFormData] = useState({
    commentId: "",
    replyBody: "",
  });

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const [shakeReplyInput, setShakeReplyInput] = useState(false);
  const [errorReplyInput, setErrorReplyInput] = useState(false);

  const replyRef = useRef();

  const submitHandler = async (event) => {
    event.preventDefault();

    const toastId = toast.loading("Sending Reply...");

    let hasError = false;

    if (!formData.replyBody) {
      setShakeReplyInput(true);
      setErrorReplyInput(true);
      hasError = true;
    }

    if (hasError) {
      toast.dismiss(toastId);
      toast.error("Please fill in all the required fields");
      setTimeout(() => {
        setShakeReplyInput(false);
        // setErrorReplyInput(false);
      }, 500);
      return;
    }

    console.log(formData);
    toast.dismiss(toastId);
    replyRef.current.reset();
    toast.success("Reply Added Successfully");

    setErrorReplyInput(false);
  };

  const [showCommentReplies, setShowCommentReplies] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(false);

  const repliesHandler = () => setShowCommentReplies(!showCommentReplies);
  const replyButtonHandler = () => {
    setShowReplyInput(!showReplyInput);
  };

  const commentCardClickHandler = () => {
    setShowReplyInput(!showReplyInput);
    setShowCommentReplies(!showCommentReplies);
  };

  return (
    <div className="text-left max-w-3xl mx-auto relative">
      <div className="p-3 pb-2  text-base bg-white border-t border-gray-200">
        <footer
          className="flex justify-between items-center mb-2"
          onClick={commentCardClickHandler}
        >
          <div className="flex items-center ">
            <p className="inline-flex items-center mr-3 font-bold text-gray-900 ">
              <img
                className="mr-2 w-6 h-6 rounded-full"
                src={user?.userImage || AltImage}
                alt={user.fullName}
              />
              {user.fullName}
            </p>
            <p className="text-xs  text-gray-600">{formattedDate}</p>
          </div>
          <div className="relative">
            <button
              onClick={toggleDropdown}
              className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
              type="button"
              aria-expanded={dropdownVisible}
              aria-controls="dropdownComment"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 3"
              >
                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
              </svg>
              <span className="sr-only">Comment settings</span>
            </button>
            {dropdownVisible && (
              <div
                id="dropdownComment"
                className="absolute right-0 mt-2 z-10 w-36 bg-white rounded divide-y divide-gray-100 shadow"
              >
                <ul
                  className="py-1 text-sm text-gray-700"
                  aria-labelledby="dropdownMenuIconHorizontalButton"
                >
                  {canEditAndRemove && (
                    <li>
                      <div className="block py-2 px-4 hover:bg-gray-100">
                        <Popup
                          trigger={<button>Edit</button>}
                          modal
                          contentStyle={{
                            width: "365px",
                            height: "auto",
                            maxHeight: "80vh",
                            overflowY: "auto",
                          }}
                        >
                          <EditComment
                            id={id}
                            body={body}
                            fetchUserData={fetchUserData}
                            closeModal={closeModal}
                            closeDropdown={closeDropdown}
                          />
                        </Popup>
                      </div>
                    </li>
                  )}
                  {canEditAndRemove && (
                    <li>
                      <Link
                        to="#"
                        className="block py-2 px-4 hover:bg-gray-100"
                        onClick={closeDropdown}
                      >
                        Remove
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      to="#"
                      className="block py-2 px-4 hover:bg-gray-100"
                      onClick={closeDropdown}
                    >
                      Report
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </footer>
        <p className=" pl-8 text-sm" onClick={commentCardClickHandler}>
          {body}
        </p>
        <div className="flex items-center justify-between space-x-4 ml-2">
          <div className="flex justify-between items-center h-[50px] ml-5 mr-5">
            <div className="flex  gap-3 ">
              <button
                className="flex gap-1"
                onClick={() => {
                  upvote();
                }}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <span className="pt-0.5">
                  {voteStatus === "upvoted" ? (
                    <BiSolidUpvote size={14} color="#3468C0" />
                  ) : (
                    <BiUpvote size={14} />
                  )}
                </span>

                <span className="w-[15px] text-sm">{upvotes}</span>
              </button>

              <button
                onClick={() => {
                  downVote();
                }}
                onMouseDown={(e) => e.stopPropagation()}
                className="flex gap-1"
              >
                <span className="pt-0.5">
                  {voteStatus === "downvoted" ? (
                    <BiSolidDownvote color="#EA4335" size={15} />
                  ) : (
                    <BiDownvote size={15} />
                  )}
                </span>
                <span className="w-[15px] text-sm">{downVotes}</span>
              </button>

              {/* <button onClick={replyButtonHandler}>
                <MdOutlineQuickreply />
              </button> */}

              {/* <button className="ml-1">
                <span className="">
                  <PiShareFatLight />
                </span>
              </button> */}

              {/* <button
              className="mb-1 flex items-center justify-center"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="pt-1">
                <MdOutlineSaveAlt size={18} />
              </span>
            </button> */}
            </div>
          </div>

          {/* <div className="">
            <button
              className="mr-2 text-blue-400 text-xs hover:underline  hover:font-semibold "
              onClick={repliesHandler}
            >
              <span>View 2 Replies</span>
            </button>
          </div> */}
        </div>

        {/* {showReplyInput && (
          <div className="flex items-center justify-center  ml-6 mt-2 mb-3">
            <img
              className="mr-2 w-8 h-8 rounded-full"
              src={user?.userImage || AltImage}
              alt={user.fullName}
            />
            <form
              className="w-full flex gap-5"
              onSubmit={submitHandler}
              ref={replyRef}
            >
              <input
                onChange={changeHandler}
                value={formData.replyBody}
                placeholder={`Reply to @${
                  user.fullName.split(" ")[0]
                }'s comment `}
                name="replyBody"
                type="text"
                className={`bg-richblack-800  text-richblack-5  p-3 w-[90%] lg:text-sm h-[40px] text-xs border rounded-md ${
                  shakeReplyInput ? "animate-shake" : ""
                } ${errorReplyInput ? "border-red-600" : ""}`}
              />

              <div className=" w-[20%]">
                <button className=" w-[50%] h-[40px] rounded-md">
                  <span className="flex justify-center">
                    <IoSend />
                  </span>
                </button>
              </div>
            </form>
          </div>
        )} */}

        {/* {showCommentReplies && (
          <div className="mt-6">
            <CommentReplyCard
              key={1}
              user={user}
              createdAt={createdAt}
              body={body}
              id={id}
              fetchUserData={fetchUserData}
              upvoteCount={5}
              downVoteCount={2}
            />
            <CommentReplyCard
              key={2}
              user={user}
              createdAt={createdAt}
              body={body}
              id={id}
              fetchUserData={fetchUserData}
              upvoteCount={5}
              downVoteCount={2}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};
