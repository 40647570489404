import React, { useEffect, useState } from "react";
import { formattedDate } from "../../utils/dateFormatter";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  removeNotification,
  setNotificationOpenedState,
} from "../../slices/notificationSlice";
export const NotificationCard = ({
  key,
  title,
  body,
  image,
  timestamp,
  messageId,
  isNotificationOpened,
}) => {
  const [formattedTime, setFormattedTime] = useState("");

  //formatted time logic
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const updateTimeDifference = new Date(currentTime) - new Date(timestamp);

      const secondsDifference = Math.floor(updateTimeDifference / 1000);
      const minutesDifference = Math.floor(updateTimeDifference / (1000 * 60));
      const hoursDifference = Math.floor(
        updateTimeDifference / (1000 * 60 * 60)
      );
      const daysDifference = Math.floor(
        updateTimeDifference / (1000 * 60 * 60 * 24)
      );
      const weeksDifference = Math.floor(daysDifference / 7);

      const formatNumber = (number) => String(number).padStart(2, "0");

      if (weeksDifference >= 4) {
        setFormattedTime(formattedDate(timestamp));
      } else if (weeksDifference >= 1) {
        setFormattedTime(
          `${formatNumber(weeksDifference)} week${
            weeksDifference > 1 ? "s" : ""
          } ago`
        );
      } else if (daysDifference >= 1) {
        setFormattedTime(
          `${formatNumber(daysDifference)} day${
            daysDifference > 1 ? "s" : ""
          } ago`
        );
      } else if (hoursDifference >= 1) {
        setFormattedTime(
          `${formatNumber(hoursDifference)} hour${
            hoursDifference > 1 ? "s" : ""
          } ago`
        );
      } else if (minutesDifference >= 1) {
        setFormattedTime(
          `${formatNumber(minutesDifference)} minute${
            minutesDifference > 1 ? "s" : ""
          } ago`
        );
      } else {
        setFormattedTime(
          `${secondsDifference} second${secondsDifference > 1 ? "s" : ""} ago`
        );
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup
  }, [timestamp]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notificationClickHandler = () => {
    dispatch(
      setNotificationOpenedState({ messageId: messageId, isOpened: true })
    );
    navigate("/dashboard");
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  // const [isCardClicked, setIsCardClicked] = useState(isNotificationOpened);

  // console.log("messageID", messageId, "isOpened", isNotificationOpened);
  // console.log()

  return (
    <div
      className={`p-2 mt-2 ml-1 mr-1 lg:w-full flex items-center justify-between rounded-xl
      ${isNotificationOpened ? "bg-white" : "bg-blue-50"} 
      border-b-2 cursor-pointer`}
    >
      <div className="ml-[-1px] mr-1">
        <img
          className=" hover:border-black lg:w-[30px] lg:h-[30px] w-[25px] h-[25px] hover:shadow-lg hover:border  rounded-full"
          src={image}
        />
      </div>
      <div
        className="flex flex-col items-start"
        onClick={notificationClickHandler}
      >
        <h2 className="font-medium text-sm">{title}</h2>
        <p className="font-normal text-xs">{body}</p>
        <span className="font-normal text-xs text-gray-400">
          {formattedTime}
        </span>
      </div>
      <div>
        <div className="flex justify-center items-start  gap-2">
          <div className="relative">
            <button
              onClick={() => {
                toggleDropdown();
              }}
              onMouseDown={(e) => e.stopPropagation()}
              className="inline-flex items-center p-1 text-sm font-medium text-center text-gray-500  rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
              type="button"
              aria-expanded={dropdownVisible}
              aria-controls="dropdownComment"
            >
              <BsThreeDotsVertical color="black" size={15} />
              <span className="sr-only">Comment settings</span>
            </button>
            {dropdownVisible && (
              <div
                id="dropdownComment"
                className="absolute right-0 mt-2 z-10 w-20 bg-white rounded divide-y divide-gray-100 shadow"
              >
                <ul
                  className="py-1 text-sm text-gray-700"
                  aria-labelledby="dropdownMenuIconHorizontalButton"
                >
                  <li>
                    <div className="block py-1 px-3 hover:bg-gray-100 cursor-pointer">
                      <button
                        onClick={() => dispatch(removeNotification(messageId))}
                      >
                        Remove
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
