import React, { useEffect, useRef, useState } from "react";
import { PostCard } from "../../../Components/Dashboard/NewUI/PostCard";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { apiConnector } from "../../../services/apiconnector";
import { CommentCard } from "../../../Components/CommentPage/CommentCard";
import AltImage from "../../../Images/profile-picture-3.jpg";
import toast from "react-hot-toast";
import { IoSend } from "react-icons/io5";
import { CommentCardNew } from "../../../Components/CommentPage/CommentCardNew";

export const CommentPageNew = () => {
  const location = useLocation();
  const postId = location.pathname.split("/").at(-1);

  const [commentData, setCommentData] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const post = useSelector((state) => state.post);
  // console.log("Comment Page post Data", post);
  // console.log(post.user);

  const commentRef = useRef();

  const { user } = useSelector((state) => state.profile);

  // console.log(post);

  // console.log("USer in COmment", user.image);

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const responseData = await apiConnector(
        "GET",
        `https://api.atmirrors.com/comments/by-post/${postId}`,
        null
      );
      console.log("Comment Page data Received", responseData);

      setCommentData(responseData.data.data);
      //   console.log("Data in CommentData", commentData.comments);
    } catch (error) {
      console.log("error", error);
      setError("Error fetching Comments data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  //   console.log(postId);

  const [formData, setFormData] = useState({
    postId: postId,
    body: "",
    isAnonymous: false,
  });

  const changeHandler = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  console.log("Comments", commentData);

  const [shakeCommentInput, setShakeCommentInput] = useState(false);
  const [errorCommentInput, setErrorCommentInput] = useState(false);

  const submitHandler = async (event) => {
    event.preventDefault();

    // console.log(formData);

    const toastId = toast.loading("Creating New Comment..."); // Store toast ID

    let hasError = false;

    if (!formData.body) {
      setShakeCommentInput(true);
      setErrorCommentInput(true);
      hasError = true;
    }

    if (hasError) {
      toast.dismiss(toastId);
      toast.error("Please fill in all the required fields");
      setTimeout(() => {
        setShakeCommentInput(false);
        // setErrorCommentInput(false);
      }, 500);
      return;
    }

    try {
      await apiConnector(
        "POST",
        "https://api.atmirrors.com/comments/create",
        formData
        // { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
      )
        .then((res) => {
          toast.dismiss(toastId);
          toast.success("Comment Created successfully");
          console.log("Comment Created Successfully. Data received:", res);
          fetchUserData();
          commentRef.current.reset();
          setFormData((prevFormData) => ({ ...prevFormData, body: "" }));
        })
        .catch((error) => {
          toast.dismiss(toastId);
          console.log("Error in the create comment", error);
          toast.error(error.response.data.message);
          // console.log(error);
          // console.log(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className=" max-w-3xl mx-auto ">
      <div className="pt-[5rem] p-3 pb-0">
        <PostCard
          // key={index}
          Name={post.user.Name}
          // CompanyName={post.companyName}
          CompanyName="Mirror"
          image={post.user.image || AltImage}
          postId={post.user.postId}
          postTitle={post.user.postTitle}
          body={post.user.body}
          createdAt={post.user.createdAt}
          updatedAt={post.user.updatedAt}
          authorName={post.user.authorName}
          downVoteCount={post.user.downVoteCount}
          upvoteCount={post.user.upvoteCount}
          isAnonymous={post.user.isAnonymous}
          authorId={post.user.authorId}
          taggedUserId={post.user.taggedUserId}
          authorImage={post.user.authorImage || AltImage}
          reviewedUserRating={post.user.reviewedUserRating || 1}
          currentCompany={post?.user?.currentCompany || "NA"}
          currentRole={post?.user?.currentRole || "NA"}
          voteType={post?.user?.voteType || 0}
          hasUserVoted={post?.user?.hasUserVoted || false}
          authorUserName={post?.user?.authorUserName || "NA"}
          reviewedUserUserName={post?.user?.reviewedUserUserName || "NA"}
        />
      </div>

      {/* <div className="p-2">Comments</div> */}
      <div className="flex justify-center items-center mb-2 p-3 pt-[0px] w-full ">
        <div>
          {" "}
          <img
            className="w-8 h-8 rounded-full mb-0.5 mr-2 "
            src={user.userImage}
            alt={user.fullName}
            title={user.fullName}
          />
        </div>

        <form
          onSubmit={submitHandler}
          ref={commentRef}
          className="flex p-2 items-center justify-center w-full gap-3 lg:gap-0"
        >
          <input
            onChange={changeHandler}
            value={formData.body}
            placeholder="Add your thoughts"
            name="body"
            type="text"
            className={`bg-richblack-800  text-richblack-5  p-3 w-[90%] lg:text-sm h-[40px] text-xs border rounded-md ${
              shakeCommentInput ? "animate-shake" : ""
            } ${errorCommentInput ? "border-red-600" : ""}`}
          />
          <div className=" lg:w-[20%] w-[10%]">
            <button className=" lg:bg-black lg:text-white w-[60%] h-[40px]  rounded-md">
              <span className="flex justify-center">
                <IoSend />
              </span>
            </button>
          </div>
        </form>
      </div>

      <div className="mb-[7rem]">
        {commentData && commentData.length > 0 ? (
          commentData.map((comment, index) => (
            <CommentCardNew
              key={index}
              user={comment.author}
              createdAt={comment.createdAt}
              body={comment.body}
              id={comment.id}
              fetchUserData={fetchUserData}
              voteType={comment?.voteType || ""}
              hasUserVoted={comment?.hasUserVoted || false}
              upvoteCount={comment?.upVoteCount || 0}
              downVoteCount={comment?.downVoteCount || 0}
            />
          ))
        ) : (
          <p className="text-sm font-semibold">No comments available </p>
        )}
      </div>
    </div>
  );
};
