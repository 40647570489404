import React from "react";

function PasswordMatcher({ newPassword, confirmNewPassword }) {
  return (
    <div>
      {newPassword === confirmNewPassword ? (
        <div className="text-right w-[325px] text-xs font-medium text-green-700">
          Passwords Match
        </div>
      ) : null}
    </div>
  );
}
export default PasswordMatcher;
