import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const AnonymousEmailVerification = () => {
  const navigate = useNavigate();
  const [invalidDomainModalOpen, setInvalidDomainModalOpen] = useState(true);
  const userId = 103;
  const [formData, setFormData] = useState({
    workEmail: "",
    currentCompany: "",
    reason: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRequestAccess = async () => {
    try {
      const response = await axios.post(
        `http://localhost:4001/api/v2/requestAnonimity/103`,
        formData
      );

      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      setInvalidDomainModalOpen(false);
    } catch (error) {
      console.error("Error requesting anonymity: ", error);
      toast.error("Failed to request anonymity. Please try again.");
    }
  };

  useEffect(() => {
    // Optional: Add any initial fetch or effect on component load
  }, [userId]);

  return (
    <div className="flex items-center justify-center w-screen h-screen text-left">
      <div className="flex flex-col items-center w-[500px] max-w-md">
        <h2 className="text-3xl font-semibold text-gray-900 ">Coming Soon..</h2>
        <p className="text-md text-gray-900 mb-4 mt-4">
          Please raise a request to add your company to the list of companies we
          support for anonymous activities.
        </p>
        <div className="mt-4 mb-4 ">
          <div className="relative">
            <input
              type="text"
              name="currentCompany"
              placeholder=""
              value={formData.currentCompany}
              onChange={handleChange}
              className="w-[300px] px-2 py-3 mb-3 border rounded block"
            />
            <label
              className={`absolute top-6 left-1 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-700 ${
                formData.currentCompany && "text-xs text-black -translate-y-8 "
              }`}
              style={{
                backgroundColor: formData.currentCompany
                  ? "#fff"
                  : "transparent",
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
            >
              Current Company
            </label>
          </div>
          <div className="relative">
            <input
              type="workEmail"
              name="workEmail"
              placeholder=""
              value={formData.workEmail}
              onChange={handleChange}
              className="w-[300px] px-2 py-3 mb-3 border rounded block"
            />
            <label
              className={`absolute top-6 left-1 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-700 ${
                formData.workEmail && "text-xs text-black -translate-y-8"
              }`}
              style={{
                backgroundColor: formData.workEmail ? "#fff" : "transparent",
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
            >
              ex. nvidia.com
            </label>
          </div>
          <h3 className="text-1xl font-semibold text-gray-900 ">Comments</h3>
          <div className="relative">
            <textarea
              name="reason"
              placeholder="xxxxxxxxxxx"
              value={formData.reason}
              onChange={handleChange}
              className="w-[300px] px-2 py-4 mb-2 border rounded block"
            />
          </div>
        </div>
        <button
          className="w-[300px] bg-white text-black font-medium py-2 px-4 rounded-full border border-black hover:bg-black hover:text-white mt-8"
          onClick={handleRequestAccess}
        >
          Request Access
        </button>
        <button
          type="button"
          className="bg-transparent text-base text-black underline rounded-full mt-3 hover:text-blue-600"
          onClick={() => navigate("/post")}
        >
          <span>Back</span>
        </button>
      </div>
    </div>
  );
};

export default AnonymousEmailVerification;
