import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { BsIncognito } from "react-icons/bs";
import PhoneInput from "react-phone-number-input/input";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export const AnonymousPhoneVerification = () => {
  const [formData, setFormData] = useState({
    phone: "",
  });
  const [phoneSubmitted, setPhoneSubmitted] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));

  const navigate = useNavigate();

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value.length === 1 && index < 5) {
      document.getElementById(`otp${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && e.target.value === "" && index > 0) {
      document.getElementById(`otp${index - 1}`).focus();
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    // Check if the phone number is valid
    const phoneNumber = parsePhoneNumberFromString(formData.phone);

    if (!phoneNumber || !phoneNumber.isValid()) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    const expectedLength =
      phoneNumber.countryCallingCode.length + phoneNumber.nationalNumber.length;

    if (formData.phone.replace(/[^\d]/g, "").length !== expectedLength) {
      toast.error(
        "Please enter a valid phone number for the selected country."
      );
      return;
    }

    const toastId = toast.loading("Verifying...");

    try {
      // Simulating API call for verification
      await new Promise((resolve) => setTimeout(resolve, 2000));

      toast.dismiss(toastId);
      toast.success("Phone number submitted successfully");

      setPhoneSubmitted(true);
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("Verification failed. Please try again.");
    }
  };

  const verifyOTP = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Verifying OTP...");

    try {
      // Simulating API call for OTP verification
      await new Promise((resolve) => setTimeout(resolve, 2000));

      toast.dismiss(toastId);
      toast.success("Phone number verified successfully");

      navigate("/anonymous-verification/email");
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("OTP verification failed. Please try again.");
    }
  };

  const validatePhone = (phone) => {
    return phone.trim() !== "";
  };

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="flex flex-col items-center justify-center w-[325px]">
        <div className="flex flex-col items-center gap-7">
          <BsIncognito size={70} className="text-center" />
          <span className="text-lg font-semibold text-black text-left">
            Please verify your Phone number to be Anonymous.
          </span>
        </div>

        <div className="mt-8">
          <form
            className="flex flex-col items-center"
            onSubmit={phoneSubmitted ? verifyOTP : submitHandler}
          >
            <div className="relative">
              <PhoneInput
                required
                placeholder=""
                value={formData.phone}
                onChange={(value) =>
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    phone: value,
                  }))
                }
                className={`p-3 text-sm w-[325px] h-[58px] border rounded-md mt-2 ${
                  formData.phone && "not-empty"
                }`}
              />
              <label
                className={`absolute top-9  left-3 transform -translate-y-1/2 pointer-events-none transition-all duration-300 text-gray-400 ${
                  formData.phone && "text-xs text-gray-700 -translate-y-9 "
                }`}
                style={{
                  backgroundColor: formData.phone ? "#fff" : "transparent",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                Phone Number
              </label>
            </div>

            {phoneSubmitted && (
              <div className="mt-5 flex items-center justify-center space-x-4">
                {otp.map((_, index) => (
                  <input
                    key={index}
                    id={`otp${index}`}
                    type="text"
                    maxLength="1"
                    className="p-3 text-sm w-10 h-10 text-center border border-grey-900 rounded-md"
                    value={otp[index]}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))}
              </div>
            )}
            {phoneSubmitted && (
              <div className="mt-2 text-gray-500 text-md text-left mb-7">
                Haven’t received the verification code on your mobile. Click
                on&nbsp;
                <Link to="#" className="text-blue-600 hover:underline">
                  Resend
                </Link>
              </div>
            )}
            <button className="bg-black w-[325px] h-[50px] text-white rounded-full mt-8">
              <span>{phoneSubmitted ? "Verify" : "Submit"}</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
